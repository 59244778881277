import { TEAM_ELEMENTS } from "../TeamBoxAcc/enums";

let association = [
  [TEAM_ELEMENTS.SELECT_TEAM, TEAM_ELEMENTS.SELECT_TEAM_LIST], //pierwszy element to element który po kliknięciu ujawnia drugi element
  [TEAM_ELEMENTS.SELECT_PERSON, TEAM_ELEMENTS.SELECT_PERSON_LIST],
];

export const handleDropDown = (e, teamRef, personsRef) => {
  let clickedElement = e.target;
  let team = teamRef.current;
  let persons = personsRef.current;

  if (clickedElement.isEqualNode(team.parentNode)) {
    if (persons.classList.contains("active")) persons.classList.remove("active");
    if (team.classList.contains("active")) {
      team.classList.remove("active");
    } else {
      team.classList.add("active");
    }
  } else if (clickedElement.isEqualNode(persons.parentNode)) {
    if (team.classList.contains("active")) team.classList.remove("active");
    if (persons.classList.contains("active")) {
      persons.classList.remove("active");
    } else {
      persons.classList.add("active");
    }
  }
};

export const handleDropdownElement = (showElement, hideElement) => {
  if (showElement) showElement.current.classList.add("active");
  if (hideElement) hideElement.current.classList.remove("active");
};

export const handleBackgoumd = (e) => {
  document.getElementById(e.target.id).classList.remove("team-bg-shadow");

  association.forEach((element) => {
    const elementToHide = document.getElementById(element[1]);
    const elementToSetIndex = document.getElementById(element[0]);
    elementToHide.style.display = "none";
    elementToSetIndex.style.zIndex = "2";
  });
};
