import React, {useContext, useEffect, useState} from 'react';
import negativeIcon from "../../../../assets/x.png";
import {useNavigate} from "react-router-dom";
import usePopup from "../../../helper/usePopup";
import {MainAppContext} from "../../../MainAppComponent";
import orderServices from "../../../services/orderServices";
import imageServices from "../../../services/imageServices";
import ERROR_MESSAGE from "./ENUMS/ERROR_POPUP_ENUMS";
const DeleteFilePopup = ({ type , orderID, imagePk, setReloadComponent}) => {
    let content = {
        icon: negativeIcon,
        text_small:'na pewno chcesz',
        text_big:'usunąć <br/> wybrany <br/> plik',
        text_center:'',
        button_first_text:'Tak, usuń',
        button_second_text:'Nie, zostaw',
        text_last:'Twojego wyboru nie będzie można cofnąć.'
    }
    const navigate = useNavigate();
    const { closePopup, clearTimeoutManually } = usePopup();
    const { setTotalRemovePopup } = useContext(MainAppContext);
    const handleFirstButton = () => {
        imageServices
            .removeImage(orderID, imagePk)
            .then((res) => {
                if (setReloadComponent) setReloadComponent(true);
                closePopup();
            })
            .catch((error) => {
                console.error(error);
            })
    }
    const handleSecondButton = () => {
        closePopup();
    }
    return (
        <div className={`delete-file-popup ${type}`} onClick={ e => e.stopPropagation() }>
            <div className={`popup-contents ${type}`}>
                <div className="popup-upper-part">
                    <img className="icon-section" src={content?.icon}></img>
                    <span className={`text-small ${type}`}>{content?.text_small}</span>
                    <span className={`text-big ${type}`} dangerouslySetInnerHTML={{__html: content?.text_big}}/>
                </div>
                {content?.text_center && <span className='text-center' dangerouslySetInnerHTML={{__html: content?.text_center}}/>}
                <div className="popup-lower-part">
                    <div className="popup-buttons">
                        <button className={`popup-button first ${type}`} onClick={handleFirstButton}>{content.button_first_text}</button>
                        <button className={`popup-button second ${type}`} onClick={handleSecondButton}>{content.button_second_text}</button>
                    </div>
                    <div className='text-last-container'>
                    <span className={`text-last ${type}`} dangerouslySetInnerHTML={{__html: content?.text_last}} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteFilePopup;
