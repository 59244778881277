import orderServices from "../components/services/orderServices";

const removeOrderLockOnLocationChange = async (lockID) => {
  try{
    await orderServices.removeOrderLock(lockID);
  }catch(error){
    console.log('Remove order lock issue');
  }
}

export default removeOrderLockOnLocationChange;