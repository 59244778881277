import React from 'react';
import negativeIcon from "../../../../assets/x.png";
import imageServices from "../../../services/imageServices";
import usePopup from "../../../helper/usePopup";
const DeleteAllFiles = ({setActiveReloadAnimation, variantID, orderID, setReloadComponent}) => {
    const { closePopup } = usePopup();

    const handleFirstButton = () => {
        if (setActiveReloadAnimation) setActiveReloadAnimation(true);
        imageServices
            .removeImageFromVariant(variantID, orderID)
            .then((res) => {
                if (setReloadComponent) setReloadComponent(true);
                closePopup();
            })
            .catch((error) => {
                setActiveReloadAnimation(false)
                console.error(error);
            });
    }

 return (
     <div className={`new-order-popup delete`} onClick={e => e.stopPropagation()}>
         <div className={`popup-contents delete`}>
             <div className="popup-upper-part">
                 <img className="icon-section" src={negativeIcon}></img>
                 <span className={`text-small delete`}>na pewno chcesz</span>
                 <span className={`text-big delete`}>Usunąć <br/> wszystkie <br/> pliki</span>
             </div>
             <div className="popup-lower-part">
                 <div className="popup-buttons">
                     <button className={`popup-button first delete`}
                             onClick={handleFirstButton}>Tak, usuń</button>
                     <button className={`popup-button second delete`}
                                            onClick={() => closePopup()}>Nie, zostaw</button>
                 </div>
                 <div className='text-last-container'>
                 <span className={`text-last delete`}>Twojego wyboru nie będzie można cofnąć.</span>
                 </div>
             </div>
         </div>
     </div>
 );
};

export default DeleteAllFiles;
