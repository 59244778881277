import React, { useState } from "react";
import roleimg from "../../../../../../assets/junior1.svg";

const RoleBox = () => {
  const [selected, setSelected] = useState(2);
  const [data, setData] = useState([
    "Junior 1",
    "Senior 2",
    "Art Dir 3",
    "Junior 4",
    "Junior 5",
    "Junior 6",
  ]);

  const rollRight = () => {
    const array = [...data];
    array.push(array.shift());
    setData([...array]);
  };

  const rollLeft = () => {
    let array = [...data];
    let popped = array.pop();
    array = [popped, ...array];
    setData([...array]);
  };

  return (
    <div className={"role-box-container"}>
      {data.length > 5 && (
        <div className="arrows prev" onClick={() => rollRight()}></div>
      )}
      {data.map((element, index) => {
        return (
          <div
            style={index > 4 ? { display: "none" } : {}}
            key={index}
            className={index === selected ? "main-card" : "card"}
            onClick={() => {
              if (data.length < 6) {
                setSelected(index);
              }
            }}
          >
            <img src={roleimg} className={"role-image"} alt="pic" />
            <p className={"role-name"}>{element}</p>
          </div>
        );
      })}
      {data.length > 5 && (
        <div className="arrows next" onClick={() => rollLeft()}></div>
      )}
    </div>
  );
};

export default RoleBox;
