import React, { createContext, useState, useMemo, useEffect, useContext, useRef } from "react";
import CollapseBanner from "./CollapseBanner";
import CollapseActions from "./CollapseActions";
import logo from "../../../../../assets/banner1.png";
import generateDisplayData from "../../../../../functions/generateDisplayData";
import { TableDataContext } from "../../../../Router";

import NotoficationCollapseStructure from "./NotificationsCollapseStructure";
import generateReformatsDisplayView from "../../../../../functions/reformatsFunctions/generateReformatsDisplayView";
import AgentionNotes from "./AgentionNote";
import TABLE_TYPES from "../../../../../globalEnums/TABLE_TYPES_ENUMS";
import ExternalLink from "../../ExternalLink";
import checkPermissions from "../../../../../functions/checkPermissions";
import { UserContext } from "../../../../wrapper/AuthWrap";
import PERMISSIONS_ENUMS from "../../../../../globalEnums/PERMISSIONS_ENUMS";
import EXTERNAL_LINKS from "../../ENUMS/EXTERNAL_LINKS";
import AcceptProjectsModal from "../InProgressPopups/AcceptProjectsModal";
import AGENTION_NOTES_ENUMS from "./ENUMS/AGENTION_NOTES_ENUMS";
import TableDataList from "../TableDataList";
import AuthorInfo from "./AuthorInfo";
import OrderEditInfo from "./OrderEditInfo";
import checkIfLockIsOlder from "../../../../../functions/checkIfLockIsOlder";

//TODO: zmienic te contexty z dwoch do jednego
export const ActiveAcceptPopupContext = createContext(null);
export const ActiveCorrectionPopupPopupsContext = createContext(null);

let externalLinksComponentTypes = [
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.ACTION_URL,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE,
    ],
  },
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION,
    ],
  },
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION,
    ],
  },
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.OPEN_FILES,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION,
    ],
  },
];

let agentionNotesTypes = [
  { note_type: AGENTION_NOTES_ENUMS.NOTE_TYPE.MANAGE_BY_NOTE, button: true },
  { note_type: AGENTION_NOTES_ENUMS.NOTE_TYPE.ASSIGNED_TO_NOTE, button: true },
];

const CollapseRow = ({ orderID, data, showVariant, rowWraperRef, variantID, rowData, variantsData, setUpdateVariantsData,updateVariantData, celData }) => {
  const { user, setUser } = useContext(UserContext);
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const [activeAcceptPopup, setActiveAcceptPopup] = useState(false);
  const [activeCorrectionPopup, setActiveCorrectionPopup] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [reformatData, setReformatData] = useState([]);
  const [showWholeContent, setShowWholeContent] = useState("");
  const [mainBannerFromClone, setMainBannerFromClone] = useState(null);
  const [graphicInfo, setGraphicInfo] = useState([]);
  const [authorInfo, setAuthorInfo] = useState([]);

  const [externalLinksReadOnlyStatus, setExternalLinksReadOnly] = useState(false); //only for clients;

  const dataListRef = useRef();

  const activeAcceptPopupValue = useMemo(() => ({ activeAcceptPopup, setActiveAcceptPopup }), [activeAcceptPopup, setActiveAcceptPopup]);
  const activeCorrectionPopupValue = useMemo(
    () => ({ activeCorrectionPopup, setActiveCorrectionPopup }),
    [activeCorrectionPopup, setActiveCorrectionPopup]
  );

  useEffect(() => {
    if (rowData?.is_reformat) {
      let reformatDataStructure = generateReformatsDisplayView(rowData.order_reformat);

      if (reformatDataStructure.length) setReformatData(reformatDataStructure);
    }

    let permisions =
      checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) ||
      checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS.EXTERNAL_LINKS_ACTIONS_FULL_ACCESS]);

    setExternalLinksReadOnly(!permisions);


  }, []);

  const generateAuthorData = () =>{
    let chosenVariantData = variantsData[showVariant];
    let authorInfoData = [];

    if(chosenVariantData?.creation_last_contributor) authorInfoData.push({department: 'Kreacja', name: chosenVariantData.creation_last_contributor})
    if(chosenVariantData?.dtp_last_contributor) authorInfoData.push({department: 'DTP', name: chosenVariantData.dtp_last_contributor})

    setAuthorInfo(authorInfoData);
  }

  useEffect(() => {
    if (tableContext.tableType != "notification"){
      setDisplayData(generateDisplayData(data, showVariant, false, rowData.custom_size_list));
      generateAuthorData()
    }
  }, [data, showVariant]);

  const displayOrderEditInfo = () =>{

    if(rowData.order_locks?.length > 0){
      return !checkIfLockIsOlder(rowData.order_locks[0]);
    }

    return false
  }


  return (
    <>
      <div className={tableContext.tableType == TABLE_TYPES.NOTIFICATION ? "row notification" : "row"}>
        <div className={`col`} ref={dataListRef}>
          {tableContext.tableType == TABLE_TYPES.NOTIFICATION ? (
            <NotoficationCollapseStructure data={data} celData={celData}/>
          ) : (
            <TableDataList displayData={displayData} reformatData={reformatData} mainBannerFromClone={mainBannerFromClone} setMainBannerFromClone={setMainBannerFromClone} dataListRef={dataListRef}/>
          )}
          {tableContext.tableType !== TABLE_TYPES.NOTIFICATION && (
            <>
              {agentionNotesTypes.map((type) => {
                return (
                  <AgentionNotes
                    isReformat={rowData?.is_reformat || false}
                    orderID={orderID}
                    variantsData={variantsData}
                    showVariant={showVariant}
                    reformatData={rowData[type.note_type] || ""}
                    noteType={type.note_type}
                    viewType={AGENTION_NOTES_ENUMS.VIEW_TYPE.TABLE}
                    saveButton={type.button}
                    setUpdateVariantsData={setUpdateVariantsData}
                    updateVariantData={updateVariantData}
                  />
                );
              })}
            </>
          )}
        </div>
        {tableContext.tableType != TABLE_TYPES.NOTIFICATION && (
          <div className="col banner-action">
            <ActiveAcceptPopupContext.Provider value={activeAcceptPopupValue}>
              <ActiveCorrectionPopupPopupsContext.Provider value={activeCorrectionPopupValue}>
                <CollapseBanner
                  variantID={variantID}
                  rowWraperRef={rowWraperRef}
                  logo={logo}
                  orderID={orderID}
                  data={displayData}
                  setDisplayData={setDisplayData}
                  fetchedData={data}
                  rowData={rowData}
                  isReformat={rowData?.is_reformat}
                  setMainBannerFromClone={setMainBannerFromClone}
                  graphicInfo={graphicInfo}
                  setGraphicInfo={setGraphicInfo}
                />
                {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.EXTERNAL_LINKS) && (
                  <div className="links-view external-links">
                    <div className="text">
                      <h4>Link</h4>
                      <span> — podaj źródło w odpowiedniej sekcji i zatwierdź zmiany przyciskiem zapisz</span>
                    </div>
                    {externalLinksComponentTypes.map((link, index) => {
                      return (
                        <ExternalLink
                          orderID={orderID}
                          linkType={link.link_type}
                          variantsData={variantsData}
                          showVariant={showVariant}
                          isReformat={rowData?.is_reformat}
                          orderData={rowData}
                          key={`external-link-key-${index}`}
                          isReadonly={!checkPermissions(user, link.permisions)}
                          saveButton={true}
                        />
                      );
                    })}
                  </div>
                )}

                <AuthorInfo authorsList={authorInfo} />
                <CollapseActions orderID={orderID} data={displayData} rowData={rowData} variantID={variantID}/>
                {displayOrderEditInfo() && <OrderEditInfo editInfo={rowData.order_locks[0]} rowPosition={'start'}/>}
                {activeAcceptPopup ? <AcceptProjectsModal rowWraperRef={rowWraperRef} orderID={orderID} variantsData={variantsData} /> : null}
              </ActiveCorrectionPopupPopupsContext.Provider>
            </ActiveAcceptPopupContext.Provider>
          </div>
        )}
      </div>
    </>
  );
};

export default CollapseRow;
