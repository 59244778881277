import React, {createContext, useMemo, useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainAppComponent from "./MainAppComponent";
import Dashboard from "./pages/dashboard/Dashboard";
import AddNewOrder from "./pages/e-commerce/AddNewOrder";
import SendedProjects from "./pages/e-commerce/SendedProjects";
import InProgressProjects from "./pages/e-commerce/InProgressProjects";
import DraftProjects from "./pages/e-commerce/DraftProjects";
import ArchiveProjects from "./pages/e-commerce/ArchiveProjects";
import Refomatting from "./pages/e-commerce/Reformatting";
import Team from "./pages/e-commerce/Team";
import Notification from "./pages/e-commerce/Notification";
import Raports from "./pages/e-commerce/Raports";
import OrderDetail from "./pages/e-commerce/OrderDetail";
import ErrorPopupTestPage from "./pages/testPages/ErrorPopupTestPage";
import CompareVariants from "./pages/e-commerce/CompareVariants";
import Specification from "./pages/e-commerce/Specification";
import Order from "./pages/e-commerce/Order";
export const TableDataContext = createContext(null);

export const OrderderIdToRemove = createContext(null);
export const ReloadTableContext = createContext(null);

const Router = () => {
  const [tableContext, setTableContext] = useState({
    tableType: null,
    tableConfiguration: [],
    tableData: [], //data from fetch
    staticTableData: [], //data from fetch
    tableDataFilter: "",
    postPerPage: 20, //page_size
    page: 1, //currecnt page
    total_page: 5,
    tableReference: null,
    additionalWidthValue: 0
  });
  const [paginationPage, setPaginationPage] = useState(null)
  const [removeOrderID, setRemoveOrderID] = useState({ orderToRemove: null, noDisplayOrder: [] });
  const [reloadComponent, setReloadComponent] = useState(false);
  const tableContextValue = useMemo(() => ({ tableContext, setTableContext, paginationPage, setPaginationPage }), [tableContext, setTableContext, paginationPage, setPaginationPage]);
  const orderToRemoveValue = useMemo(() => ({ removeOrderID, setRemoveOrderID }), [removeOrderID, setRemoveOrderID]);
  const reload = useMemo(() => ({ reloadComponent, setReloadComponent }), [reloadComponent, setReloadComponent]);

  return (
    <BrowserRouter basename={process.env.REACT_APP_ENV === "local" ? "/" : "/mod_ecommerce"}>
      {/* //"/mod_ecommerce" */}
      <TableDataContext.Provider value={tableContextValue}>
        <ReloadTableContext.Provider value={reload}>
          <OrderderIdToRemove.Provider value={orderToRemoveValue}>
      <Routes>
        <Route path="/" element={<MainAppComponent />}>
          <Route index element={<Dashboard />} />
          <Route path="/addneworder" element={<AddNewOrder />} />
          <Route path="/sendedprojects" element={<SendedProjects />} />
          <Route path="/inprogressProjects" element={<InProgressProjects />} />
          <Route path="/draft" element={<DraftProjects />} />
          <Route path="/archive" element={<ArchiveProjects />} />
          <Route path="/reformatting" element={<Refomatting />} />
          <Route path="/team" element={<Team />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/raports" element={<Raports />} />
          <Route path="/orderdetail" element={<OrderDetail />} />
          <Route path="/order/:id" element={<Order />} />
          <Route path="/popup-test-page" element={<ErrorPopupTestPage />} />
          <Route path="/compare-variants" element={<CompareVariants />} />
          <Route path="/specification" element={<Specification />} />
        </Route>
      </Routes>
          </OrderderIdToRemove.Provider>
        </ReloadTableContext.Provider>
      </TableDataContext.Provider>
    </BrowserRouter>
  );
};

export default Router;
