import React, { useContext, useEffect, useRef, useState } from "react";
import { AddNewOrderContext } from "../../../../pages/e-commerce/AddNewOrder";
import { choseDefaultNameLanguage } from "../../../../../functions/boxFieldsFunctions/choseDefaultNameLanguage";
import { getActualBoxFieldState } from "../../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { updateGLobalBoxFieldsData } from "../../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getBoxFieldContent } from "../../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import { DROPDOWN_TYPE } from "./ENUMS/DROPDOWN_ENUM";
import checkIfContentIncludeID from "../../../../../functions/boxFieldsFunctions/checkIfContentIncludeID";
import Dropdown from "../../../globalElements/Dropdown/Dropdown";

const OrderBoxDropdownContainer = ({ dropdownRef, value, actualVariant, boxFieldID, dropdownSetting, setDropdownSetting, showCheckbox }) => {
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);

  const [dropdownListData, setDropdownListData] = useState([]);

  useEffect(() => {
    generateDropdownData();
  }, [boxSettings.language]);

  const generateDropdownData = () => {
    let mapedData = value.box_field_dictionary.map((el, index) => {
      return {
        value: boxSettings.language !== "PL" ? choseDefaultNameLanguage(el, boxSettings.language, "id") : el.id ?? "",
        displayName: boxSettings.language !== "PL" ? choseDefaultNameLanguage(el, boxSettings.language, "name") : el.default_name,
        groupId: el.id,
      };
    });

    setDropdownListData(mapedData);
  };

  const handleDropdownElement = (e) => {
    e.stopPropagation()
    let initialValue = { ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: [e.target.dataset.groupId] } };
    updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  const handleMultipleCheckboxes = (copyArr, elementValue) => {
    let handledArr = [...copyArr];
    if (handledArr.includes(elementValue)) {
      let index = handledArr.indexOf(elementValue);
      handledArr.splice(index, 1);
    } else {
      handledArr.unshift(elementValue);
    }

    return handledArr;
  };

  const handleDropdownCheckboxElement = (e) => {
    let elementValue,
      copyArr,
      finalArr = [];
    copyArr = getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant).value.content;

    elementValue = e.target.dataset.groupId
    if (e.target.tagName === 'INPUT') {
      e.stopPropagation();
    }
    if (copyArr == null) {
      let initialValue = { ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: [e.target.dataset.groupId] } };
      updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
    } else {
      if (value.field_type == DROPDOWN_TYPE.SINGLE_DROPDOWN) {
        copyArr = [];
        finalArr = [elementValue];
      } else {
        finalArr = [...handleMultipleCheckboxes(copyArr, elementValue)];
      }
      let initialValue = { ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: [...finalArr] } };
      updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
    }
  };

  const handleCheckboxCheck = (id) => {
    let content = getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant);
    return checkIfContentIncludeID(id, content);
  };

  return (
    <>
      {dropdownSetting && (
        <Dropdown
          data={dropdownListData}
          handleListElement={
            [DROPDOWN_TYPE.DROPDOWN, DROPDOWN_TYPE.SINGLE_DROPDOWN].includes(value.field_type) ? handleDropdownCheckboxElement : handleDropdownElement
          }
          displayCheckbox={[DROPDOWN_TYPE.DROPDOWN, DROPDOWN_TYPE.SINGLE_DROPDOWN].includes(value.field_type)}
          positionToElementRef={dropdownRef}
          radioInput={[DROPDOWN_TYPE.SINGLE_DROPDOWN].includes(value.field_type)}
          handleCheckBox={handleCheckboxCheck}
          setShowDropdown={setDropdownSetting}
          showCheckbox={showCheckbox}
        />
      )}
    </>
  );
};

export default OrderBoxDropdownContainer;
