import React, { useContext, useState, useRef, useEffect } from "react";
import AddProductPopup from "../Popup/AddProductPopup";
import { MainAppContext } from "../../../MainAppComponent";
import { ProductViewContext, AddProductPopupContext } from "../OrderBox/OrderBoxContent";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateGLobalBoxFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import Slider from "../Table/Slider";

const AddProduct = ({ description, actualVariant, boxFieldID, value, hidden }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { showPopup, setShowPopup } = useContext(AddProductPopupContext);
  const [isPopupActive, setPopupActive] = useState(false);
  const [slideContent, setSlideContent] = useState([])
  const boxRef = useRef();

  const handleButton = (e,edit) => {
    setShadowActive(true);
    setShowPopup({ active: true, idValue: e.target.dataset.idValue });
  };

  useEffect(() => {
    let chosenValue = [];
    for (let i = 0; i < productViewData.length; i++) {
      if (productViewData[i].ean) {
        let obj = {
          view_id: productViewData[i].id || null,
          external_id: productViewData[i].external_id || null,
          ean: productViewData[i].ean || null,
          plu: productViewData[i].plu || null,
          name: productViewData[i].name || null,
          quantity: productViewData[i].quantity || null,
          weight: productViewData[i].weight || null,
          scale: productViewData[i].scale || 1,
          rotate: productViewData[i].rotate || 0,
          width: productViewData[i].width || 0,
          height: productViewData[i].height || null,
          display_width: productViewData[i].display_width || null,
          grammage: productViewData[i].grammage || null,
          photos: [
            {
              external_id: productViewData[i].external_id || null,
              url: productViewData[i].img_src || null,
            },
          ],
        };
        chosenValue.push(obj);
        // chosenValue = obj;
      }
    }

    let initialValue = {
      variant_id: actualVariant,
      is_hidden: false,
      value: chosenValue,
      custom_input_value: null,
      image: null,
    };
    updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  }, [productViewData]);


  useEffect(()=>{
    let slideArr = productViewData.map((value, index) => {
      return (
        <button data-id-value={value.id} className={value.ean ? "add selected" : "add"} onClick={e => handleButton(e,value.ean)} key={`add-product-${index}`}>
          {value.id}
        </button>
      );
    })

    setSlideContent(slideArr);
  }, [productViewData])

  return (
    <>
      <div className="content add-product-box" ref={boxRef}>
        <span>{description || null}</span>
        <div className="add-product">
            {slideContent.length
            ? <Slider spaceBetween={0} slidesPerView={5} slides={slideContent} hasCustomNavigation={true} />
            : null}
        </div>
      </div>
    </>
  );
};

export default AddProduct;
