import React, {useEffect, useState, useContext} from "react";
import scope from "../../../../../../assets/scope.png";
import deleteImg from "../../../../../../assets/delete.png";
import ImageModal from "../../../Popup/ImageModla";
import { getFileBaseName } from "../../../../../../functions/getFileBaseName";
import trimString from "../../../../../../functions/trimString";
import checkPrefix from "../../../../../../functions/checkPrefix";
import checkIfSourceIsPDF from "../../../../../../functions/checkIfSourceIsPDF";
import pdfIcon from "../../../../../../assets/file-pdf.svg";
import reloadImage from "../../../../../../assets/reload-image.svg";
import imageServices from "../../../../../services/imageServices";
import AddCorectionButton from "../../../Table/collapseElements/buttons/AddCorrectionButton";
import PERMISSION_ENUM from "../../ENUMS/TEAN_PERMISSIONS";
import orderServices from "../../../../../services/orderServices";
import BOX_INFO_ENUMS from "../../../Popup/ENUMS/BOX_INFO_ENUMS";
import Loader from "../../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../../globalElements/Loader/LOADER_TYPE";
import usePopup from "../../../../../helper/usePopup";
import InfoPopup from "../../../Popup/InfoPopup";

import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import DeleteFile from "../../../Popup/AddOrder/DeleteFile";
import DeleteAllFiles from "../../../Popup/AddOrder/DeleteAllFiles";
import NewInfoPopup from "../../../Popup/AddOrder/NewInfoPopup";

const ImageList = ({
  list,
  setImagesData,
  orderID,
  setRealoadComponent,
  activeReloadAnimation,
  setActiveReloadAnimation,
  permission,
  isDisabled,
  variantID,
  orderDetails
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [showImageSource, setShowImageSource] = useState(null);
  const [showRemoveImagePopup, setShowRemoveImagePopup] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(null);
  const [removeAllFromVariant, setRemoveAllFromVariant] = useState(true);
  const [showBoxInfo, setShowBoxInfo] = useState(false);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const handleScope = (e) => {
    let source = e.target.dataset?.source ? checkPrefix(e.target.dataset.source) : e.target.src;

    if (checkIfSourceIsPDF(source)) {
      window.open(source, "_blank");
    } else {
      setShowImageModal(true);
      setShowImageSource(source);
    }
  };

  const handleRemoveImage = (e) => {
    let imagePk = e.target.dataset.imagePk;
    setImageToRemove(imagePk);
    setRemoveAllFromVariant(false);
    setShowRemoveImagePopup(true);
    initPopup(
        <DeleteFile
        orderID={orderID}
        imagePk={imagePk}
        setReloadComponent={setRealoadComponent}
      />
    );
  };

  const handleUpdateImageSlot = (e) => {
    let filesList = Array.from(e.target.files);
    let slotPk = e.target.dataset.imagePk;

    if (filesList.length == 1) {
      setActiveReloadAnimation(true);
      imageServices
        .updateImageSlot(filesList[0], slotPk)
        .then((res) => {
          let newImagesList = list.map((image) => {
            if (image.pk == res.pk) return res;

            return image;
          });

          setImagesData(newImagesList);
          setActiveReloadAnimation(false);
        })
        .catch((error) => {
          console.log("THERE IS A PROBLEM WITH IMAGE UPDATE in ImageList.js");
          console.error(error);
          initPopup(<NewInfoPopup type={'negative'} info={[PERMISSION_ENUM.ACCM].includes(permission) ? 'accept_button' : 'image_update'}/>)
          setActiveReloadAnimation(false);
        });
    }
  };
  const handleRemove = (e) => {
    setRemoveAllFromVariant(true);
    initPopup(
        <DeleteAllFiles
        setActiveReloadAnimation={setActiveReloadAnimation}
        orderID={orderID}
        imagePk={imageToRemove}
        variantID={variantID}
        setShowRemoveImagePopup={setShowRemoveImagePopup}
        setReloadComponent={setRealoadComponent}
        removeAllFromVariant={true}
      />
    );
  };
  const handleOrderStatus = () => {
    orderServices
      .changeOrderStatus(orderID, "accepted_acc")
      .then((res) => {
        setRealoadComponent(true);
        setActiveButtonLoader(false);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<NewInfoPopup type={'negative'} info={[PERMISSION_ENUM.ACCM].includes(permission) ? BOX_INFO_ENUMS.ERROR_MESSAGE.ACCEPT_BUTTON : BOX_INFO_ENUMS.ERROR_MESSAGE.IMAGE_UPDATE_ERROR}/>)
        setActiveButtonLoader(false);
      });
  };

  const generateAcceptButtonClass = (orderID) => {
    let classArr = "accept";
    //TOFIX: disallowedStatuses switch to allowed statuses
    let disallowedStatuses = ["accepted_acc", "finished", "renew"] //list of statuses that make ACC unable to accept project
    if (
      orderDetails && (
      (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) && !disallowedStatuses.includes(orderDetails.status)) ||
      (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE]) && orderDetails.status == "accepted_acc"))
    ) {
      classArr += " user_can_accept";
    } else {
      classArr += " user_can_not_accept ";
    }

    return classArr;
  };

  return (
    <div className="image-list-wrapper">
      <ul>
        {list.map((element, index) => {
          if (element?.image) {
            let fielName = getFileBaseName(element.image || "photo");
            return (
              <li key={index}>
                <span className="index">{index + 1}</span>
                <div className="row">
                  <div className="image-actions">
                    <img className="icon" src={scope} alt="pic" data-source={element.image} onClick={handleScope} />
                    <label>
                      <input type={`file`} data-image-pk={element.pk} onChange={handleUpdateImageSlot}></input>
                      <img className="icon" src={reloadImage} alt="pic" />
                    </label>

                    <img className="icon" src={deleteImg} alt="pic" data-image-pk={element.pk} onClick={handleRemoveImage} data-type="single" />
                  </div>
                  <div className="img-preview">
                    <img src={checkIfSourceIsPDF(element.image) ? pdfIcon : checkPrefix(element.image)} alt="picture" onClick={handleScope} />
                    <p className="name" title={fielName}>
                      {trimString(fielName, 35)}
                    </p>
                  </div>
                </div>
              </li>
            );
          }
        })}
      </ul>
      {activeReloadAnimation && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
      {showImageModal && <ImageModal setShowImageModal={setShowImageModal} imageSource={showImageSource} />}

      <div className="actions">
        {permission == PERMISSION_ENUM.ACCM ? (
          <>
            <AddCorectionButton isReformat={false} orderID={orderID} />
            <button className={generateAcceptButtonClass()} onClick={handleOrderStatus}>
              Zaakceptuj
            </button>
          </>
        ) : (
          <button className="remove" disabled={list?.length == 0 || isDisabled ? true : false} data-type="all" onClick={handleRemove}>
            Usuń wszystkie pliki z tego wariantu
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageList;
