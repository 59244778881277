import React, {useContext, useEffect, useRef, useState} from "react";
import checkPermissions from "../../../../../../functions/checkPermissions";
import handlePermissionClass from "./functions/handlePermissionClass";
import { ReloadTableContext } from "../../../../../Router";

import BOX_INFO_ENUMS from "../../../Popup/ENUMS/BOX_INFO_ENUMS";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import POPUP_TYPES from "../../../Popup/ENUMS/POPUP_TYPES";
import Popup from "../../../Popup/Popup";
import AssigneePopup from "../../../Popup/AssigneePopup";
import usePopup from "../../../../../helper/usePopup";

const ForwardToGraphicButton = ({ orderID, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [assignedPopup, setAssignedPopup] = useState({ showPopup: false, order_id: orderID });
  const buttonRef = useRef();

  const handleForwardButton = () => {
    setAssignedPopup({ showPopup: true, order_id: orderID });
  };
  const { initPopup } = usePopup();
  useEffect(() => {
    if (assignedPopup.showPopup) {
      initPopup(<AssigneePopup setRealoadComponent={setReloadComponent} setAssignePopup={setAssignedPopup} orderID={orderID} changeStatus={rowData.status == 'correction' ? false : true}/>);
    }
  }, [assignedPopup])
  return (
    <>
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.FORWARD_TO_GRAPHIC_BUTTON) && (
        <>
          <button className={`forwoard ${handlePermissionClass(user)}`} style={rowData.order_status === "accepted_acc" ? {backgroundColor:'#E6E6E6', pointerEvents:'none'} : null} onClick={handleForwardButton} ref={buttonRef}>
            Przekaż do grafika
          </button>
        </>
      )}
    </>
  );
};

export default ForwardToGraphicButton;
