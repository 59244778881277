import authHeader from "../helper/auth-header";
import { handleResponse } from "../helper/handle-response";
import getApiKey from "../helper/getApiKey";

const updateCorrectionBoxFields = async (box_fields) => {
  let body = JSON.stringify(box_fields);
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
    body: body,
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/variant/variant-box-field-value/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default updateCorrectionBoxFields;
