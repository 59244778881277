/**
 * CETA NOTIFICATION BELL, TEMPORARY SOLUTION
 *
 * bell initialisation
 */

import notificationServices from "../components/services/notificationServices";

let cetaNavbar, liElement, linkElement, notificationNumber, firstNavbarElement, spanElement;

const initNotificationBell = () => {
  cetaNavbar = process.env.REACT_APP_ENV === "local" ? document.querySelector("header .actions") : document.querySelector(".nav.navbar-top-links");
  firstNavbarElement = cetaNavbar.querySelector("li");
  liElement = document.createElement("li");
  linkElement = document.createElement("a");
  notificationNumber = document.createElement("span");

  linkElement.href = "/mod_ecommerce/notifications";
  liElement.classList.add("notification-bell");

  liElement.appendChild(linkElement);
  linkElement.appendChild(notificationNumber);

  cetaNavbar.insertBefore(liElement, firstNavbarElement);

  getNotificationNumber();

  setInterval(getNotificationNumber, 20000);
};
const getNotificationNumber = () => {
  notificationServices.getNotificationCount().then((res) => {
    let currentNumber = notificationNumber.textContent;
    if (res.unread_count) {
      if (notificationNumber.style.display == "none") notificationNumber.style.display = "flex";
      if (currentNumber !== res.unread_count) notificationNumber.textContent = res.unread_count;
    } else {
      notificationNumber.style.display = "none";
    }
  }).catch(error=>{
    console.log('Error fetching notification count:');
    console.error(error);
  });
};

export default { initNotificationBell, getNotificationNumber };
