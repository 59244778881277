import React from 'react';
import {ReactComponent as Warning}  from "../../../../../assets/popups/warning.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {contentMap} from "../../ENUMS/INFO_POPUP_ENUM";
const NewInfoPopup = ({info, type}) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    return (
        <div className='wrong-popup'>
            <Warning />
            <h3>{contentMap[type][info].text_big}</h3>
            <p>Pomoc: <a href="mailto:e-commerce@app4ad.pl">e-commerce@app4ad.pl</a></p>
            <div className='single-button'>
                <button className="button accept" onClick={()=> closeOrderPopup()}>OK</button>
            </div>
        </div>
    );
}

export default NewInfoPopup;
