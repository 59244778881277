import React, { useState, useContext } from "react";
import faceicon from "../../../../../assets/junior1.svg";
import { ReactComponent as RocketGrey } from "../../../../../assets/rocket-lunch-grey.svg";
import { ReactComponent as RocketGreen } from "../../../../../assets/rocket-green.svg";
import Row from "./Row";
import settingsIMG from "../../../../../assets/settings-sliders.svg";
import orderServices from "../../../../services/orderServices";
import { UserContext } from "../../../../wrapper/AuthWrap";
import checkPermissions from "../../../../../functions/checkPermissions";
import trimString from "../../../../../functions/trimString";
import PERMISSION_ENUM from "../ENUMS/TEAN_PERMISSIONS";
import Popup from "../../Popup/Popup";
import POPUP_TYPES from "../../Popup/ENUMS/POPUP_TYPES";
import AssigneePopup from "../../Popup/AssigneePopup";
import InfoPopup from "../../Popup/InfoPopup";
import usePopup from "../../../../helper/usePopup";

const AssignedProjectBox = ({
  new_order,
  id,
  rocket,
  data,
  initializeNewWorkLog,
  currentWorkProject,
  setAssignePopup,
  setOrderId,
  orderId,
  startProjectRef,
  showRocketStartProject,
  permission,
  projectAdditionalActionsRef,
  setLoading,
  setRealoadComponent
}) => {
  const [popupStatus, setPopupStatus] = useState("correct");
  const [showDetails, setShowDetails] = useState(false);
  const [boxToRemove, setBoxToRemove] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const handleDetailButtons = (e) => {
    let type = e.currentTarget.dataset.buttonType;

    if (type == "capture") {
      initPopup(<AssigneePopup setRealoadComponent={setRealoadComponent} setAssignePopup={setAssignePopup} orderID={id} changeStatus={true}/>)
    } else {
      setLoading(true);
      orderServices.changeOrderStatus(id, "finished").then((res) => {
        // setLoading(false);
        initPopup(<InfoPopup type={'positive'} info={'archive'} setReloadComponent={setRealoadComponent}/>)
        setBoxToRemove(e.target);
        setRealoadComponent(true);
      });
    }
  };

  const handleOrder = (localOrderID) => {
    if(orderId !== localOrderID){
      setOrderId(localOrderID);

      let currentElement;

      if(projectAdditionalActionsRef){
        projectAdditionalActionsRef.current.forEach(el=>{
          if(el) el.classList.remove('details-height')
        })
      }

      if (startProjectRef) {
        startProjectRef.current.forEach((el) => {
          if (el) {
            el.classList.remove("show");
            if (el.dataset.order == localOrderID) {
              currentElement = el;
            }
          }
        });
      }

      if(currentElement) currentElement.classList.add("show")
    }

  };

  const handleOrderDetails = (e) =>{

    if(projectAdditionalActionsRef){
      let elements = projectAdditionalActionsRef.current
      for(let i = 0; i<elements.length; i++){
        if(elements[i] && elements[i].dataset.id == id){
          elements[i].classList.toggle('details-height');
          break;
        }
      }
    }

    if (startProjectRef) {
      startProjectRef.current.forEach((el) => {
        if (el) {
          if (el.dataset.order == id && !el.classList.contains("show")) {
            el.classList.add("show");
          }else{
            el.classList.remove("show");
          }
        }
      });
    }
  }

  return (
    <>
      <div
        className="assigned-project-box-container-around"
        data-order={id}
        {...(permission == PERMISSION_ENUM.ACCM
          ? {
              onClick: () => {
                handleOrder(id);
              },
              ref: (el) => {
                startProjectRef.current.push(el);
              },
            }
          : {})}
      >
        <div className="assigned-project-box-container">
          {!new_order && (
            <>
              <div
                className="status-wrapper"
                style={{ background: `linear-gradient(270deg, ${data?.status_label_and_color[1] || `#ffb800`} 50%, rgba(0, 113, 149, 0.00) 125.5%)` }}
              >
                <img src={data?.managed_by_avatar_url ? data.managed_by_avatar_url : faceicon} className="face-icon" alt="img" />
                <p className="status">{data?.status_label_and_color[0] || ""}</p>
              </div>
              <div className="assigned-to-name">
                {rocket ? (
                  <>
                    <span>{data?.managed_by?.split(" ")[0] || ""}</span>
                    <span>{data?.managed_by?.split(" ")[1] || ""}</span>
                  </>
                ) : (
                  <>
                    <span>{data?.assigned_to?.split(" ")[0] || ""}</span>
                    <span>{data?.assigned_to?.split(" ")[1] || ""}</span>
                  </>
                )}
              </div>
              {/* <span className="assigned-to-name">{rocket ? data?.managed_by || "" : data?.assigned_to || ""}</span> */}
              {rocket && showRocketStartProject && (
                <div className="project-handler">
                  {currentWorkProject && data?.id == currentWorkProject[0]?.id ? null : (
                    <div
                      className="start-project-worklog"
                      onClick={() => initializeNewWorkLog(data.id)}
                      ref={(el) => {
                        startProjectRef.current.push(el);
                      }}
                      data-order={data.id}
                    >
                      Rozpocznij
                    </div>
                  )}

                  <div
                    className={currentWorkProject && data?.id == currentWorkProject[0]?.id ? `rocket-wrapper active` : `rocket-wrapper`}
                    {...(permission == PERMISSION_ENUM.ACCM ? {} : { onClick: () => handleOrder(data.id) })}
                  >
                    {currentWorkProject && data?.id == currentWorkProject[0]?.id ? (
                      <RocketGreen
                        style={{
                          width: "43px",
                          height: "32px",
                        }}
                        stroke="#FFFFFF"
                      />
                    ) : (
                      <RocketGrey
                        style={{
                          width: "43px",
                          height: "32px",
                        }}
                        stroke="#FFFFFF"
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="card-info-wrapper">
            <div className="card-info">
              <ul className="ul-titles">
                <li>Nazwa</li>
                <li>Język</li>
                <li>kampania</li>
                {/* <li>Propozycja do</li> */}
                <li>formaty</li>
                <li>deadline</li>
              </ul>
              <ul className="ul-data">
                <li title={data.action_name?.content || `id: ${data.id}`}>{trimString(data.action_name?.content, 17) || `id: ${data.id}`}</li>
                <li title={data.language_name?.custom_input_value || ``}>{trimString(data.language_name?.custom_input_value, 17) || ``}</li>
                {/* <li>{data.proposal_deadline || `---`}</li> */}
                <li>
                  {" "}
                  {data.campaign_name?.calculated_content && data.campaign_name?.calculated_content.length > 0
                    ? trimString(data.campaign_name.calculated_content[0].default_name, 17)
                    : "--"}
                </li>
                <li>{data.formats_count}</li>
                <li>{data.deadline?.content || `---`}</li>
              </ul>
              {!new_order && (
                <>
                  <div className="icon settings-icon" onClick={handleOrderDetails} style={showDetails ? { background: "#FFD600" } : {}} data-id={id}>
                    <img src={settingsIMG} alr="pic" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={`details`}
          ref={(el) => {
            projectAdditionalActionsRef.current.push(el);
          }}
          data-id={id}
        >
          <div className="details-content">
            <Row paddingTop={"4px"} paddingRight={"8px"}>
              <div className="button-wrapper" data-button-type={"capture"} onClick={handleDetailButtons}>
                <div className="icon capture"></div>
                <div className="extended-text">
                  <span>przekaż do</span>
                </div>
              </div>
              {checkPermissions(user, ["ecommerce_admin", "ecommerce_orders_create"]) ? (
                <div className="button-wrapper" data-button-type={"archive"} onClick={handleDetailButtons}>
                  <div className="icon archive"></div>
                  <div className="extended-text">
                    <span>zamknij i zarchiwizuj</span>
                  </div>
                </div>
              ) : null}
              <div className={`icon close-btn`} onClick={handleOrderDetails}></div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignedProjectBox;
