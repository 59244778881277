import React, {useContext, useState} from 'react';
import icon from "../../../../assets/zmiana_osoby.png";
import {MainAppContext} from "../../../MainAppComponent";
import ENUMS from "../Team/ENUMS";
import orderServices from "../../../services/orderServices";
import usePopup from "../../../helper/usePopup";
import SelectBox from "../Team/globalelements/SelectBox";
const AssigneePopup = ({ setAssignePopup, orderID, setRealoadComponent, assigneToDtp, changeStatus }) => {
    const [showErrorPopup, setErrorPopup] = useState(false);
    const [selectBoxData, setSelectBoxData] = useState({ role: "", person: "" });
    const [activeButtonLoader, setActiveButtonLoader] = useState(false);
    const [error, setError] = useState(false);
    const { usersList, setUsersList } = useContext(MainAppContext);
    const { closePopup } = usePopup();
    const handleFirstButton = (e) => {
        if(!selectBoxData.role || !selectBoxData.person){
            setError(true);
            return;
        }
        if (setRealoadComponent) setRealoadComponent(true);
        setActiveButtonLoader(true);

        if (assigneToDtp || selectBoxData.role == ENUMS.ROLE.KREACJA || selectBoxData.role == ENUMS.ROLE.DTP) {
            orderServices
                .assignProjectToDtpUser(orderID, selectBoxData.id, changeStatus)
                .then((res) => {
                    setActiveButtonLoader(false);
                })
                .catch((error) => {
                    console.log("ASSIGNE PROJECT TO DTP ERROR - assignePopup");
                    console.error(error);
                    setActiveButtonLoader(false);
                });
        } else if (selectBoxData.role == ENUMS.ROLE.CLIENT) {
            orderServices
                .changeOrderDetail(orderID, { client_managed_by: selectBoxData.id })
                .then((res) => {
                    setActiveButtonLoader(false);
                })
                .catch((error) => {
                    console.log("ASSIGNE PROJECT TO CURRENT CLIENT USER ERROR - assignePopup");
                    console.error(error);
                    setActiveButtonLoader(false);
                });
        } else {
            orderServices
                .assignProjectToCurrentAccUser(orderID, selectBoxData.id, changeStatus)
                .then((res) => {
                    setActiveButtonLoader(false);
                })
                .catch((error) => {
                    console.log("ASSIGNE PROJECT TO CURRENT ACC USER ERROR - assignePopup");
                    console.error(error);
                    setActiveButtonLoader(false);
                });
        }
        setAssignePopup({ showPopup: false, order_id: null });
        closePopup();
    };
    const handleSecondButton = () => {
        setAssignePopup({ showPopup: false, order_id: null });
        closePopup();
    }
 return (
     <div className={`new-order-popup positive`} onClick={e => e.stopPropagation()}>
         <div className={`popup-contents positive`}>
             <div className="popup-upper-part">
                <img className="icon-section" src={icon}></img>
                 <span className={`text-small positive`}>dokonaj zmiany opiekuna zlecenia</span>
                 <span className={`text-big positive`}>wybierz osobę której <br /> chcesz przekazać <br/> zlecenie</span>
             </div>
             <div className="popup-center-part">
                 <SelectBox selectBoxData={selectBoxData} setSelectBoxData={setSelectBoxData} persons={usersList} error={error} setError={setError}/>
             </div>
             <div className="popup-lower-part">
                 <div className="popup-buttons">
                     <button className={`popup-button first positive`}
                             onClick={handleFirstButton}>Przekaż</button>
                     <button className={`popup-button second neutral`}
                             onClick={handleSecondButton}>Anuluj</button>
                 </div>
                 <div className='text-last-container'><span className={`text-last positive`}></span></div>
                 
             </div>
         </div>
     </div>
 );
};

export default AssigneePopup;
