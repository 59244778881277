const initHardReload = () =>{
  let cetaNavbar = process.env.REACT_APP_ENV === "local" ? document.querySelector("header .actions") : document.querySelector(".nav.navbar-top-links");
  let firstNavbarElement = cetaNavbar.querySelector("li");
  let liElement = document.createElement("li");
  let divElement = document.createElement("div");
  
  liElement.classList.add("hard-reload");

  liElement.appendChild(divElement);

  liElement.setAttribute('title', "Załaduj stronę ponownie");

  cetaNavbar.insertBefore(liElement, firstNavbarElement);

  liElement.addEventListener(`click`, ()=>{
    window.location.reload(true);
  })
}

export default {initHardReload}