import React from "react";

const RaportsRadioButtons = ({ title, values, layoutDirection, type, handleRadioListElement, chosenData, isValidated }) => {

  const handleCheckBox = (value) => {
    return chosenData.includes(value.toString());
  };

  return (
    <div className="raports-value-list">
      <h3 className={isValidated ? 'is-validated' : 'not-validated'}>{!isValidated && '*'} {title}</h3>
      <div className="radio-list">
        <ul style={{display: "flex", flexDirection: layoutDirection}}>
          {values &&
            values.length > 0 &&
            values.map((liElement, index) => {
              return (
                <li key={`raports-radio-list-${index}`}>
                    {type === "range"
                        ?
                        <label className="custom-checkbox">
                            <input type="checkbox" onChange={handleRadioListElement} data-input-type={type}
                                   value={liElement.id} checked={handleCheckBox(liElement.id)}></input>
                            <div className="checkmark"></div>
                            <span className='custom-checkbox-text'>{liElement.default_name}</span>
                        </label>
                        :
                        <label className="checkbox ">
                            <input type="checkbox" onChange={handleRadioListElement} data-input-type={type}
                                   value={liElement.id} checked={handleCheckBox(liElement.id)}></input>
                            <div className="checkmark"></div>
                            <span className='custom-checkbox-text'>{liElement.default_name}</span>
                        </label>
                    }
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(RaportsRadioButtons);
