import { handleResponse } from "../helper/handle-response.js";
import authHeader from "../helper/auth-header.js";
import getApiKey from "../helper/getApiKey.js";

const getProductsList = (ean_code) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/?ean=${ean_code}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getProducts = async (ean_number) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/?ean=${ean_number}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getProductImage = async (image_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };

  ///products/${image_id}/image/
  return fetch(`${process.env.REACT_APP_API_URL}/products/${image_id}/image-core/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getProductProperty = async (image_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };

  ///products/${image_id}/image/
  return fetch(`${process.env.REACT_APP_API_URL}/products/${image_id}/image-core-property/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const downloadProductsZipFileByVariants = async (variant_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };
  
  return fetch(`${process.env.REACT_APP_API_URL}/products/${variant_id}/download`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getProductsList,
  getProducts,
  getProductImage,
  getProductProperty,
  downloadProductsZipFileByVariants,
};
