const NOTE_TYPE = {
  MANAGE_BY_NOTE: 'acc_note',
  ASSIGNED_TO_NOTE: 'assigned_to_note',
}

const VIEW_TYPE = {
  TABLE: "table-view",
  TEAM: "team-view"
}

const NOTE_TEXT = {
  acc_note: {
    TITLE: "Notatka",
    SUB_TITLE: "od ACCOUNTa",
    DESCRIPTION: "wprowadzać zmienne może jedynie agencja",
  },
  assigned_to_note: {
    TITLE: "Notatka",
    SUB_TITLE: "od GRAFIKa",
    DESCRIPTION: "wprowadzać zmienne może jedynie grafik po stronie agencji",
  },
}

export default {NOTE_TYPE, VIEW_TYPE, NOTE_TEXT};