import React, {useContext, useState} from "react";
import {ReformatContext} from "../../../pages/e-commerce/Reformatting";

const CustomSizeInputs = ({ customSizeList, handleData, removeButtonType, reformatData, setReformatData }) => {
  const [inputValue, setInputValue] = useState("");
  const handleButton = (event) => {
    setInputValue("");
    handleData(event, inputValue);
  };
  const handleCheckChange = (size, event) => {
    const checked = event.target.checked;
    if (checked) {
      if (!reformatData.custom_sizes_list.includes(size)) {
        setReformatData({ ...reformatData, custom_sizes_list: [...reformatData.custom_sizes_list, size] });
      }
    } else {
      const newSizesList = reformatData.custom_sizes_list.filter(s => s !== size);
      setReformatData({ ...reformatData, custom_sizes_list: newSizesList });
    }
  };

  return (
    <ul className="custom-size-inputs">
      {customSizeList?.length > 0
        ? customSizeList.map((size, index) => {
            return (
              <li className="list-element" key={"li" + index}>
                {removeButtonType == "checkbox" ? (
                  <label className="real-checkbox">
                    <input checked={true} type="checkbox" onChange={handleButton} data-remove-value={size}></input>
                    <span className="checkmark"></span>
                    {size}
                  </label>
                ) : (
                    <li className="list-element" key={"li" + index}>
                      <label className="real-checkbox" style={{margin:0}}>
                        <input
                            type="checkbox"
                            checked={reformatData.custom_sizes_list.includes(size)}
                            onChange={(e) => handleCheckChange(size, e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {`${index + 1}. ${size}`}
                      {/*<button*/}
                      {/*    className="remove_btn"*/}
                      {/*    onClick={(e) => handleData(e, size)}*/}
                      {/*    data-remove-value={size}*/}
                      {/*>*/}
                      {/*  -*/}
                      {/*</button>*/}
                    </li>
                )}
              </li>
            );
          })
          : null}
      <li className="add-li">
        {removeButtonType != "checkbox" && `${customSizeList.length + 1}`}
        <input placeholder="wpisz rozmiar..." value={inputValue}
               onChange={(e) => setInputValue(e.target.value)}></input>
        <button className="add_btn" onClick={handleButton}>
          +
        </button>
      </li>
    </ul>
  );
};

export default CustomSizeInputs;
