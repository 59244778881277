import React, { useMemo, useState, useRef, useEffect } from "react";
import CalenderFromTo from "../../globalElements/CalenderFromTo";
import { ReactComponent as DropdownIcon } from "../../../../assets/dropdownicon.svg";
import Dropdown from "../../globalElements/Dropdown/Dropdown";

const RaportsHeader = ({
  title,
  values,
  type,
  chosenDateFrom,
  setChosenDateFrom,
  chosenDateTo,
  setChosenDateTo,
  value,
  handleListElement,
  setRaportName,
    error,
    setError
}) => {
  const dropdownRef = useRef();
  const headerRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className={`raport-header`} ref={headerRef}>
      <h3 className={`${error.type.length > 0 && error.type === type && `error-${type}`}`}>{error.type.length > 0 && error.type === type ? "*" : null}{title}</h3>
      <div className={`value-container ${type}`}>
        {["calender", "methodType"].includes(type) ? (
          <>
            <input
              ref={dropdownRef}
              className={`input-dropdown ${type} ${error.type.length > 0 && error.type === type ? `error-${type}` : null}`}
              onClick={handleDropdown}
              readOnly={true}
              placeholder="wybierz z listy"
              value={value}
            ></input>
            <DropdownIcon className='arrow-down' />
            {showDropdown && (
              <div className={`dropdown dropdown-checkbox`}>
                {type == "calender" ? (
                  <CalenderFromTo
                    chosenDateFrom={chosenDateFrom}
                    setChosenDateFrom={(value) => {
                      setError({ type: ''});
                      setChosenDateFrom(value)
                    }}
                    chosenDateTo={chosenDateTo}
                    setChosenDateTo={(value) => {
                      setError({ type: ''});
                      setChosenDateTo(value)
                    }}
                    handleFilter={() => {
                      setShowDropdown(false);
                    }}
                    setShowDropdown={setShowDropdown}
                  />
                ) : (
                  <Dropdown
                    data={values}
                    handleListElement={handleListElement}
                    displayCheckbox={false}
                    positionToElementRef={dropdownRef}
                    setShowDropdown={setShowDropdown}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <input className="input-text" placeholder="wpisz" onChange={(e) => setRaportName(e.target.value)} value={value}></input>
        )}
      </div>
    </div>
  );
};

export default React.memo(RaportsHeader);
