import React, { useState, useRef, useContext, useEffect } from "react";
import { MainAppContext } from "../../../MainAppComponent";
import { choseDefaultNameLanguage } from "../../../../functions/boxFieldsFunctions/choseDefaultNameLanguage";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getUniqueFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { getHeadersFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import createCampaign from "../../../services/createCampaign";
import showCampaignDropdown from "../../../../functions/campaignDropdownFunctions/showCampaignDropdown";
import CampaignDropdownContainer from "../../campaignDropdownElements/CampaignDropdownContainer";
import InfoPopup from "../../e-commerce/Popup/InfoPopup";
import usePopup from "../../../helper/usePopup";

const AddNewOrderCampaignDropdown = ({ value, boxFieldID, actualVariant, blockEdit, notValidatedBoxes }) => {
  const { headerBoxesData, setHeaderBoxesData } = useContext(AddNewOrderContext);
  const [campaignName, setCampaignName] = useState(null);
  const [displayDropdownData, setDisplayDropdownData] = useState([]);
  const [localVariant, setLocalVariant] = useState(null);
  const [renderOnce, setRenderOnce] = useState(true);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);
  const [isValidated, setIsValidated] = useState(true)
  const [showDopdown, setShowDropdown] = useState(false);
  const { initPopup } = usePopup();
  const dropdownRef = useRef();
  const inputRef = useRef();
  const dropDownArea = useRef();

  useEffect(()=>{
    if (notValidatedBoxes.length > 0) {
      for (let i = 0; i < notValidatedBoxes.length; i++) {
        if (notValidatedBoxes[i].box_field === boxFieldID) {
          setIsValidated(false)
        }
      }
    }
  }, [notValidatedBoxes])

  useEffect(() => {
    if (!isShadowActive) dropDownArea.current.classList.remove("not-validated");
  }, [isShadowActive]);

  useEffect(() => {
    let newDisplayValueArr = [];
    value.every((el, index) => {
      if (index < 15) {
        if (boxSettings.language !== "PL") {
          let obj = { name: choseDefaultNameLanguage(el, boxSettings.language, "name"), id: el.id };
          newDisplayValueArr.push(obj);
        } else {
          let obj = { name: el.default_name, id: el.id };
          newDisplayValueArr.push(obj);
        }
        return true;
      } else {
        return false;
      }
    });

    setDisplayDropdownData(newDisplayValueArr);
  }, [boxSettings.language]);

  const handleInput = (e) => {
    setCampaignName(e.target.value);
    let copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, localVariant), value: { content: null }, custom_inputs_value: e.target.value };
    updateUniqueFieldsData(copyInputData, headerBoxesData, setHeaderBoxesData, boxFieldID, localVariant);
  };

  useEffect(() => {
    if (renderOnce && actualVariant) {
      setLocalVariant(actualVariant);
    }
  }, [actualVariant]);

  const handleDropdown = (e) => {
    let element = e.target;
    showCampaignDropdown(element, dropdownRef, setShadowActive);
  };

  const handleAddButton = () => {
    let campaignName = inputRef.current.value;
    if (campaignName) {
      setCampaignName(campaignName);
      createCampaign
        .createNewCampaign(boxFieldID, campaignName)
        .then((res) => {
          let copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, localVariant), value: { content: res.id } };
          updateUniqueFieldsData(copyInputData, headerBoxesData, setHeaderBoxesData, boxFieldID, localVariant);
          setDisplayDropdownData([{ name: res.default_name, id: res.id }, ...displayDropdownData]);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<InfoPopup type={'negative'} info={'campaign_dropdown'}/>)
        });
      dropDownArea.current.classList.remove("not-validated");
    } else {
      dropDownArea.current.classList.add("not-validated");
    }
  };

  const handleListElement = (e) => {
    let copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, localVariant), value: { content: e.target.value }, custom_inputs_value: e.target.dataset.nameContent };
    updateUniqueFieldsData(copyInputData, headerBoxesData, setHeaderBoxesData, boxFieldID, localVariant);
    setCampaignName(e.target.textContent);
    setShowDropdown(false);
    setShadowActive(false);
    dropDownArea.current.classList.remove("not-validated");
  };

  return (
    <CampaignDropdownContainer
      blockEdit={blockEdit}
      dropDownArea={dropDownArea}
      campaignValue={campaignName ? campaignName : getHeadersFieldContent(headerBoxesData, boxFieldID, localVariant) || ""}
      handleInput={handleInput}
      handleDropdown={handleDropdown}
      inputRef={inputRef}
      dropdownRef={dropdownRef}
      handleAddButton={handleAddButton}
      displayDropdownData={displayDropdownData}
      handleListElement={handleListElement}
      isShadowActive={isShadowActive}
      validate={isValidated}
      showDopdown={showDopdown}
      setShowDropdown={setShowDropdown}
    />
  );
};

export default AddNewOrderCampaignDropdown;
