const generateProductViewData = (variantsData) => {
  let productFieldValues = findProductBoxField(variantsData);
  let generatedProductViewData = [];

  if (productFieldValues && Array.isArray(productFieldValues) && productFieldValues.length > 0) {

    productFieldValues.forEach(value=>{
      generatedProductViewData.push({variant_id: value.variant_id, products: generateProductsValuse(value.value)});
    })

  }

  return generatedProductViewData
};

const findProductBoxField = (variantsData) => {
  for (let i = 0; i < variantsData.length; i++) {
    if(variantsData[i]?.variants){
      console.log(variantsData[i]);
      if (variantsData[i]?.variants[0]?.value && variantsData[i]?.variants[0]?.value[0]?.ean) return variantsData[i]?.variants;
    }

  }

  return false;
};

const generateProductsValuse = (productsData) =>{
  let productDataList = [];

  if(productsData && Array.isArray(productsData) && productsData.length > 0){
    productsData.forEach(value=>{
      productDataList.push({id: value.id || value.view_id, img_src: value.photos[0].url, rotate: value.rotate, display_width: value.display_width, display_height: value.display_height})
    })
  }


  return productDataList;
}

export default generateProductViewData;
