import React from "react";

const CalenderErrorrs = ({ error, chosenDateError, noSourceError, isNotNumeric }) => {
  return (
    <>
      {error ? <span className="error">musisz wybrać date</span> : null}
      {chosenDateError && <span className="error">*Wybrana data nie może być mniejsza od aktualnej</span>}
      {noSourceError && (
        <span className="error campaign">
          *Aby dodać datę <strong>musisz wybrać plik źródłowy</strong>
        </span>
      )}
      {isNotNumeric && <span className="error">*Czas może składać się tylko z cyfr</span>}
    </>
  );
};

export default CalenderErrorrs;
