import React, { useContext, useEffect, useState } from "react";
import BackgroundShadow from "../../../globalElements/BackgroundShadow";
import { ReactComponent as Icon } from "../../../../../assets/banners_to_accept_icon.svg";
import { ActiveAcceptPopupContext } from "../collapseElements/CollapseRow";
import { MainAppContext } from "../../../../MainAppComponent";
import imageServices from "../../../../services/imageServices";
import orderServices from "../../../../services/orderServices";
import { ReloadTableContext } from "../../../../Router";

import IMAGE_STATUS from "../../../../../globalEnums/IMAGE_STATUS";
import PageComponent from "../../Team/globalelements/PageComponent";
import { getFileBaseName } from "../../../../../functions/getFileBaseName";
import Loader from "../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../globalElements/Loader/LOADER_TYPE";
import InfoPopup from "../../Popup/InfoPopup";
import usePopup from "../../../../helper/usePopup";

const AcceptProjectsModal = ({ orderID, variantsData }) => {
  const { activeAcceptPopup, setActiveAcceptPopup } = useContext(ActiveAcceptPopupContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const [imageList, setImageList] = useState([]);
  const [orderImages, setOrderImages] = useState([]);
  const [acceptedImages, setAcceptedImages] = useState([]);
  const [error, setError] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [dataLoader, setDataLoader] = useState(false);
  const [orderVariantsList, setOrderVariantsList] = useState([]);
  const [page, setPage] = useState(0);
  const { initPopup } = usePopup();
  const sortImagesArray = (imagesArray) => {
    imagesArray.sort((a, b) => {
      // Porównaj wartości variant w obiektach a i b
      const variantA = a.variant;
      const variantB = b.variant;

      if (variantA < variantB) {
        return -1;
      } else if (variantA > variantB) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  useEffect(() => {
    setShadowActive(true);
    if (orderID) {
      imageServices
        .getImages(orderID)
        .then((res) => {
          let variantsList = variantsData.map((variant) => variant.id);

          let imagesArray = [];
          let acceptDataStructure = [];

          sortImagesArray(res);

          variantsList.forEach((variantIdHelper, index) => {
            imagesArray.push({
              variant: variantIdHelper,
              images: [],
              variantNumber: index,
            });

            acceptDataStructure.push({ variant: variantIdHelper, image_pk: null });

            for (let i = 0; i < res.length; i++) {
              if (res[i].image && res[i].status !== "cloned") {
                if (res[i].variant == variantIdHelper) {
                  imagesArray[index].images.push({ image_pk: res[i].pk, src: res[i].image });
                }
              }
            }
          });

          setImageList(imagesArray);
          setAcceptedImages(acceptDataStructure);
          setOrderImages(res);
          setOrderVariantsList(variantsList);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<InfoPopup type={'negative'} info={'accept_button'}/>)
        });

      orderServices.getOrderDetails(orderID).then((res) => {
        setOrderDetails(res);
      });
    }
  }, []);

  const handleBackBtn = () => {
    setActiveAcceptPopup(false);
    setShadowActive(false);
  };

  const handleOrderDetails = (body) => {
    if (orderID) {
      orderServices
        .changeOrderDetail(orderID, body)
        .then((res) => {
          setDataLoader(false);
          setActiveAcceptPopup(false);
          setShadowActive(false);
          setReloadComponent(true);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<InfoPopup type={'negative'} info={'accept_button'}/>)
        });
    }
  };

  const handleChangeOrderStatus = () => {
    let status = "";

    if (orderDetails?.formats_count == 1 || orderDetails?.has_first_acceptation) {
      status = "finished";
    } else {
      status = "renew";
    }

    if (status) {
      if (!orderDetails?.has_first_acceptation) {
        let body = { has_first_acceptation: true, status: status, assigned_to: null };
        handleOrderDetails(body);
      } else {
        let body = { status: status };
        handleOrderDetails(body);
      }
    }
  };

  const handleImagesAcceptation = async (index, maxRequestNumber, acceptedImagesList) => {
    if (index < maxRequestNumber) {
      try {
        if (orderImages[index].status != IMAGE_STATUS.CLONED) {
          //we dont want to interact with cloned images
          await imageServices.changeImageStatus(
            orderImages[index].pk,
            acceptedImagesList.includes(orderImages[index].pk) ? IMAGE_STATUS.ACCEPTED : IMAGE_STATUS.REJECTED
          );
        }

        handleImagesAcceptation(index + 1, maxRequestNumber, acceptedImagesList);
      } catch (error) {
        console.error(error);
        initPopup(<InfoPopup type={'negative'} info={'accept_button'}/>)
      }
    } else {
      handleChangeOrderStatus();
    }
  };

  const handleAcceptBtn = (e) => {
    let acceptedImagesList = [];

    acceptedImages.forEach((el) => {
      if (el.image_pk) acceptedImagesList.push(parseInt(el.image_pk));
    });

    if (acceptedImagesList.length > 0) {
      setDataLoader(true);
      handleImagesAcceptation(0, orderImages.length, acceptedImagesList);
    } else {
      setError("musisz wybrać zdjęcie");
    }
  };

  const selectBanner = (e) => {
    let imagePk = e.target.value;
    let variantID = e.target.dataset.variant;
    let acceptedImagesCopy = [...acceptedImages];

    for (let i = 0; i < acceptedImagesCopy.length; i++) {
      if (acceptedImagesCopy[i].variant == variantID) {
        acceptedImagesCopy[i].image_pk = imagePk;
        break;
      }
    }

    setAcceptedImages(acceptedImagesCopy);
  };

  const handleCheckbox = (variantID, imagePk) => {
    for (let i = 0; i < acceptedImages.length; i++) {
      if (acceptedImages[i].variant == variantID && acceptedImages[i].image_pk == imagePk) return true;
    }

    return false;
  };

  return (
    <>
      <div className="accept-project-popup">
        <div className="title-section">
          <figure>
            <Icon />
          </figure>
          <h3>
            Banery do <strong>akceptacji</strong>
          </h3>
        </div>
        <div className="projects-inputs">
          {orderVariantsList.length ? (
            <PageComponent page={page} setPage={setPage} data={orderVariantsList} showContent={true}>
              {imageList[page] && imageList[page].images.length > 0 ? (
                imageList[page].images.map((image, imageIndex) => {
                  return (
                    <div className="input-box">
                      <label>
                        <input
                          checked={handleCheckbox(orderVariantsList[page], image.image_pk)}
                          type="checkbox"
                          value={image.image_pk}
                          data-variant={orderVariantsList[page]}
                          onChange={selectBanner}
                        ></input>
                        <span className="checkmark"></span>
                        <div className={`image-section`}>
                          <figure className="input-banner">
                            <img src={image.src}></img>
                          </figure>
                          <span>{getFileBaseName(image.src)}</span>
                        </div>
                      </label>
                    </div>
                  );
                })
              ) : (
                <span>Brak grafik dla tego wariantu</span>
              )}
            </PageComponent>
          ) : (
            <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />
          )}

        </div>
        {error && <span className={`error`}>*musisz wybrać zdjęcie</span>}
        <div className="action-buttons">
          <div className="button">
            <button className="accept" onClick={handleAcceptBtn}>
              Zaakceptuj
            </button>
            <button className="back" onClick={handleBackBtn}>
              Powrót
            </button>
          </div>

        </div>
        <span className='cant-go-back'>Twojego wyboru nie będzie można cofnąć.</span>
        {dataLoader && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
      </div>

      <BackgroundShadow setShow={setActiveAcceptPopup} handleShadow={false}/>
    </>
  );
};

export default AcceptProjectsModal;
