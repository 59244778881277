export const addNewBoxFieldVariant = (variantsObj, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant, uniqueBoxData) => {
  let result = [];

  result = boxFieldsData.map((value) => {
    if (value.box_field == boxFieldID && !checkIfFieldIsUnique(value.box_field, uniqueBoxData)) {
      if (value.variants?.length > 0) {
        let lastIndex = value.variants.length - 1;
        let lastArrayElement = value.variants[lastIndex];
        value.variants = [...value.variants, { ...lastArrayElement, variant_id: actualVariant }];
      } else {
        value.variants = [...value.variants, variantsObj];
      }
    }

    return value;
  });

  setBoxFieldsData(result);
};

const checkIfFieldIsUnique = (field_id, uniqueBoxData) => {
  let result = false;
  uniqueBoxData.box_fields.forEach((el) => {
    if (el.id == field_id) result = true;
  });

  return result;
};
