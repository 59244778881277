import React, {useEffect, useRef} from 'react';
import positiveIcon from "../../../../assets/v.png";
import {useNavigate, useSearchParams} from "react-router-dom";
import usePopup from "../../../helper/usePopup";

const FileSendSuccess = ({ setReloadComponent }) => {
    const navigate = useNavigate();
    const { closePopup } = usePopup();
    const [searchParams,setSearchParams] = useSearchParams();
    const temp = useRef(null);

    useEffect(() => {
        temp.current = setTimeout(() => {
            closePopup();
            setSearchParams({})
        }, 5000);
        return () => {
            clearTimeout(temp.current);
        };
    }, [navigate, closePopup]);
    return (
        <div className={`new-order-popup positive`} onClick={e => e.stopPropagation()}>
            <div className={`popup-contents positive`}>
                <div className="popup-upper-part">
                    <img className="icon-section" src={positiveIcon}></img>
                    <span className={`text-small positive`}>projekt został</span>
                    <span className={`text-big positive`}>wysłany <br/> do accounta</span>
                </div>
                <span className='text-center' style={{cursor:'pointer', fontWeight:400}} onClick={()=> {
                    navigate('/sendedprojects')
                    window.location.reload();
                    closePopup();
                }}><strong>kliknij tutaj,</strong> <br/> aby sprawdzić jego status.</span>
                <div className="popup-lower-part">
                    <div className="popup-buttons">
                        <button className={`popup-button first positive`}
                                onClick={()=> {
                                    clearTimeout(temp.current); // Użyj temp.current, aby uzyskać aktualny identyfikator
                                    setReloadComponent(true);
                                    closePopup();
                                    setSearchParams({})
                                }}>Powróć do TEAM</button>
                    </div>
                    <div className='text-last-container'>
                    <span className={`text-last positive`}>Popup zniknie automatycznie za <strong>3 sekund</strong></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileSendSuccess;
