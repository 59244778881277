import React, { useContext, useEffect, useState } from "react";
import { MainAppContext } from "../../../MainAppComponent";
import BackgroundShadow from "../../globalElements/BackgroundShadow";
import Slider from "../Table/Slider";
import checkPrefix from "../../../../functions/checkPrefix";
import MODAL_TYPES from "./ENUMS/IMAGE_MODAL_TYPES";
import { getFileBaseName } from "../../../../functions/getFileBaseName";
import { checkIfImageIsBas64 } from "../../../../functions/checkIfImageIsBas64";

const ImageModal = ({ imageSource, setShowImage, setShowImageModal, modalType, imagesList, isOutSideLink }) => {
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);

  useEffect(() => {
    setShadowActive(true);
  }, []);

  return (
    <>
      <div className={modalType == MODAL_TYPES.CAROUSEL ? "image-modal carousel" : "image-modal"}>
        <div className="close-button" onClick={() =>{
            setShowImageModal(false)
            setShowImage(false);
        } }></div>
        {modalType == MODAL_TYPES.CAROUSEL && imagesList.length ? (
          <Slider spaceBetween={5} slidesPerView={1} slides={imagesList} hasCustomNavigation={true} keyBoardControl={true} />
        ) : (
          <>
            <div className="close-button" onClick={() =>{
                setShowImageModal(false)
                setShowImage(false);
            } }></div>
            <figure>
              <img src={isOutSideLink ? imageSource : checkPrefix(imageSource)}></img>
            </figure>
            {!checkIfImageIsBas64(imageSource) && <span className="image-name">{getFileBaseName(imageSource)}</span>}
          </>
        )}
      </div>
      <BackgroundShadow setShow={setShowImageModal} setShowImage={setShowImage} isShadowActive={isShadowActive} handleShadow={true}/>
    </>
  );
};

export default ImageModal;
