import React, {useState, useContext, useRef} from "react";
import CalenderContainer from "../calenderElements/CalenderContainer";
import { MainAppContext } from "../../MainAppComponent";

const CalenderFromTo = ({chosenDateFrom, setChosenDateFrom, chosenDateTo, setChosenDateTo, handleFilter, calenderRef, setShowDropdown}) => {
  const [hour, setHoure] = useState("");
  const [minute, setMinute] = useState("");
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const [error, setError] = useState(false);

  const [displayCalenderFrom, setDisplayCalenderFrom] = useState(false);
  const [displayCalenderTo, setDisplayCalenderTo] = useState(false);

  const calendarFromRef = useRef();
  const calendarToRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();

  const disactiveOnDeadlineFromChose = () => {
      setShadowActive(false);
      setDisplayCalenderFrom(false);
  }
  const disactiveOnDeadlineToChose = () => {
    setShadowActive(false);
    setDisplayCalenderTo(false)
  };

  const handleCancelButton = () => {
    setChosenDateFrom("");
    setChosenDateTo("");
    if(setShowDropdown) setShowDropdown(false);
  };
  
  return (
    <div className="filter-deadline calender-from-to" ref={calenderRef}>
      <div className="inputs">
        <div className="calender-input">
          <span>od </span>
          <CalenderContainer
            blockEdit={false}
            calendarRef={calendarFromRef}
            hour={hour}
            minute={minute}
            hourRef={hourRef}
            minuteRef={minuteRef}
            setChosenDate={setChosenDateFrom}
            setHoure={setHoure}
            setMinute={setMinute}
            chosenDate={chosenDateFrom}
            error={error}
            inputValidated={true}
            handleShadow={false}
            calenderValue={chosenDateFrom}
            allowChoseAnyDate={true}
            showTimeInput={false}
            disActiveOnDateChose={disactiveOnDeadlineFromChose}
            displayCalender={displayCalenderFrom}
            setDisplayCalender={setDisplayCalenderFrom}
            autoDateSet={true}
          />
        </div>
        <div className="calender-input">
          <span>do </span>
          <CalenderContainer
            blockEdit={false}
            calendarRef={calendarToRef}
            hour={hour}
            minute={minute}
            hourRef={hourRef}
            minuteRef={minuteRef}
            setChosenDate={setChosenDateTo}
            setHoure={setHoure}
            setMinute={setMinute}
            chosenDate={chosenDateTo}
            error={error}
            inputValidated={true}
            handleShadow={false}
            calenderValue={chosenDateTo}
            allowChoseAnyDate={true}
            showTimeInput={false}
            disActiveOnDateChose={disactiveOnDeadlineToChose}
            displayCalender={displayCalenderTo}
            setDisplayCalender={setDisplayCalenderTo}
            autoDateSet={true}
          />
        </div>
      </div>
      <div className="actions">
            <button className="cancel" onClick={handleCancelButton}>
              Anuluj
            </button>
            <button className="accept" onClick={handleFilter}>
              Zastosuj
            </button>
          </div>
    </div>
  );
};

export default CalenderFromTo;
