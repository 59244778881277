import React, { useContext } from "react";
import duplicateOrder from "./functions/duplicateOrder";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions, {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import { useNavigate } from "react-router-dom";
import usePopup from "../../../../../helper/usePopup";
import Format from "../../../Popup/AddOrder/Format"
const FormatButton = ({ orderID, order_name, variantID, isReformat, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { initPopup, closePopup } = usePopup();
  const navigate = useNavigate();

  const handleFormatBtn = (orderName, calenderDeadline) => {
    let link = `/reformatting/?&create_format=true&order=${orderID}&variant=${variantID}&order_name=${orderName}&deadline=${calenderDeadline}`;
    closePopup();
    navigate(link);
  };

  return (
    <>
      {shouldDisplayButton('format', rowData?.order_status,user,) !== 'Brak' && !isReformat && (
        <>
          <button className={`format  ${shouldDisplayButton('format', rowData?.order_status,user,) === 'Szary' ? 'casual' : null}`} onClick={()=> initPopup(<Format contentType={'format'} handleAction={handleFormatBtn}/>)}>
            Formatuj
          </button>
        </>
      )}
    </>
  );
};

export default FormatButton;
