import React from "react";

const Row = ({
  children,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddintBottom,
  rowBorder,
  title,
}) => {
  return (
    <div
      className={`row ${rowBorder ? "row-border" : ""} ${
        title ? "title-height" : ``
      }`}
      style={{
        padding: `${paddingTop || 0} ${paddingRight || 0} ${
          paddintBottom || 0
        } ${paddingLeft || 0}`,
      }}
    >
      {children}
    </div>
  );
};

export default Row;
