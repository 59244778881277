import React, { useContext, useState, useRef, useEffect } from "react";
import { ProductViewContext, AddProductPopupContext } from "../OrderBox/OrderBoxContent";
import { MainAppContext } from "../../../MainAppComponent";
import noPhotoPlaceholder from "../../../../assets/no-photos.svg";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import handleDisplayProductView from "./helper/handleDisplayProductView";

const ProductView = ({ keyValue, productBoxID, variant }) => {
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { showPopup, setShowPopup } = useContext(AddProductPopupContext);
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);
  const [hidePorductView, setHideProductView] = useState(false);
  const imagesRef = useRef([]);

  const addNewProduct = () => {
    setShadowActive(true);
    setShowPopup({ ...showPopup, active: true });
  };

  const handleRemoveItem = (e) => {
    let idValue = e.target.parentNode.parentNode.parentNode.dataset.view,
      newProductViewData;

    newProductViewData = productViewData.map((el) => {
      if (el.id == idValue) {
        return { ...el, ean: null, img_src: null, scale: 1, rotate: 0 };
      }
      return el;
    });

    setProductViewData(newProductViewData);
  };

  const getImageScale = (idValue) => {
    let scale = 1;
    productViewData.forEach((el) => {
      if (el.id == idValue) {
        scale = el.scale;
      }
    });

    return scale;
  };

  const getImageWidth = (idValue) => {
    let scale = 1;
    productViewData.forEach((el) => {
      if (el.id == idValue) {
        scale = el.width;
      }
    });

    return scale;
  };

  const getImageHeight = (idValue) => {
    let scale = 1;
    productViewData.forEach((el) => {
      if (el.id == idValue) {
        scale = el.height;
      }
    });

    return scale;
  };

  const getImageRotation = (idValue) => {
    let rotate = 0;
    productViewData.forEach((el) => {
      if (el.id == idValue) {
        rotate = el.rotate;
      }
    });

    return rotate;
  };

  const handleScale = (e) => {
    let parent = e.target.parentNode.parentNode.parentNode;
    let idValue = parent.dataset.view,
      scale = getImageScale(idValue),
      width = getImageWidth(idValue),
      height = getImageHeight(idValue),
      rotate = getImageRotation(idValue);
    let newView;
    let image = parent.querySelector("img");

    let imgWidth = image.clientWidth;

    if (e.target.classList.contains("plus")) {
      width += 20;
      height += 20;
      imgWidth += 20;

      image.style.width = `${imgWidth}px`;
    } else {
      // scale -= 0.3;
      if (imgWidth <= 20) {
        imgWidth = 20;
      } else {
        width -= 20;
        height -= 20;
        imgWidth -= 20;
      }

      image.style.width = `${imgWidth}px`;
    }

    newView = productViewData.map((el) => {
      if (el.id == idValue) {
        return { ...el, width: width, height: height, display_width: imgWidth };
      } else {
        return el;
      }
    });
    setProductViewData(newView);
  };

  const handleRotate = (e) => {
    let parent = e.target.parentNode.parentNode.parentNode;
    let idValue = parent.dataset.view,
      rotate = getImageRotation(idValue),
      scale = getImageScale(idValue),
      newView;

    let image = parent.querySelector("img");
    if (rotate >= 360) rotate = 0;
    rotate += 90;

    image.style.transform = `rotate(${rotate}deg)`;

    newView = productViewData.map((el) => {
      if (el.id == idValue) {
        return { ...el, rotate: rotate };
      } else {
        return el;
      }
    });

    setProductViewData(newView);
  };

  const handleRefreshImage = (e) => {
    let idValue = e.target.parentNode.parentNode.parentNode.dataset.view,
      rotate = getImageRotation(),
      newView;
    imagesRef.current.forEach((el) => {
      if (el !== null && el.dataset.view == idValue) {
        el.style.transform = `rotate(0deg) scale(1)`;
      }
    });

    newView = productViewData.map((el) => {
      if (el.id == idValue) {
        return { ...el, rotate: 0, scale: 1 };
      } else {
        return el;
      }
    });

    setProductViewData(newView);
  };

  const handleGrammage = (e) => {
    let idValue = e.target.dataset.view;
    let newView = productViewData.map((el) => {
      if (el.id == idValue) {
        return { ...el, grammage: e.target.value };
      } else {
        return el;
      }
    });

    setProductViewData(newView);
  }

  useEffect(() => {
    let boxes = boxSettings.hidden_boxes;
    setHideProductView(handleDisplayProductView(boxes, productBoxID, variant));
  }, [boxSettings]);

  return (
    <div className={hidePorductView ? "view inactive" : "view"}>
      <div className="wrapper">
        <div className="title">
          <h2>Podgląd przykładowego ustawienia produktów i ich wielkości</h2>
        </div>
        <div className="products">
          <div className="products-wrapper">
            {productViewData
              ? productViewData.map((el, index) => {
                  return (
                    <>
                      {el.ean ? (
                        <div className="product-block-wrapper" data-view={el.id}>
                          <div className="block" key={el.id} data-view={el.id}>
                            <div className="action">
                              <span className="letter">{el.id}</span>
                              <div className="icons">
                                <div className="icon minus" onClick={handleScale}></div>
                                <div className="icon plus" data-view={el.id} onClick={handleScale}></div>
                                <div className="icon rotate" data-view={el.id} onClick={handleRotate}></div>
                                <div className="icon refresh" onClick={handleRefreshImage}></div>
                                <div className="icon remove" onClick={handleRemoveItem}></div>
                              </div>
                            </div>
                            <div className={el.img_src ? "photo" : "photo no-photo"}>
                              <figure>
                                <img
                                  src={el.img_src || noPhotoPlaceholder}
                                  data-view={el.id}
                                  ref={(el) => {
                                    imagesRef.current.push(el);
                                  }}
                                  style={{ transform: `rotate(${el.rotate}deg)`, width: el.display_width }}
                                ></img>
                              </figure>
                            </div>
                          </div>
                          <div className="grammage">
                            <h4>Gramatura:</h4>
                            <input value={el.grammage} onChange={handleGrammage} data-view={el.id}></input>
                          </div>
                        </div>
                        
                      ) : null}
                    </>
                  );
                })
              : null}
            <div className="block add" onClick={addNewProduct} key={`view-last`}>
              <div className="action">
                <span className="letter">A</span>
                <div className="icons">
                  <div className="icon minus"></div>
                  <div className="icon plus"></div>
                  <div className="icon rotate"></div>
                  <div className="icon refresh"></div>
                  <div className="icon remove"></div>
                </div>
              </div>
              <div className="add-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
