import React, { useContext, useRef } from "react";
import { TableDataContext } from "../../../../Router";

import TableBodyRows from "./TableBodyRows";
import { OrderderIdToRemove } from "../../../../Router";
import { UserContext } from "../../../../wrapper/AuthWrap";
import TABLE_TYPES from "../../../../../globalEnums/TABLE_TYPES_ENUMS";
import formatDate from "../../../../../functions/formatDate";
import generateTableElementsClass from "../collapseElements/helper/generateTableElementsClass";

const TableBody = () => {
  const { user, setUser } = useContext(UserContext);
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { removeOrderID, setRemoveOrderID } = useContext(OrderderIdToRemove);

  const tableRef = useRef();

  const checkIfDisplay = (order_id) => {
    let ordersArr = removeOrderID.noDisplayOrder;
    for (let i = 0; i < ordersArr.length; i++) {
      if (order_id == ordersArr[i]) {
        return true;
      }
    }

    return false;
  };

  const choseCelDataByOrderField = (rowData, defined_field) =>{
    //if order field name is different than defined_field from tableConfiguration, choose correct fields in switch
    switch(defined_field){
      case 'language':
        return rowData.language_name?.custom_input_value || null
      case 'status':
        return tableContext.tableType == TABLE_TYPES.FINISHED ? rowData.designer_status_label : rowData.status_label;
      case 'order_created_at':
        return formatDate(rowData.created_at)
      case 'created_at':
        return formatDate(rowData.created_at)
      case 'is_read':
        return rowData.is_read ? "przeczytane" : "nieodczytane";
      default:
       return rowData[defined_field]
    }
  }

  const choseCelDataByBoxField = (boxFields, searched_box_field) =>{
    let result = '';

    for(let i=0; i< boxFields.length; i++){
      if(boxFields[i].box_field == searched_box_field){
        if(boxFields[i].order){
          return boxFields[i].order[0].value;
        }else if(boxFields[i].variants){
          return boxFields[i].variants[0].value;
        }
      }
    }

    return result
  }

  const generateCelData = (rowData) =>{
    let celData = [];
    let tableConfiguration = tableContext.tableConfiguration;

    tableConfiguration.forEach(el=>{
      celData.push({...el, value: el.defined_field ? choseCelDataByOrderField(rowData, el.defined_field) : choseCelDataByBoxField(rowData.box_field_values, el.box_field) })
    })

    return celData
  }

  return (
    <div className={generateTableElementsClass("tbody ",tableContext.tableType, user)} ref={tableRef}>
      {tableContext.tableData
        ? tableContext.tableData.map((el, index) => {
            if (index >= (tableContext.page - 1) * tableContext.postPerPage && index < tableContext.page * tableContext.postPerPage) {

              let rowData = {
                status: el.status,
                order_id: el.id,
                data_index: index,
                is_reformat: el.is_reformat,
                is_read: null,
                notification_id: null,
                reformat_sizes: null,
                order_reformat: null,
                order_status: el.status ,
                is_counter: el.is_counter,
                acc_note: el.acc_note,
                agency_internal_url: el.agency_internal_url || '',
                file_url: el.file_url || '',
                action_url: el.action_url || '',
                stock_file_url: el.stock_file_url,
                has_first_acceptation: el.has_first_acceptation,
                assigned_to_note : el.assigned_to_note,
                is_duplicated: el.source_obj_id,
                is_observed: el.is_observed,
                order_locks : el.order_locks,
                is_restored : el.is_restored,
                custom_size_list: el.custom_size_list,
                is_before_any_work: el.is_before_any_work,
                assigned_to: el.assigned_to,
                is_favourite: el.is_favourite
              };

              if (tableContext.tableType == TABLE_TYPES.NOTIFICATION) {
                rowData.is_read = el.is_read || "";
                rowData.notification_id = el.id || "";
                rowData.order_id = el.order;
              } else {
                rowData.reformat_sizes = el.reformat_sizes;
                rowData.order_reformat = el.order_reformat;
              }

              if (!checkIfDisplay(rowData.order_id)) {
                return (
                  <TableBodyRows
                    acceptedProject={el.status == "accepted_acc" ? true : false}
                    celData={generateCelData(el)}
                    rowData={rowData}
                    key={`tbody-${index}`}
                    user={user}
                  />
                );
              }
            }
          })
        : null}
    </div>
  );
};

export default TableBody;
