import React from 'react';
import negativeIcon from "../../../../assets/icon-negative.png";
import usePopup from "../../../helper/usePopup";
const ContinuePopup = ({setChangeIndividualCheckboxValue}) => {
    const { closePopup } = usePopup();

 const handleFirstButton = () => {
    closePopup();
    setChangeIndividualCheckboxValue(true);
 }


 return (
     <div className={`info-popup continue`} onClick={e => e.stopPropagation()}>
         <div className={`popup-contents continue`}>
             <div className="popup-upper-part">
                <img className="icon-section" src={negativeIcon}></img>
                 <span className={`text-small continue`}>wprowadzona zmiana</span>
                 <span className={`text-big continue`}>Wpłynie <br/> na pozostałe<br/> warianty</span>
             </div>
             <div className="popup-lower-part">
                 <div className="popup-buttons">
                     <button className={`popup-button only positive`}
                             onClick={handleFirstButton}>Ok
                     </button>
                     <button className={`popup-button only neutral`}
                             onClick={()=> closePopup()}>Anuluj
                     </button>
                 </div>
                 <div className='text-last-container'>
                 <span className={`text-last continue`}>Twojego wyboru nie będzie można cofnąć.</span>
                 </div>
             </div>
         </div>
     </div>
 );
};

export default ContinuePopup;
