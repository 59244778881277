const FILTER_BAR_TYPES = {
  TEAM: 'team',
  TABLE: 'table',
  MY_PROJECT_BOX: "my_project_box",
  NEW_ORDER_BOX: "new_order_box",
  TEAM_BOX_ACC: "team_box_acc",
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
  DRAFT: 'draft',
  NOTIFICATION: "notification",
  NEW:"new",
}

export default FILTER_BAR_TYPES
