import React, { useState, useContext, useEffect, useRef } from "react";
import Month from "./Month";
import Weekdays from "./Weekdays";
import TimeInput from "./TimeInput";
import CalenderErrorrs from "./Errors";
import BackgroundShadow from "../globalElements/BackgroundShadow";
import { MainAppContext } from "../../MainAppComponent";
import renderCalendar from "../../../functions/calenderFunctions/renderCalender";
import showCalender from "../../../functions/calenderFunctions/showCalender";
import setCorrectDate from "../../../functions/calenderFunctions/setCorrectDate";
import handleDisplayMonth from "../../../functions/calenderFunctions/handleDisplayMonth";
import { isNumeric } from "../../../functions/isNumeric";
import handleCalenderInputs from "../../../functions/calenderFunctions/handleCalenderInputs";
import { month_list } from "./month_list";
import { ReactComponent as DropdownIcon } from "../../../assets/dropdownicon.svg"
/**
 * CalenderContainer - visual component for calender deadline
 *
 * that component required declaration of all fields and methods from props
 *
 * Example components based on CalenderContainer: AddNewOrderCalenderDeadline.js, ReformatingCalenderDeadline.js
 */

const CalenderContainer = ({
  blockEdit,
  calenderValue,
  setUpDeadline,
  hour,
  minute,
  hourRef,
  minuteRef,
  error,
  noSourceError,
  inputValidated,
  setChosenDate,
  setHoure,
  setMinute,
  chosenDate,
  handleShadow,
  allowChoseAnyDate,
  showTimeInput,
  disActiveOnDateChose,
  displayCalender,
  setDisplayCalender,
  disallowPosition,
  deadline,
  placeholder,
  autoDateSet
}) => {
  let getDate = new Date();
  let getCurrYear = getDate.getFullYear();
  let getCurrMonth = getDate.getMonth();

  const months = month_list;

  const [date, setDate] = useState(getDate);
  const [currentDays, setCurrentDays] = useState([]);
  const [currYear, setCurrYear] = useState(getCurrYear);
  const [currMonth, setCurrMonth] = useState(getCurrMonth);
  const [currentMonthName, setCurrentMonthName] = useState(months[currMonth]);
  const [chosenDateError, setChosenDateError] = useState(false);
  const [isNotNumeric, setIsNotNumeric] = useState(false);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);

  const positionToElementRef = useRef();
  const calenderDropdownRef = useRef();

  useEffect(() => {
    renderCalendar(setCurrentDays, setCurrentMonthName, currYear, currMonth, date, handleDate);
  }, [date]);

  const handleCalender = (e) => {
    showCalender(setDisplayCalender, chosenDateError, setChosenDateError, setShadowActive, isShadowActive, handleShadow);
  };

  const handleDate = (e) => {
    let dateElement = e.target;

    let liList = document.querySelectorAll(".days li");

    setCorrectDate(dateElement, setChosenDateError, setChosenDate, liList, allowChoseAnyDate, autoDateSet);
    if (disActiveOnDateChose) disActiveOnDateChose();
  };

  const handleCalenderButton = (e) => {
    let element = e.target;
    handleDisplayMonth(element, currMonth, currYear, setCurrMonth, setDate, setCurrYear);
    renderCalendar(setCurrentDays, setCurrentMonthName, currYear, currMonth, date, handleDate);
  };

  const handleTimeInputs = (e) => {
    let element = e.target;
    let value = e.target.value;
    if (isNumeric(value)) {
      setIsNotNumeric(false);
      handleCalenderInputs(element, value, setChosenDateError, setHoure, setMinute, chosenDate, minuteRef, hourRef);
    } else {
      setIsNotNumeric(true);
    }
  };

  const positionDropdown = () => {
    let relativeElement = positionToElementRef.current;
    let dropdown = calenderDropdownRef.current;

    if (relativeElement && dropdown) {
      let rect = relativeElement.getBoundingClientRect();

      dropdown.style.position = "fixed";
      dropdown.style.top = rect.bottom + "px";
      dropdown.style.left = rect.left + "px";
      dropdown.style.zIndex = "99999999";
      dropdown.style.maxWidth = relativeElement.getBoundingClientRect().width + "px";
    }
  };

  useEffect(() => {
    if (!disallowPosition && positionToElementRef) {
      positionDropdown();
      window.addEventListener("scroll", positionDropdown);
      document.addEventListener("click", handleWindowCLick);
    }
  }, [displayCalender]);

  const handleWindowCLick = (e) => {
    if (
      calenderDropdownRef.current &&
      e.target !== calenderDropdownRef.current &&
      e.target !== positionToElementRef?.current &&
      !calenderDropdownRef?.current.contains(e.target) &&
      !positionToElementRef?.current.contains(e.target)
    ) {
      if (setDisplayCalender) setDisplayCalender(false);
      document.removeEventListener("click", handleWindowCLick);
    }
  };

  return (
    <>
      <div className={blockEdit ? "calendar-deadline blocked" : `calendar-deadline ${displayCalender ? "visible" : ""}`}>
        <input
          className={inputValidated ? "calender-data" : "calender-data not-validated"}
          onClick={handleCalender}
          placeholder={placeholder ? placeholder : 'wybierz deadline'}
          readOnly="readonly"
          style={deadline ? { height:'25px'} : null}
          value={calenderValue}
          ref={positionToElementRef}
        ></input>
        <DropdownIcon className='arrow-down' onClick={handleCalender} />
        {displayCalender && (
          <div className="wrapper" ref={calenderDropdownRef}>
            <div className="calendar-container visible" >
              <Month handleCalenderButton={handleCalenderButton} currentMonthName={currentMonthName} />
              <Weekdays currentDays={currentDays} />
              {showTimeInput && setUpDeadline && (
                <TimeInput
                  handleTimeInputs={handleTimeInputs}
                  setUpDeadline={setUpDeadline}
                  hour={hour}
                  minute={minute}
                  hourRef={hourRef}
                  minuteRef={minuteRef}
                />
              )}
              <CalenderErrorrs error={error} noSourceError={noSourceError} chosenDateError={chosenDateError} isNotNumeric={isNotNumeric} />
            </div>
          </div>
        )}
      </div>
      {handleShadow && displayCalender && <BackgroundShadow setShow={setDisplayCalender} handleShadow={true}/>}
    </>
  );
};

export default CalenderContainer;
