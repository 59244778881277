import React from "react";

const TimeInput = ({ handleTimeInputs, setUpDeadline, hour, minute, hourRef, minuteRef }) => {
  return (
    <div className="time-input">
      <label>
        Godzina zakończenia
        <input onChange={handleTimeInputs} placeholder="__" className="hour" maxLength={2} value={hour ?? ""} ref={hourRef}></input>:<input onChange={handleTimeInputs} className="minute" maxLength={2} value={minute ?? ""} ref={minuteRef} placeholder="__"></input>
      </label>
      <button className="set-up" onClick={setUpDeadline}>
        Ustaw
      </button>
    </div>
  );
};

export default TimeInput;
