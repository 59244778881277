import React, { useContext, useState, useEffect } from "react";
import { MainAppContext } from "../../../../MainAppComponent";

import { AddNewOrderContext, AddProductPopupContext, ProductViewContext } from "../../../../pages/e-commerce/AddNewOrder";
import handleDisplayProductView from "../helper/handleDisplayProductView";
import {ReactComponent as ProductViewIcon} from "../../../../../assets/product-view.svg"

import Product from "./Product";

const ProductView = ({ keyValue, productBoxID, variant }) => {
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { showPopup, setShowPopup } = useContext(AddProductPopupContext);
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);
  const [hideProductView, setHideProductView] = useState(false);

  const addNewProduct = () => {
    setShadowActive(true);
    setShowPopup({ ...showPopup, active: true });
  };
  useEffect(() => {
    let boxes = boxSettings.hidden_boxes;
    setHideProductView(handleDisplayProductView(boxes, productBoxID, variant));
  }, [boxSettings]);

  return (
    <div className={hideProductView ? "view inactive" : "view"}>
      <div className="wrapper">
        <div className="title">
          <ProductViewIcon/>
          <h2>Podgląd produktu</h2>
        </div>
        <div className="products">
          <div className="products-wrapper">
            {productViewData
              ? productViewData.map((el, index) => {
                  return (
                    <>
                      <Product el={el}
                               index={index}
                               productViewData={productViewData}
                               setProductViewData={setProductViewData}
                      />
                    </>
                  );
                })
              : null}
            <button className="add-new-product" onClick={addNewProduct} key="add-new-product">
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
