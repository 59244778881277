import React, { useState, useContext } from "react";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import CounterPopup from "../../../Popup/CounterPopup";
import usePopup from "../../../../../helper/usePopup";
import { ReloadTableContext } from "../../../../../Router";

const AddCounterBtn = ({ orderID, isCounter, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const { setReloadComponent } = useContext(ReloadTableContext);
  return (
    <>
      {!isCounter && checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ADD_COUNTER_BUTTON) &&  (
        <>
          <button className={`add-counter ${rowData?.order_status ? rowData.order_status === 'cancelled' ? 'casual' : null : null}`} onClick={()=> initPopup(<CounterPopup orderID={orderID} setReloadComponent={setReloadComponent}/>)}>
            Dodaj licznik
          </button>
        </>
      )}
    </>
  );
};

export default AddCounterBtn;
