import React, { useContext } from "react";
import duplicateOrder from "./functions/duplicateOrder";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import { useNavigate } from "react-router-dom";
import ChangeDeadlinePopup from "../../../Popup/PopupComponents/ChangeDeadlinePopup";
import usePopup from "../../../../../helper/usePopup";

const FormatButton = ({ orderID, order_name, variantID, isReformat, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { initPopup, closePopup } = usePopup();
  const navigate = useNavigate();

  const handleFormatBtn = (orderName, calenderDeadline) => {
    let link = `/reformatting/?&create_format=true&order=${orderID}&variant=${variantID}&order_name=${orderName}&deadline=${calenderDeadline}`;
    closePopup();
    navigate(link);
  };

  return (
    <>
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.FORMAT_BUTTON) && !isReformat && (
        <>
          <button className={`format  ${rowData?.order_status ? rowData.order_status === 'cancelled' ? 'casual' : null : null}`} onClick={()=> initPopup(<ChangeDeadlinePopup contentType={'format'} handleAction={handleFormatBtn}/>)}>
            Formatuj
          </button>
        </>
      )}
    </>
  );
};

export default FormatButton;
