import React, { useState, useContext, useEffect, createContext, useMemo } from "react";
import OrderBox from "./OrderBox";
import ProductView from "../OrderBoxFields/ProductView";
import AddProductPopup from "../Popup/AddProductPopup";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { useSearchParams } from "react-router-dom";

export const ProductViewContext = createContext(null);
export const AddProductPopupContext = createContext(null);

export const BoxesLanguageContext = createContext(null);

const OrderBoxContent = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const { boxData, setBoxData } = useContext(AddNewOrderContext);
  const [productBoxID, setProductBoxID] = useState(null);

  let viewData = [
    {
      id: "A",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "B",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "C",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "D",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "E",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "F",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "G",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "H",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
    {
      id: "I",
      img_src: null,
      image_external_id: null,
      scale: 1,
      rotate: 0,
      external_id: null,
      ean: null,
      plu: null,
      name: null,
      quantity: 1,
      weight: null,
      width: null,
      height: null,
      display_width: "100%",
      grammage: ''
    },
  ];

  const [col_1_elements, set_col_1_elements] = useState([]);
  const [col_2_elements, set_col_2_elements] = useState([]);
  const [col_3_elements, set_col_3_elements] = useState([]);
  const [activeProductView, setActiveProductView] = useState(false);

  const [productViewData, setProductViewData] = useState(viewData);
  const productViewValue = useMemo(() => ({ productViewData, setProductViewData }), [productViewData, setProductViewData]);

  const [showPopup, setShowPopup] = useState({ active: false, idValue: "undefined" });
  const popupValue = useMemo(() => ({ showPopup, setShowPopup }), [showPopup, setShowPopup]);

  const getAddProductFieldID = () => {
    let resultID = null;
    for (let i = 0; i < boxData.length; i++) {
      let boxFields = boxData[i].box.box_fields;
      for (let j = 0; j < boxFields.length; j++) {
        if (boxFields[j].field_type == "product") {
          resultID = boxFields[j].id;
          break;
        }
      }

      if (resultID) break;
    }

    return resultID;
  };
  const findProductViewData = () => {
    let addProductFieldID = getAddProductFieldID();
    let resultData;
    for (let i = 0; i < boxFieldsData.length; i++) {
      if (boxFieldsData[i].box_field == addProductFieldID) {
        resultData = boxFieldsData[i];
        break;
      }
    }

    return resultData;
  };
  useEffect(() => {
    let productView = findProductViewData();
    if (productView) {
      let variant_data
      let productViewVariants = productView.variants
      
      if(searchParams.get("order_status") || searchParams.get("edit_order")){
        productViewVariants.forEach(el=>{
          if(el.variant_id == props.variant){
            variant_data=el;
          }
        })
      }else{
        // let lastIndex = productViewVariants.length - 1;
        variant_data = productViewVariants[props.chosenTabIndex];
      }
      
      let valueLength = 0;
      if (variant_data?.value.length > 0) valueLength = variant_data.value.length;

      if (valueLength) {
        viewData = viewData.map((el) => {
          let obj = el;
          variant_data.value.forEach((valueElement, index) => {
            if (el.id == valueElement.view_id) {
              obj = {
                id: el.id,
                img_src: valueElement.photos[0].url,
                image_external_id: valueElement.photos[0].external_id,
                scale: valueElement.scale,
                rotate: valueElement.rotate,
                external_id: valueElement.external_id,
                ean: valueElement.ean,
                plu: valueElement.plu,
                name: valueElement.name,
                quantity: valueElement.quantity,
                weight: valueElement.weight,
                width: valueElement.width,
                height: valueElement.height,
                display_width: valueElement.display_width,
                grammage: valueElement.grammage
              };
            }
          });

          return obj;
        });

        setProductViewData(viewData);
      }
    }
  }, [boxData, props.variant]);

  useEffect(() => {
    let col_1 = [],
      col_2 = [],
      col_3 = [];

    boxData.forEach((value, index) => {
      if (value.box.box_fields[0]?.field_type == "product") {
        setActiveProductView(true); // <---- new solution
        setProductBoxID(value.box.id);
      }

      if (value.column == 1) {
        col_1.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            boxData={value}
            arget={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      } else if (value.column == 2) {
        col_2.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            boxData={value}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      } else if (value.column == 3) {
        col_3.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            boxData={value}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      }
    });

    set_col_1_elements(col_1);
    set_col_2_elements(col_2);
    set_col_3_elements(col_3);
  }, [boxData]);

  return (
    <AddProductPopupContext.Provider value={popupValue}>
      <ProductViewContext.Provider value={productViewValue}>
        <div className={props.visible ? "box-content" : "box-content inactive"} data-target={props.target}>
          <div className={props.visible ? "box-wrapper" : "box-wrapper inactive"} data-target={props.target}>
            <div className="row">
              <div className="column col-1">{col_1_elements}</div>
              <div className="column col-2">{col_2_elements}</div>
              <div className="column col-3">{col_3_elements}</div>
            </div>
            {activeProductView ? <ProductView keyValue={`${props.target}-view`} productBoxID={productBoxID} variant={props.variant}/> : null}
          </div>
          {showPopup.active ? <AddProductPopup /> : null}
        </div>
      </ProductViewContext.Provider>
    </AddProductPopupContext.Provider>
  );
};

export default OrderBoxContent;
