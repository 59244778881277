/**
 * copyToClipboard() - method that copy value of passed element to clipboard
 *
 * @param {String} element
 */

const copyToClipboard = (element) => {
  navigator.clipboard.writeText(element.target.dataset.value);
};

export default copyToClipboard;
