const INFO = {
  save_data_info: "Aby wykonać akcję uzupełnij zaznaczone pola",
  variant_info: "Aby dodać nowy wariant uzupełnij zaznaczone pola",
  accept_info: "Zaakceptowano zamówienie",
  acc_accept_info: "Zaakceptowano zamówienie",
  archive: "Pomyślnie zapisano w archiwum",
  no_permission: "Przykro nam, ale nie posiadasz uprawnień do tej akcji",
  reject_info: "Odrzucono projekt",
  archive_accepted: "Pomyślnie zaakceptowane projekt i zapisano w archiwum",
  forward_to_graphic: "Pomyślnie przekazano do grafika zlecenie",
  restore_project: "Pomyślnie przywrócono projekt",
  external_url_image_validate: "Aby przesłać pliki na serwer podaj lokalizację na serwerze",
  no_file: "Wybierz pliki jakie chcesz wgrać na serwer",
  file_send_success: "projekt",
  no_files_to_download: "Brak plików do pobrania",
  take_over_success: "Pomyślnie przypisano projekt",
};

const EXTENDED_INFO = {
  file_send_success:{
    first_text: "projekt",
    second_text: "przesłany do accounta",
  }
}

const WARNINGS = {
  VARIANT_DATA: "Nadchodząca zmiana wpłynie na pozostałe warianty, czy aby na pewno chcesz kontynuwoać?",
};

const ERROR_MESSAGE = {
  LOAD_DATA_ERROR: "Problem przy wczytywaniu danych, spróbuj ponownie później",
  CREATE_NEW_ORDER_ERROR: "Wystąpił problem przy tworzeniu nowego zamówienia, spróbuj ponownie",
  CREATE_NEW_ORDER_VARIANT_ERROR: "Wystąpił problem przy tworzeniu nowego wariantu, spróbuj ponownie",
  SAVE_DATA_ERROR: "Nie można wysłać zlecenia do realizacji, spróbuj ponownie",
  DRAFT_DATA_ERROR: "Nie można zapisać zlecenia jako szkic, spróbuj ponownie",
  REMOVE_DATA_ERROR: "Nie można usunąć zlecenia, spróbuj ponownie",
  SEND_FILE_ERROR: "Wystąpił błąd i nie można przesłąć pliku, spróbuj ponownie",
  ADD_COUNTER_ERROR: "Nie można dodać licznika, spróbuj ponownie",
  ADD_COMMENT_POPUP: "Nie można dodać uwagi do zamówienia, spróbuj ponownie",
  TAKE_OVER_ERROR: "Nie można przejąć projektu, spróbuj ponownie",
  CAMPAIGN_DROPDOWN_ERROR: "Nie można wczytać wszystkich kampani",
  PRODUCT_LIST_ERROR: "Nie można wczytać listy produktów",
  PRODUCT_DETAIL_ERROR: "Nie można wczytać danych produktu",
  FORMAT_LIST_ERROR: "Nie można wczytać rozmiarów",
  GET_IMAGE_ERROR: "Nie można wczytać zdjęć",
  CORRECTION_LIST_ERROR: "Nie można wczytać listy korekt",
  COMMENT_LIST_ERROR: "Nie można wczytać listy uwag",
  USER_LIST_ERROR: "Nie można wczytać dostępnych użytkowników",
  USER_ID_ERROR: "Nie można wczytać danych aktualnego użytkownika",
  SEND_DATA_ERROR: "Nie można wysłać danych",
  SETTINGS_DATA_ERROR: "Nie można wczytać ustawień",
  SENDING_IMAGE_ERROR: "Nie można wysłać zdjęcia, spróbuj ponownie",
  REMOVE_IMAGE_ERROR: "Nie udało się usunąć zdjęcia, spróbuj ponownie",
  FORWARD_TO_GRAPHIC_ERROR: "Nie udało się przepisać zlecenia do grafika, spróbuj ponownie",
  RESTORE_PROJECT_ERROR: "Nie można przywrócić projektu",
  IMAGE_UPDATE_ERROR: "Nie można zaktualizować zdjęcia, spróbuj ponownie",
  ACCEPT_BUTTON: "Nie można zaakceptować zlecenie, spróbuj ponownie"
};

export default {
  INFO,
  WARNINGS,
  EXTENDED_INFO,
  ERROR_MESSAGE
};
