import React, {useEffect, useState} from 'react';
import usePopup from "../../../helper/usePopup";
import neutralIcon from "../../../../assets/wprowadzania_korerkty.png";
import timer from "../../../../functions/timer";
import {ReactComponent as CorrectionIcon} from "../../../../assets/korekta.svg";
import {ReactComponent as ClockIcon} from "../../../../assets/clock.svg";

const ChangesErrorPopup = ({editInfo}) => {
    const {closePopup, clearTimeoutManually} = usePopup();
    const handleFirstButton = () => {
        closePopup();
    };
    const [timerValue, setTimerValue] = useState("00:00");

    useEffect(() => {
        if (editInfo) timer.displayCountDownTimer(editInfo.end_time, setTimerValue);
    }, []);
    return (
        <div className={`info-popup neutral`} onClick={e => e.stopPropagation()}>
            <div className={`popup-contents neutral`}>
                <div className="popup-upper-part">
                    <img className="icon-section" src={neutralIcon}></img>
                    <span className={`text-small neutral`} style={{marginBottom:'10px'}}>zlecenie w trakcie edycji</span>
                    <span className={`text-big neutral`}>Brak  możliwości <br/> wprowadzenia <br/> korekty</span>
                </div>
                {
                    editInfo &&
                    <div className={`order-edit-info`} style={{alignItems: 'flex-start', margin:'10px 0px 50px'}}>
                        <div className={`order-edit-info__data-row--person`}>
                            <CorrectionIcon width="10px" height="10px"/>
                            <span className="order-edit-info__text-content">korektę wprowadza: <strong>{editInfo.user_name}</strong></span>
                        </div>
                        <div className={`order-edit-info__data-row--time`}>
                            <ClockIcon width="10px" height="10px"/>
                            <span className="order-edit-info__text-content">estymowany czas wprowadzenia zmian: <strong>{timerValue}</strong></span>
                        </div>
                    </div>
                }
                <div className="popup-lower-part">
                    <div className="popup-buttons">
                        <button className={`popup-button only positive`} onClick={handleFirstButton}>Powrót</button>
                    </div>
                    <div className='text-last-container'>
                        <span className={`text-last positive`} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangesErrorPopup;
