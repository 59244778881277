import { handleResponse } from "../helper/handle-response.js";
import authHeader from "../helper/auth-header.js";
import getApiKey from "../helper/getApiKey.js";

const getWorkLog = async () => {
  const auth = authHeader();
  let api_key = getApiKey();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/worklog/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const startWorkLog = async (order_id) => {
  const auth = authHeader();
  let api_key = getApiKey();
  let body = JSON.stringify({ order: order_id });
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
    body,
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/worklog/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const stopWorkLog = async (order_id) => {
  const auth = authHeader();
  let api_key = getApiKey();
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/worklog/${order_id}/end`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getWorkLog,
  startWorkLog,
  stopWorkLog,
};
