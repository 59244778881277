import React from "react";
import noPhotoPlaceholder from "../../../../../../assets/no-photos.svg";
import checkPrefix from "../../../../../../functions/checkPrefix";

const TeamProductViewBox = ({data}) => {
  return (
    <div className="block" key={data.id} data-view={data.id}>
      <div className="action">
        <span className="letter">{data.id}</span>
      </div>
      <div className={data.img_src ? "photo" : "photo no-photo"}>
        <figure>
          <img
            src={data.img_src || noPhotoPlaceholder}
            data-view={data.id}
            style={{ transform: `rotate(${data.rotate}deg)`, width: data.display_width }}
          ></img>
        </figure>
      </div>
    </div>
  );
};


export default TeamProductViewBox