/**
 * checkPermissions() - checks if current user has permissions passed in permissionList
 *
 * example usage: AgentionNote.js, CollapseBanner.js, AcceptButton.js
 *
 * @param {Object} user - current user data
 * @param {Array} permissionList - list of permisions to check
 * @returns true/false
 */


const checkPermissions = (user, permissionList) => {
  let userPermissions = user.permissions;
  for (let i = 0; i < userPermissions.length; i++) {
    if (permissionList.length > 0) {
      for (let j = 0; j < permissionList.length; j++) {
        if (userPermissions[i] == permissionList[j]) {
          return true;
        }
      }
    } else if (userPermissions[i] == permissionList[0]) {
      return true;
    }
  }

  return false;
};

export default checkPermissions;
export const shouldDisplayButton = (buttonName, orderStatus, user) => {
  const isAdmin = user.permissions.includes('ecommerce_admin');
  const isClient = user.permissions.includes('ecommerce_orders_create');
  const buttonStatusMap = {
    'add_correction': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': 'Szary',
      'in_progress':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'ready_for_qa':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'accepted_acc': isAdmin ? 'Szary' : isClient ? 'Aktywny' : 'Brak',
      'correction':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'renew':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'created': isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'restored': 'Aktywny',
      'cancelled': 'Brak',
      'finished': isAdmin ? 'Brak' : isClient ? 'Aktywny' : 'Brak' ,
    },
    'accept': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'in_progress':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'ready_for_qa':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'accepted_acc': isAdmin ? 'Szary' : isClient ? 'Aktywny' : 'Brak',
      'correction':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'renew':  isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'created': isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'restored': isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'cancelled': 'Brak',
      'finished': 'Brak',
    },
    'edit': {
      'draft': 'Aktywny',
      'new': 'Aktywny',
      'accepted_for_realization': isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'in_progress': 'Brak' ,
      'ready_for_qa': 'Brak' ,
      'accepted_acc': 'Brak',
      'correction': 'Brak' ,
      'renew': 'Brak' ,
      'created': 'Brak' ,
      'restored': 'Brak' ,
      'cancelled': 'Brak',
      'finished': 'Brak',
    },
    'duplicate': {
      'draft': 'Aktywny',
      'new': 'Aktywny',
      'accepted_for_realization': 'Aktywny',
      'in_progress': 'Aktywny' ,
      'ready_for_qa': 'Aktywny' ,
      'accepted_acc': 'Aktywny',
      'correction':  'Aktywny' ,
      'renew':  'Aktywny' ,
      'created':'Aktywny' ,
      'restored': 'Aktywny',
      'cancelled': 'Szary',
      'finished': 'Aktywny' ,
    },
    'delete': {
      'draft': 'Aktywny',
      'new': 'Aktywny',
      'accepted_for_realization': isAdmin ? 'Aktywny' : isClient ? 'Szary' : 'Brak' ,
      'in_progress': 'Brak',
      'ready_for_qa':  'Brak',
      'accepted_acc':  'Brak',
      'correction':   'Brak',
      'renew':  'Brak',
      'created':  'Brak',
      'restored':  'Brak',
      'cancelled': 'Brak',
      'finished':  'Brak',
    },
    'observe': {
      'draft':   'Brak',
      'new':   'Brak',
      'accepted_for_realization': 'Aktywny',
      'in_progress': 'Aktywny',
      'ready_for_qa': 'Aktywny',
      'accepted_acc': 'Aktywny',
      'correction':  'Aktywny',
      'renew':   'Aktywny',
      'created': 'Aktywny',
      'restored':  'Aktywny',
      'cancelled': 'Brak',
      'finished': 'Brak',
    },
    'restore': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': 'Brak',
      'in_progress':  'Brak',
      'ready_for_qa': 'Brak',
      'accepted_acc':'Brak',
      'correction': 'Brak',
      'renew':  'Brak',
      'created': 'Brak',
      'restored':'Brak',
      'cancelled': isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'finished': 'Aktywny',
    },
    'add_counter': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': 'Brak',
      'in_progress':  'Brak',
      'ready_for_qa':'Brak',
      'accepted_acc':'Brak',
      'correction': 'Brak',
      'renew': 'Brak',
      'created': 'Brak',
      'restored':'Brak',
      'cancelled': 'Szary',
      'finished':'Aktywny',
    },
    'format': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': 'Brak',
      'in_progress':  'Brak',
      'ready_for_qa':'Brak',
      'accepted_acc':'Brak',
      'correction': 'Brak',
      'renew': 'Brak',
      'created': 'Brak',
      'restored':'Brak',
      'cancelled': 'Szary',
      'finished':'Aktywny',
    },
    'take_over': {
      'draft': 'Brak',
      'new':isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'accepted_for_realization': 'Brak',
      'in_progress':  'Brak',
      'ready_for_qa':  'Brak',
      'accepted_acc': 'Brak',
      'correction':  'Brak',
      'renew':  'Brak',
      'created': 'Brak',
      'restored':'Brak',
      'cancelled': 'Brak',
      'finished':'Brak',
    },
    'forward': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'in_progress':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'ready_for_qa': isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'accepted_acc': isAdmin ? 'Szary' : isClient ? 'Brak' : 'Brak' ,
      'correction':   isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'renew':   isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'created':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'restored':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'cancelled': 'Brak',
      'finished':'Brak'
    },
    'cancel': {
      'draft': 'Brak',
      'new': 'Brak',
      'accepted_for_realization': 'Brak',
      'in_progress':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'ready_for_qa':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'accepted_acc':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'correction':   isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'renew':   isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'created': isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'restored':  isAdmin ? 'Aktywny' : isClient ? 'Brak' : 'Brak' ,
      'cancelled': 'Brak',
      'finished': 'Brak',
    },
    'download': {
      'draft': 'Brak',
      'new':'Brak',
      'accepted_for_realization': 'Brak',
      'in_progress':  'Brak',
      'ready_for_qa':  'Brak',
      'accepted_acc': 'Brak',
      'correction':  'Brak',
      'renew':  'Brak',
      'created': 'Brak',
      'restored':'Brak',
      'cancelled': 'Szary',
      'finished':'Aktywny',
    },
  }
  // Pobieramy status przycisku dla podanego statusu zamówienia
  return buttonStatusMap[buttonName]?.[orderStatus];
  // Zwracamy true jeśli przycisk jest aktywny lub szary, false w innym przypadku
}
