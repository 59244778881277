import React, { useState, useRef, useContext, useEffect } from "react";
import { AddNewOrderContext } from "../../../../pages/e-commerce/AddNewOrder";
import { getBoxFieldContent } from "../../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import { choseDefaultNameLanguage } from "../../../../../functions/boxFieldsFunctions/choseDefaultNameLanguage";
import { DROPDOWN_TYPE } from "./ENUMS/DROPDOWN_ENUM";
import checkIfContentIncludeID from "../../../../../functions/boxFieldsFunctions/checkIfContentIncludeID";
import OrderBoxDropdownContainer from "./OrderBoxDropdownContainer";
import { ReactComponent as DropdownIcon } from "../../../../../assets/dropdownicon.svg";
const OrderBoxDropdown = ({ value, description, boxFieldID, actualVariant }) => {
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);

  const dropdownRef = useRef();

  const [dropdownSetting, setDropdownSetting] = useState(false);

  const handleDropdown = () => {
    setDropdownSetting(!dropdownSetting);
  };

  const handleCheckboxCheck = (id) => {
    let content = getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant);
    return checkIfContentIncludeID(id, content);
  };

  return (
    <>
      <div className={"content dropdown-box"}>
        <span>{description || ""}</span>
        {value.field_type == DROPDOWN_TYPE.DROPDOWN || value.field_type == DROPDOWN_TYPE.SINGLE_DROPDOWN ? (
          <>
            <div className="custom-input" onClick={handleDropdown} ref={dropdownRef}>
              {value.box_field_dictionary
                ? value.box_field_dictionary.map((el, index) => {
                    if (handleCheckboxCheck(el.id)) {
                      return (
                        <span key={`dropdown-element-${index}`}>
                          {boxSettings.language !== "PL" ? choseDefaultNameLanguage(el, boxSettings.language, "name") : el.default_name}
                        </span>
                      );
                    }
                  })
                : null}
              {value.field_type == DROPDOWN_TYPE.DROPDOWN && getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant)?.length == 0 &&(
                <span className="info" key={"last-1"}>
                  (kliknij, aby wybrać z listy)
                </span>
              )}

              {value.field_type == DROPDOWN_TYPE.SINGLE_DROPDOWN && getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant)?.length == 0 && <span className="info">(kliknij, aby wybrać z listy)</span>}
                <DropdownIcon className='arrow-down' onClick={handleDropdown} />
            </div>
          </>
        ) : (
          <>
            <div className="input-field">
              <input placeholder="wpisz..." readOnly="readonly" onClick={handleDropdown} ref={dropdownRef}></input>
            </div>
          </>
        )}
      </div>
      <OrderBoxDropdownContainer
        actualVariant={actualVariant}
        boxFieldID={boxFieldID}
        description={description}
        value={value}
        dropdownRef={dropdownRef}
        dropdownSetting={dropdownSetting}
        setDropdownSetting={setDropdownSetting}
      />
    </>
  );
};

export default OrderBoxDropdown;
