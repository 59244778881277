/**
 * getCurrentDate() - method that return current date in format DD-MM-YYYY
 * 
 * @returns current date
 */

const getCurrentDate = () => {
  let getDate = new Date();
  let getCurrYear = getDate.getFullYear();
  let getCurrMonth = getDate.getMonth();
  let getCurrDay = getDate.getDate();

  let month = getCurrMonth < 10 ? `0${getCurrMonth + 1}` : getCurrMonth + 1;
  let day = getCurrDay < 10 ? `0${getCurrDay}` : getCurrDay;

  let dateValue = `${day}-${month}-${getCurrYear}`;

  return dateValue;
};

export default getCurrentDate;
