import React, { useState, useRef } from "react";
import SectionHeader from "./sectionsElements/SectionHeader";
import RadioInput from "./sectionsElements/RadioInput";
import ViewBanner from "./sectionsElements/ViewBanner";

const SourceFile = ({ setChosenOrder, chosenOrder, setChosenSource, chosenSource, setSourceType, sourceType, validate, reloadComponent }) => {
  return (
    <div className={validate ? "source-file" : "source-file not-validated"}>
      <div className="row">
        <div className={`col-4`}>
          <SectionHeader headerType={"source_file"} />
          <div className={validate ? "inputs" : "inputs not-validated"}>
            <RadioInput
              reloadComponent={reloadComponent}
              setSourceType={setSourceType}
              setChosenSource={setChosenSource}
              chosenSource={chosenSource}
              setChosenOrder={setChosenOrder}
              chosenOrder={chosenOrder}
            />
          </div>
        </div>
        {sourceType == "order_id" && (
          <div className="col-8">
            <SectionHeader headerType={"view"} />
            <div className="view">
              <ViewBanner chosenSource={chosenSource.image} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SourceFile;
