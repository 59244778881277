export const TEAM_ELEMENTS = {
  SELECT_TEAM: "select_team",
  SELECT_TEAM_LIST: "select_team_list",
  TEAM_BG_SHADOW: "team_bg_shadow",

  SELECT_PERSON: "select_person",
  SELECT_PERSON_LIST: "select_person_list",
};

export const BOX = {
  MY_PROJECT: "my_project",
  MY_ORDER: "my_order",
  NEW_ORDER: "new_order",
};
