/**
 * trimString() - method thath trim string
 * 
 * example usage: CollapseRow.js, AssignedProjectBox.js
 * 
 * @param {String} text - text to trim
 * @param {Number} allowedLength - max allowed length to display
 * @returns trimed string
 */


const trimString = (text, allowedLength) => {
  if (text) return text.length > allowedLength ? text.substring(0, allowedLength) + "..." : text;

  return text;
};

export default trimString;
