import React, { useContext, useState } from "react";
import checkPermissions from "../../../../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import orderServices from "../../../../../services/orderServices";
import { ReloadTableContext } from "../../../../../Router";

import usePopup from "../../../../../helper/usePopup";
import InfoPopup from "../../../Popup/InfoPopup";
import ChangeDeadlinePopup from "../../../Popup/PopupComponents/ChangeDeadlinePopup";

const RestoreProjectButton = ({ orderID, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const { initPopup } = usePopup();

  const handleRestoreButton = (orderName, calenderDeadline, setActiveButtonLoader) => {
    setActiveButtonLoader(true);
    if (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN])) {
      orderServices
        .restoreProjectAcc(orderID, calenderDeadline)
        .then((res) => {
          if (res.ok) {
            setActiveButtonLoader(true);
            initPopup(<InfoPopup type="positive" info="restore" reload />);
          }
        })
        .catch((error) => {
          if (error) {
            setActiveButtonLoader(false);
            initPopup(<InfoPopup type="negative" info="something_wrong" />);
          }
        });
    } else if (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])) {
      orderServices
        .restoreProjectClient(orderID, calenderDeadline)
        .then((res) => {
          if (res.ok) {
            setReloadComponent(true);
            initPopup(<InfoPopup type="positive" info="restore" reload />);
          }
        })
        .catch((error) => {
          if (error) {
            setActiveButtonLoader(false);
            initPopup(<InfoPopup type="negative" info="something_wrong" />);
          }
        });
    }
  };

  return (
    <>
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.RESTORE_BUTTON) && rowData.status == 'finished' && (
        <>
          <button
            className={`restore`}
            onClick={() =>
              initPopup(
                <ChangeDeadlinePopup
                  contentType={"restore"}
                  handleAction={handleRestoreButton}
                />
              )
            }
          >
            Przywróć projekt
          </button>
        </>
      )}
    </>
  );
};

export default RestoreProjectButton;
