import React, {useContext, useEffect, useState} from 'react';
import usePopup from "../../../helper/usePopup";
import neutralIcon from "../../../../assets/plus.png";
import AddCounterCalenderDeadline from "./additionalElements/AddCounterCalenderDeadline";
import orderServices from "../../../services/orderServices";
import InfoPopup from "./InfoPopup";
import {useNavigate} from "react-router-dom";
import { ReactComponent as Action } from "../../../../assets/action_name.svg";
import { ReactComponent as Deadline } from "../../../../assets/deadline_icon.svg"
const CounterPopup = ({orderID, setReloadComponent}) => {
    const {closePopup, initPopup} = usePopup();
    const [actionName, setActionName] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [calenderDeadline, setCalenderDeadline] = useState("");
    const navigate = useNavigate();

    const handleActionName = (e) => {
        if (!error) setError("");
        setActionName(e.target.value);
    };
    const handleSaveButton = () => {
        if (actionName !== "" && actionName !== null && actionName !== undefined && calenderDeadline !== "" && calenderDeadline) {
            setLoading(true);
            orderServices
                .addCounter(orderID, actionName, calenderDeadline)
                .then((res) => {
                    closePopup();
                    initPopup(<InfoPopup type={'positive'} info={'order'} handleButton={handleNavigateButton}/>)
                    setReloadComponent(true);
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                    closePopup();
                    initPopup(<InfoPopup type={'negative'} info={'add_counter'}/>)
                });
        } else {
            setError("*Musisz wskazać nowy deadline");
        }
    };

    const handleNavigateButton = () => {
        navigate("/sendedprojects");
        window.location.reload();
    };

    const handleCancelButton = () => {
        setActionName("");
        closePopup()
    };

    return (
        <div className={`info-popup neutral`} onClick={e => e.stopPropagation()}>
            <div className={`popup-contents neutral`}>
                <div className="popup-upper-part">
                    <img className="icon-section" src={neutralIcon}></img>
                    <span className={`text-small neutral`}>DODAJ LICZNIK</span>
                    <span className={`text-big neutral`}>aby kontynuować <br/> wpisz nazwę akcji <br/> oraz jej deadline</span>
                </div>
                <div className="popup-center-part">
                    <div className='input-container' style={error ? { boxShadow: '0px 0px 10px #ce5a69'} : {boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.13)'}}>
                        <div className="icon-container">
                            <Action/>
                        </div>
                        <div className="input-group" style={{display: 'block', width: '100%'}}>
                            <input className={"input"} value={actionName}
                                onChange={handleActionName} placeholder={'wpisz nazwę akcji'}></input>
                        </div>
                    </div>
                    <div className='input-container' style={error ? { boxShadow: '0px 0px 10px #ce5a69'} : {boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.13)'}}>
                        <div className='icon-container'>
                            <Deadline/>
                        </div>
                        <div className="input-group" style={{display: 'block', width: '100%'}}>
                            <AddCounterCalenderDeadline setCalenderDeadline={setCalenderDeadline} deadline/>
                        </div>
                    </div>
                    {error && <span className="error">{error}</span>}
                </div>
                <div className="popup-lower-part">
                    <div className="popup-buttons">
                        <button className={`popup-button first neutral`} onClick={handleSaveButton}>{loading ?
                            <span className='loader-spinner'></span> : 'Utwórz zlecenie'}</button>
                        <button className={`popup-button second neutral`} onClick={handleCancelButton}>Anuluj</button>
                    </div>
                    <div className='text-last-container'>
                    <span className={`text-last neutral`}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterPopup;
