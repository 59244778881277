import React from "react";

const SelectAllElements = ({hasChildren, handleCheckeAllDataCheck, handleCheckAll, parent}) => {
  return (
    <>
      {hasChildren && (
        <li className={"select-all"} style={{ order: parent?.children?.length + 1 || "unset" }}>
          <label className="real-checkbox">
            <input
              checked={handleCheckeAllDataCheck(parent.id)}
              onChange={handleCheckAll}
              data-action={handleCheckeAllDataCheck(parent.id) ? "uncheckall" : "checkall"}
              type="checkbox"
              data-parent={parent.id}
            ></input>
            <span className="checkmark"></span>
            {handleCheckeAllDataCheck(parent.id) ? "odznacz wszystko" : "zaznacz wszystko"}
          </label>
        </li>
      )}
    </>
  );
};

export default SelectAllElements;
