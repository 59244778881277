import React from 'react';
import usePopup from "../../../helper/usePopup";

const GlobalPopup = () => {
    const { popup } = usePopup();

    return (
        <div
            className={`${popup.state === 'custom' ? 'bg-dark' : 'bg-blur'} ${popup.display ? 'active' : ''}`}>
            {popup.component}
        </div>
    );
};

export default GlobalPopup;
