import React, { useContext, useEffect } from "react";
import { MainAppContext } from "../../../../../MainAppComponent";
import { OrderderIdToRemove } from "../../../../../Router";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import checkPermissions, {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import { TableDataContext } from "../../../../../Router";
import TABLE_TYPES from "../../../../../../globalEnums/TABLE_TYPES_ENUMS";
import usePopup from "../../../../../helper/usePopup";
import Abort from "../../../Popup/AddOrder/Abort";
import RemoveOrderPopup from "../../../Popup/RemoveOrderPopup";
import DoNotProceed from "../../../Popup/AddOrder/DoNotProceed";
import CancelProceeding from "../../../Popup/AddOrder/CancelProceeding";

const RemoveButton = ({ orderID, rowData }) => {
  const { totalRemovePopup, setTotalRemovePopup } = useContext(MainAppContext);
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { removeOrderID, setRemoveOrderID } = useContext(OrderderIdToRemove);
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const handleRemoveBtn = () => {
    // initCustomBackgroundPopup(<RemoveOrderPopup orderID={orderID} removeOrderID={removeOrderID} setRemoveOrderID={setRemoveOrderID} status = {rowData.is_before_any_work ? "deleted" : "cancelled"}/>);
    if(rowData.order_status === "restored" ||
        rowData.order_status === "correction" ||
        rowData.order_status === "in_progress" ||
        rowData.order_status === "renew" ||
        rowData.order_status === "ready_for_qa" ||
        rowData.order_status === "accepted_acc"){
      initPopup(<CancelProceeding orderID={orderID} removeOrderID={removeOrderID} setRemoveOrderID={setRemoveOrderID} status={rowData.is_before_any_work ? "deleted" : "cancelled"}/>)
    }else{
      initPopup(<Abort orderID={orderID} removeOrderID={removeOrderID} setRemoveOrderID={setRemoveOrderID} status={rowData.is_before_any_work ? "deleted" : "cancelled"}/>)
    }
  };
  const checkIfDisplayButton = () =>{
    let tableType = tableContext.tableType;
    if(tableType == TABLE_TYPES.NEW && checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN, PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])){
      return true;
    }else if(tableType == TABLE_TYPES.IN_PROGRESS && checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN, PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])){
      if((rowData.order_status === 'renew'
          || rowData.order_status === 'correction'
          || rowData.order_status === 'ready_for_qa'
          || rowData.order_status === 'accepted_acc'
          || rowData.order_status === 'in_progress'
          || rowData.order_status === 'restored'
      ) && user.permissions.includes(PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE)){
        return false;
      }else{
        return true;
      }
    }else if(tableType == TABLE_TYPES.FINISHED && checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN])){
      return false;
    }else if(tableType == TABLE_TYPES.DRAFT && checkPermissions(user,
        [
            PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
            PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE,
        ])){
      return true;
    }else return tableType === null;
  }

  const checkIfButtonIsActive = () =>{
    if(checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN])){
      return ["cancelled"].includes(rowData.order_status)
    }else if(checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])){
      return ["cancelled", "accepted_for_realization"].includes(rowData.order_status)
    }

    return false
  }

  return (
    <>
      {shouldDisplayButton((rowData.order_status === "restored" ||
      rowData.order_status === "correction" ||
      rowData.order_status === "in_progress" ||
      rowData.order_status === "renew" ||
      rowData.order_status === "ready_for_qa" ||
      rowData.order_status === "accepted_acc"
          ? 'cancel' : 'delete'), rowData?.order_status,user,) !== 'Brak' && (
        <button
          className={`remove ${shouldDisplayButton((rowData.order_status === "restored" ||
          rowData.order_status === "correction" ||
          rowData.order_status === "in_progress" ||
          rowData.order_status === "renew" ||
          rowData.order_status === "ready_for_qa" ||
          rowData.order_status === "accepted_acc"
              ? 'cancel' : 'delete'), rowData?.order_status,user,) === 'Szary' ? 'casual' : null} `}
          onClick={handleRemoveBtn}
        >
          {rowData.order_status === "restored" ||
          rowData.order_status === "correction" ||
          rowData.order_status === "in_progress" ||
          rowData.order_status === "renew" ||
          rowData.order_status === "ready_for_qa" ||
          rowData.order_status === "accepted_acc"
            ? "Przerwij"
            : "Usuń"}
        </button>
      )}
    </>
  );
};

export default RemoveButton;
