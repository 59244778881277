import React from 'react';
import {ReactComponent as Delete}  from "../../../../../assets/popups/delete.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import imageServices from "../../../../services/imageServices";
const ReformatDelete = ({handleDelete, editOrder}) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    const handleFirstButton = () => {
        if(!editOrder) handleDelete();
        navigate("/");
        closeOrderPopup();
    }
    const handleSecondButton = () => {
        closeOrderPopup();
    }
    return (
        <div className='abort-popup'>
            <Delete />
            {editOrder ? <h3>Przerwij wprowadznie<br/>zmian</h3> : <h3>Czy na pewno chcesz usunąć <br/> rozpoczęte zlecenie?</h3>}
            <p>Tej operacji nie można cofnąć.</p>
            <div className='buttons'>
                <button className="button cancel" onClick={handleSecondButton}>Anuluj</button>
                <button className="button abort" onClick={handleFirstButton}>{editOrder ? "Przerwij" : "Usuń"}</button>
            </div>
        </div>
    );
}

export default ReformatDelete;
