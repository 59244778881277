import React, { useEffect, useState, useContext } from "react";
import LogoTypeChooserPopup from "../Popup/LogoTypeChooserPopup";
import usePopup from "../../../helper/usePopup";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateGLobalBoxFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getActualBoxFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import TextEditor from "./TextEditor";

const LogoTypeChooser = ({ actualVariant, boxFieldID }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);

  const { initPopup } = usePopup();

  const [buttonChooserData, setButtonChooserData] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState("");

  useEffect(() => {
    //initial data
    let actualBoxFieldState = getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant)

    if (actualBoxFieldState?.value?.length > 0) {
      setButtonChooserData(actualBoxFieldState.value);
    } else {
      setButtonChooserData([
        { type: "A", id: null, url: "", name: "" },
        { type: "B", id: null, url: "", name: "" },
        { type: "C", id: null, url: "", name: "" },
        { type: "D", id: null, url: "", name: "" },
        { type: "E", id: null, url: "", name: "" },
      ]);
    }

    if(actualBoxFieldState?.custom_inputs_value) setAdditionalInfo(actualBoxFieldState.custom_inputs_value)
    
  }, []);

  useEffect(() => {
    //save new data
    setBoxFieldContent();
  }, [additionalInfo, buttonChooserData]);

  const setBoxFieldContent = () => {
    let initialValue = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      value: buttonChooserData, custom_inputs_value: additionalInfo,
    };
    updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  const saveData = ({type, id, url, name}) => {
    setButtonChooserData((prevState) =>
      prevState.map((value) => {
        if (value.type == type) {
          return { ...value, id: parseInt(id), url: url, name: name };
        } else {
          return value;
        }
      })
    );
  };

  const removeData = (chooserType) => {
    setButtonChooserData((prevState) =>
      prevState.map((value) => {
        if (value.type == chooserType) {
          return { ...value, id: null, url: "", name: "" };
        } else {
          return value;
        }
      })
    );
  };

  const handleChooserButton = (chooserType) => {
    let initialData = buttonChooserData.find((el) => el.type == chooserType);
    initPopup(<LogoTypeChooserPopup saveData={saveData} removeData={removeData} initialData={initialData} />);
  };

  const checkIfChooserIsFull = (chooserType) => {
    let chooserValue = buttonChooserData.find((el) => el.type == chooserType);

    return chooserValue.name ? true : false;
  };

  const handleAdditionalInfo = (content) => {
    setAdditionalInfo(content);
  };

  return (
    <div className="logo-chooser">
      <div className="chooser-wrapper">
        {buttonChooserData.map((button, index) => {
          return (
            <div className="column" key={`button-${index}`}>
              <button className={`chooser ${checkIfChooserIsFull(button.type) ? "chosen" : null}`} onClick={() => handleChooserButton(button.type)}>
                {button.type}
              </button>
              <div className="view">
                {button.url && (
                  <figure>
                    <img src={button.url}></img>
                  </figure>
                )}
              </div>
              <div className="view-controler">
                <div className="icon refresh" onClick={() => handleChooserButton(button.type)}></div>
                <div className="icon remove" onClick={(e) => removeData(button.type)}></div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={`text-wrapper`}>
        <span>dodatkowe informacje, np. copy pod logotypami “i wiele więcej...”</span>
        <TextEditor
          saveData={handleAdditionalInfo}
          initialValue={additionalInfo}
          placeholder={`kliknij, aby wprowadzić dodatkowe informacje...`}
          style = {{
            width: '100%',
            height: '38px',
            background: '#f4f4f4',
            borderRadius: '5px',
            padding : '10px 5px',
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: `12px`
          }}
        />
      </div>
    </div>
  );
};

export default LogoTypeChooser;
