import React, { useState, useRef, useContext, useEffect } from "react";
import { MainAppContext } from "../../../MainAppComponent";
import { ProductViewContext, AddProductPopupContext } from "../OrderBox/OrderBoxContent";
import BackgroundShadow from "../../globalElements/BackgroundShadow";
import productsServices from "../../../services/productsServices";
import noPhotoPlaceholder from "../../../../assets/no-photos.svg";
import { getFileBaseName } from "../../../../functions/getFileBaseName";
import img from "../../../../assets/product-modal.svg";
import Dropdown from "../../globalElements/Dropdown/Dropdown";
import usePopup from "../../../helper/usePopup";
import InfoPopup from "./InfoPopup";
const AddProductPopup = () => {
  const [indexValue, setIndexValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(false);
  const [productCodes, setProductCodes] = useState([]);
  const [viewImage, setViewImage] = useState(null);
  const [allowLoad, setAllowLoad] = useState(false);
  const [imageArr, setImageArr] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [emptyPhotoList, setEmptyPhotoList] = useState(false);
  const [productsData, setProductsData] = useState({
    external_id: null,
    ean: null,
    plu: null,
    name: null,
    quantity: 1,
    weight: null,
    width: null,
    height: null,
  });
  const [defaultButtonSettings, setDefaultButtonSettings] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const { initPopup } = usePopup();
  const popupRef = useRef();
  const buttonRef = useRef(new Array());
  const eanInputRef = useRef();
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { showPopup, setShowPopup } = useContext(AddProductPopupContext);

  const getProductsList = (ean) => {
    setTimeout(() => {
      if (ean) {
        productsServices
          .getProductsList(ean)
          .then((res) => {
            let data = res.data;
            let eanArr = data.map((el) => {
              return { value: el.ean, displayName: el.ean };
            });

            setProductCodes(eanArr);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<InfoPopup type={'negative'} info={'product_list'}/>);
          });
      }
    }, 200);
  };

  useEffect(() => {
    if (allowLoad) {
      const delayDebounceFn = setTimeout(() => {
        handleAddBtn(indexValue);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [indexValue]);

  useEffect(() => {
    productViewData.forEach((el) => {
      if (el.id === showPopup.idValue) {
        productsServices
          .getProducts(el.ean)
          .then((res) => {
            let data = res.data[0];
            if (data) {
              let images = data.photo_ids;
              getImagesArr(images);
              if (images.length == 0) setEmptyPhotoList(true);
            }
          })
          .catch((error) => {
            console.error(error);
            initPopup(<InfoPopup type={'negative'} info={'product_list'}/>)
          });

        setDefaultButtonSettings(false);
        if(el.ean){
          setIsEdit(true);
        }
        setIndexValue(el.ean);
      }
    });
  }, []);

  useEffect(() => {
    if (imageArr.length > 0) setLoadingImage(false);
  }, [imageArr]);

  const handleInput = (e) => {
    if (e.target.value !== "") {
      setShowDropdown(true);
    } else {
      setShowDropdown(false)
    }

    if (allowLoad == false) {
      setAllowLoad(true);
    }

    setIndexValue(e.target.value);
    getProductsList(e.target.value);
  };

  const getImagesArr = (images) => {
    let imagesList = [];
    images.forEach((id) => {
      let hostName = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_APP4AD_URL : 'https://' + window.location.hostname;

      productsServices.getProductImage(id).then((photoRes) => {
        setImageArr((prevState) => {
          return [...prevState, { image_id: id, image_url: hostName + photoRes.data.url }];
        });
      });
    });
  };

  const handleAddBtn = (value) => {
    if (value !== "" && value !== null) {
      setLoadingImage(true);
      setError(false);
      setEmptyPhotoList(false);
      setImageArr([]);
      productsServices
        .getProducts(value)
        .then((res) => {
          let data = res.data[0];
          let images = data.photo_ids || null;

          setProductsData({
            ...productsData,
            image_external_id: data.photo_ids[0],
            external_id: data.id,
            ean: data.ean,
            plu: data.plu,
            name: data.name,
            quantity: data.quantity,
            weight: data.weight,
          });

          if (images.length > 0) {
            getImagesArr(images);
            setEmptyPhotoList(false);
          } else {
            setImageArr([]);
            setEmptyPhotoList(true);
            setViewImage(noPhotoPlaceholder);
          }

          setLoadingImage(false);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<InfoPopup type={'negative'} info={'product_list'}/>);
        });
    } else {
    }
  };

  const handleSaveBtn = () => {
    if(!!!viewImage){
      setError(true)
      return
    }
    let newProductViewData,
      canChange = true;
    let idValue = popupRef.current.dataset.idValue;
    if (idValue !== "undefined") {
      newProductViewData = productViewData.map((el) => {
        if (el.id == idValue) {
          let image = getFileBaseName(viewImage).includes("no-photos") ? null : viewImage;
          return { ...el, ean: indexValue, img_src: image, ...productsData };
        }
        return el;
      });
    } else {
      newProductViewData = productViewData.map((el) => {
        if (el.img_src === null && canChange) {
          let image = getFileBaseName(viewImage).includes("no-photos") ? null : viewImage;
          canChange = false;
          return { ...el, ean: indexValue, img_src: image, ...productsData };
        }
        return el;
      });
    }
    setProductViewData(newProductViewData);
    setShowPopup(false);
    setShadowActive(false);
  };

  const handleRemoveBtn = () => {
    let idValue = popupRef.current.dataset.idValue,
      newProductViewData;

    setIndexValue("");
    setViewImage(null);
    setImageArr([]);

    newProductViewData = productViewData.map((el) => {
      if (el.id == idValue) {
        return { ...el, ean: null, img_src: null, scale: 1, rotate: 0 };
      }
      return el;
    });

    setProductViewData(newProductViewData);
  };

  const handleImageChoice = (e) => {
    let imageID = e.target.dataset.photoId;
    let chosenURL = null;

    for (let i = 0; i < imageArr.length; i++) {
      if (imageArr[i].image_id == imageID) {
        chosenURL = imageArr[i].image_url;
        break;
      }
    }

    productsServices
      .getProductProperty(imageID)
      .then((res) => {
        setProductsData({
          ...productsData,
          width: res.width,
          height: res.height,
        });
      })
      .catch((error) => {
        console.error(error);
        initPopup(<InfoPopup type={'negative'} info={'product_list'}/>)
      });

    if (chosenURL) setViewImage(chosenURL);
  };

  const handleEadnCode = (e) => {
    setShowDropdown(false)
    setIndexValue(e.target.value);
  };

  return (
    <>
      <div className="outer-add-product-popup">
      <div className="add-product-popup" ref={popupRef} data-id-value={showPopup.idValue}>
        <div className="title-section">
          <figure>
            <img src={img}></img>
          </figure>
          <h3>
            DODAJ<span>produkt</span>
          </h3>
          <div className={`green-bar`}>
            <span>dodaj / wyszukaj produkt po nr. ean</span>
          </div>
        </div>
        <div className="fields">
          <div className={"index"}>
            <h2>Numer ean/index</h2>
            <input placeholder="wpisz/wybierz po nr.EAN/INDEX" value={indexValue ?? ""} onChange={handleInput} ref={eanInputRef}></input>
            {showDropdown && (
              <Dropdown data={productCodes} handleListElement={handleEadnCode} displayCheckbox={false} positionToElementRef={eanInputRef} setShowDropdown={setShowDropdown} showDropdown={showDropdown}/>
            )}
          </div>
          <div className="view">
            <h2 style={indexValue ? {color : "rgba(0, 0, 0, 1)"} : {color : "rgba(0, 0, 0, 0.3)"}}>podgląd</h2>
            <div className={error ? "show-field error" : "show-field"}>
              {loadingImage ? <span>loading...</span> : null}
              {emptyPhotoList ? (
                <figure className="no-photo">
                  <img src={noPhotoPlaceholder}></img>
                </figure>
              ) : null}
              {imageArr?.length > 0
                ? imageArr.map((el, index) => {
                    return (
                      <label key={`image-${index}`}>
                        <input type="checkbox" data-photo-id={el.image_id} onClick={handleImageChoice}></input>
                        <span className="checkmark"></span>
                        {index + 1}
                        <figure className="input-banner">
                          <img src={el.image_url}></img>
                        </figure>
                      </label>
                    );
                  })
                : null}
            </div>
          </div>
          {error ? <span className="error">*Aby dodać wybierz produkt z listy</span> : <span></span>}
          <div className="bottom">

            {indexValue && <button ref={(el) => buttonRef.current.push(el)} className={"save view-button-active"}
                                   onClick={handleSaveBtn}>
              {isEdit ? "Zamień" : "Dodaj"}
            </button>}
            <button ref={(el) => buttonRef.current.push(el)} className={"cancel view-button-active"}
                    onClick={() => setShowPopup(false)}>
              Anuluj
            </button>
            {isEdit && <button ref={(el) => buttonRef.current.push(el)} className={"remove view-button-active"}
                    onClick={handleRemoveBtn}>
              Usuń
            </button>}
          </div>
        </div>
      </div>
      </div>
      <BackgroundShadow handleShadow={false}/>
    </>
  );
};

export default AddProductPopup;
