import React, { useContext, useRef } from "react";
import { TableDataContext } from "../../../../Router";

import TABLE_TYPES from "../../../../../globalEnums/TABLE_TYPES_ENUMS";
import notificationServices from "../../../../services/notificationServices";
import notificationBell from "../../../../../cetaMethods/notificationBell";
import { ReloadTableContext } from "../../../../Router";
import { UserContext } from "../../../../wrapper/AuthWrap";
import generateTableElementsClass from "../collapseElements/helper/generateTableElementsClass";

const TableHeaders = ({ orderBy, setOrderBy }) => {
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const { user, setUser } = useContext(UserContext);
  const tableHeaderRef = useRef([]);
  const sortButtonRef = useRef();

  const handleOrderByList = (value) => {
    let orderByListCopy = [...orderBy];

    for (let i = 0; i < orderByListCopy.length; i++) {
      if (orderByListCopy[i]?.includes(value)) {
        orderByListCopy.splice(i, 1);
        break;
      }
    }

    return orderByListCopy;
  };

  const sortTable = (e, header) => {
    let order = e.currentTarget.dataset.order;
    let button = e.currentTarget.querySelector(".sort-btn");

    let orderByListCopy = [];

    if(header.box_field){
      orderByListCopy = handleOrderByList(header.box_field);
    }else{
      orderByListCopy = handleOrderByList(header.defined_field);
    }

    if (header.is_sortable) {
      if (order === "desc") {
        e.currentTarget.dataset.order = "asc";
        button.classList.remove("desc");
        button.classList.add("asc");
        if(header.box_field){
          setOrderBy([...orderByListCopy, `${header.box_field}`]);
        }else{
          setOrderBy([...orderByListCopy, header.defined_field]);
        }

      }else if(order === "asc") {
        e.currentTarget.dataset.order = "";
        button.classList.remove("asc");
        setOrderBy([...orderByListCopy]);
      } else {
        e.currentTarget.dataset.order = "desc";
        button.classList.remove("asc");
        button.classList.add("desc");
        if(header.box_field){
          setOrderBy([...orderByListCopy, `-${header.box_field}`]);
        }else{
          setOrderBy([...orderByListCopy, `-${header.defined_field}`]);
        }

      }
    }
  };

  const handleMarkAllAsRead = () => {
    notificationServices.markAllAsRead().then((res) => {
      setReloadComponent(true);
    });

    notificationBell.getNotificationNumber();
  };

  return (
    <div className={generateTableElementsClass("thead ", tableContext.tableType, user)}>
      {tableContext.tableConfiguration.map((el, index) => {
        return (
          <div
            className="action desc"
            ref={(el) => {
              tableHeaderRef.current.push(el);
            }}
            key={`sort-col-key-${index}`}
            onClick={(event) => sortTable(event, el)}
            data-col={el.defined_field}
            data-order="desc"
            style={{ order: el.position, width: `${el.width}%` }}
          >
            {el.column_title} {el.is_sortable && <button className="sort-btn" ref={sortButtonRef}></button>}
            {tableContext.tableType == TABLE_TYPES.NOTIFICATION && index == tableContext.tableConfiguration.length - 1 && (
              <div className="mark-all-as-read" title="zaznacz wszystko jako przeczytane" onClick={handleMarkAllAsRead}></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TableHeaders);
