import { handleResponse } from "../helper/handle-response.js";
import authHeader from "../helper/auth-header.js";
import FILTER_BAR_TYPES from "../elements/globalElements/Filters/ENUMS/FILTER_BAR_TYPE.js";

const getFilterSettings = async () => {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/search-filters`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getDataByFilter = async (filterEndpoint) => {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/${filterEndpoint}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
}

export default {
  getFilterSettings,
  getDataByFilter
};