import React from "react";
import PageComponent from "../Team/globalelements/PageComponent";
import Loader from "../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../globalElements/Loader/LOADER_TYPE";
import ProductShow from "../../globalElements/Products/ProductsShow";

const CompareColumn = ({ icon, title, subtitle, dataList, page, setPage, variantList, loadingData, columnType, showNoDataInfo }) => {
  return (
    <div className="col">
      <div className="header">
        <div className="title-section">
          <div className="logo">
            <figure>
              <img src={icon}></img>
            </figure>
          </div>
          <h3 className="title">{title}</h3>
          <span className="subtitle"> — {subtitle}</span>
        </div>
        {variantList?.length > 0 && <PageComponent page={page} setPage={setPage} data={variantList} showContent={true} />}
      </div>
      <ul className="list">
        {loadingData ? (
          <Loader type={LOADER_TYPE.LOAD_DATA} width={25} height={25} position={`absolute`} startAnimation={true} />
        ) : (
          <>
            {dataList.length ? (
              dataList.map((data, index) => {
                return (
                  <li className={`list-element ${columnType} ${data.is_changed ? "dif" : ""}`} key={`col-element-${index}`}>
                    <span className={`field-name`}>{data.name}</span>
                    {typeof data.value == "object" ? (
                      <span className="field-value product">
                        <ProductShow productsVisualisation={data.value}/>
                      </span>
                    ) : (
                      <span className="field-value" dangerouslySetInnerHTML={{ __html: data.value }}></span>
                    )}
                  </li>
                );
              })
            ) : showNoDataInfo ? <span>brak danych odnośnie korekt dla tego wariantu</span> : null }
          </>
        )}
      </ul>
    </div>
  );
};

export default CompareColumn;
