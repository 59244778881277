/**
 * getCurrentHour() - method that return current hour
 * 
 * @returns current hour
 */

const getCurrentHour = () => {
  let date = new Date();
  let time = date.getHours();

  return time;
};

export default getCurrentHour;
