import React, { useEffect, useState } from "react";
import Slider from "../../../Table/Slider";
import TeamProductViewBox from "./TeamProductViewBox";

const TeamProductView = ({ productsData }) => {
  const [productSlideList, setProductsSlideList] = useState(null);

  useEffect(() => {
    let productsList = productsData.products.map((data, index) => <TeamProductViewBox data={data} key={`product-slide-${index}`} />);

    setProductsSlideList(productsList);
  }, [productsData]);

  return (
    <div className="products team-view">
      <div className="products-wrapper team-view">
        <Slider
          spaceBetween={10}
          slidesPerView={4}
          slides={productSlideList}
          hasCustomNavigation={true}
          breakpoints={{2450: {slidesPerView: 4}, 1600:{slidesPerView: 3} ,772: {slidesPerView: 1.5}, 320: { slidesPerView: 1 }}}
        />
      </div>
    </div>
  );
};

export default TeamProductView;
