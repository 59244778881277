/**
 * checkPermissions() - checks if current user has permissions passed in permissionList
 *
 * example usage: AgentionNote.js, CollapseBanner.js, AcceptButton.js
 *
 * @param {Object} user - current user data
 * @param {Array} permissionList - list of permisions to check
 * @returns true/false
 */


const checkPermissions = (user, permissionList) => {
  let userPermissions = user.permissions;
  for (let i = 0; i < userPermissions.length; i++) {
    if (permissionList.length > 0) {
      for (let j = 0; j < permissionList.length; j++) {
        if (userPermissions[i] == permissionList[j]) {
          return true;
        }
      }
    } else if (userPermissions[i] == permissionList[0]) {
      return true;
    }
  }

  return false;
};

export default checkPermissions;
