import React, { useState, useRef, useContext } from "react";
import orderServices from "../../../../../services/orderServices";
import { ReloadTableContext } from "../../../../../Router";

import Loader from "../../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../../globalElements/Loader/LOADER_TYPE";
import usePopup from "../../../../../helper/usePopup";
import InfoPopup from "../../../Popup/InfoPopup";
import { ReactComponent as Observe} from "../../../../../../assets/observe.svg";
import { ReactComponent as StopObserve} from "../../../../../../assets/dont_observe.svg";


const ObserveButton = ({ orderID, navigate, rowData }) => {
  const [showBoxInfo, setShowBoxInfo] = useState(false);
  const buttonRef = useRef();
  const [showErrorPopup, setErrorPopup] = useState(false);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const { initPopup } = usePopup();
  const handleObserve = (status) => {
    let observe = rowData.is_observed;

    setActiveButtonLoader(true);
    orderServices
      .observeOrder(orderID, observe)
      .then((res) => {
        setShowBoxInfo(true);
        initPopup(<InfoPopup type={'positive'} info={rowData.is_observed ? "stop_observing" : "start_observing"} customIcon={rowData.is_observed ? <StopObserve /> : <Observe /> } setReloadComponent={setReloadComponent}/>)
        setActiveButtonLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setErrorPopup(true);
        setActiveButtonLoader(false);
      });
  };

  return (
    <>
      <>
        <button className={rowData.is_observed ? `stop-observe` : `observe`} onClick={handleObserve} ref={buttonRef}>
          {rowData.is_observed ? <StopObserve style={{marginRight:8}}/> : <Observe  style={{marginRight:8}}/>}
          {activeButtonLoader ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} /> : rowData.is_observed ? "Przestań obserwować" : "Obserwuj"}
        </button>
      </>
    </>
  );
};

export default ObserveButton;
