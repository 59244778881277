import React, { useEffect, useState } from "react";
import correctionServices from "../../../../services/correctionServices";
import formatDate from "../../../../../functions/formatDate";
import InfoPopup from "../../Popup/InfoPopup";
import usePopup from "../../../../helper/usePopup";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const CorrectionList = ({ variantID, orderID, data, rowData, paginationPage, page }) => {
  const [correctionList, setCorrectionList] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();

  const { initPopup } = usePopup();

  useEffect(() => {
    if (data && variantID) {
      correctionServices
        .getCorrectionsList(variantID)
        .then((response) => {
          let correctionArr = [];

          response.sort((obj1, obj2) => {
            const date1 = new Date(obj1.created_at);
            const date2 = new Date(obj2.created_at);

            return date2 - date1;
          });


          response.forEach((correction) => {
            let fullData = formatDate(correction.modified_at);
            correctionArr.push({ date: fullData, pk: correction.pk });
          });

          setCorrectionList(correctionArr);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<InfoPopup type={'negative'} info={'correction_list'}/>)
        });
    }
  }, [data, variantID]);

  const getDetails = (e) => {
    let elementDataset = e.target.dataset

    const searchParams = new URLSearchParams({
      order: orderID,
      variant_id: variantID,
      correction_pk: elementDataset.pk,
      page: page,
      pagination: paginationPage || 1,
      prevPage: location.pathname.replace('/', '')
    }).toString();

    navigate(`/compare-variants?${searchParams}`, {state: {prevPage: location.pathname}})
  };

  return (
    <>
      {correctionList && correctionList.length > 0 ? (
        <div className="row correction-list-wrapper">
          <span className="title">Korekta</span>
          <ul className="correction-list">
            {correctionList
              ? correctionList.map((el, index) => {
                  return (
                    <li key={`list-el-key-${index}`}>
                      <div data-pk={el.pk} data-index={index} onClick={getDetails}>
                        <strong>{index + 1}.</strong> {el.date}
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default CorrectionList;
