import React, { useState, useContext, useEffect } from "react";
import { ActuadVariatnContext, AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateGLobalBoxFieldsData, updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getUniqueFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { getHeadersFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";

const ActionName = ({ blockEdit, boxFieldID, actualVariant }) => {
  const { headerBoxesData, setHeaderBoxesData } = useContext(AddNewOrderContext);
  const [localVariant, setLocalVariant] = useState(null);
  const [renderOnce, setRenderOnce] = useState(true);

  const handleInput = (e) => {
    let copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, actualVariant), value: { content: e.target.value } };
    updateUniqueFieldsData(copyInputData, headerBoxesData, setHeaderBoxesData, boxFieldID, actualVariant);
  };

  useEffect(() => {
    if (renderOnce && actualVariant) {
      setLocalVariant(actualVariant);
    }
  }, [actualVariant]);

  return (
    <>
      <input className={blockEdit ? "blocked" : "active"} placeholder="wpisz..." onChange={handleInput} value={getHeadersFieldContent(headerBoxesData, boxFieldID, actualVariant) ?? ""}></input>
    </>
  );
};

export default ActionName;
