import getCurrentDate from "../getCurrentDate";
import getCurrentHour from "../getCurrentHour";

let timeOut;

/**
 * handleCalenderInputs() - method that handle display time format in calender inputs,
 * that method handle 24h format, set time and disallow to chose time older than current
 * 
 * @param {*} element - handle node element
 * @param {Integer} value - passed value
 * @param {*} setChosenDateError - state to set errors
 * @param {*} setHoure - state that set chosen houre
 * @param {*} setMinute - state that set chosen minute
 * @param {*} chosenDate - chosen date
 * @param {*} minuteRef - reference to node element that get minutes values
 * @param {*} hourRef - reference to node element that get hour values
 */

const handleCalenderInputs = (element, value, setChosenDateError, setHoure, setMinute, chosenDate, minuteRef, hourRef) => {
  let currentDate = getCurrentDate();
  let currentTime = getCurrentHour();

  if (element.classList.contains("hour")) {
    if (value > 23) value = 23;
    if (value < 0) value = 0;

    setHoure(value);
    clearTimeout(timeOut);
    if (currentDate == chosenDate && value !== "") {
      setChosenDateError(true);
      timeOut = setTimeout(() => {
        if (value < currentTime) {
          setHoure(currentTime + 1);
        }
        clearTimeout(timeOut);
      }, 300);
    }

    if (value.length >= 2) minuteRef.current.focus();
  } else if (element.classList.contains("minute")) {
    if (value > 59) value = 59;
    if (value < 0) value = 0;
    setMinute(value);
    if (value.length == 0) hourRef.current.focus();
  }
};

export default handleCalenderInputs;
