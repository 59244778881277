import React, { useEffect, useState, useContext } from "react";
import { ReformatContext } from "../../../../pages/e-commerce/Reformatting";
import reformatServices from "../../../../services/reformatServices";
import { useSearchParams } from "react-router-dom";
import Popup from "../../Popup/Popup";
import POPUP_TYPES from "../../Popup/ENUMS/POPUP_TYPES";
import BOX_INFO_ENUMS from "../../Popup/ENUMS/BOX_INFO_ENUMS";
import usePopup from "../../../../helper/usePopup";
import InfoPopup from "../../Popup/InfoPopup";

const FormatList = () => {
  const [formats, setFormats] = useState([]);
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [renderOnce, setRenderOnce] = useState(true);
  const { initPopup } = usePopup();
  let sizeGroupHelper = '';

  const checkIfStrcturesIncludes = (basicSizeList, element) => {
    for (let i = 0; i < basicSizeList.length; i++) {
      if (basicSizeList[i].group == element.group) return true;
    }
    return false;
  };

  const generateBasicSizeListDataStructure = (data, initsizes) => {
    let basicSizeList = [];
    let groupHelper = '';

    data.sort((a, b) => a.size_group - b.size_group);

    data.forEach((el) => {
      if (el.size_group_name !== groupHelper) {
        groupHelper = el.size_group_name;
        let obj = { group: el.size_group, group_name: el.size_group_name, chosen_sizes: [] };
        basicSizeList.push(obj);
      }
    });

    if (initsizes) {
      setReformatData({ ...reformatData, basic_sizes_list: basicSizeList });
    } else {
      let basicSizeListCopy = [...reformatData.basic_sizes_list];
      let isSizeListChanged = false;

      basicSizeList.forEach((el) => {
        if (!checkIfStrcturesIncludes(basicSizeListCopy, el)) {
          basicSizeListCopy.push(el);
          if (!isSizeListChanged) isSizeListChanged = true;
        }
      });

      if (isSizeListChanged) setReformatData({ ...reformatData, basic_sizes_list: basicSizeListCopy });
    }
  };

  const getSizes = (initsizes) => {
    reformatServices
        .getClientSizes()
        .then((res) => {
          generateBasicSizeListDataStructure(res, initsizes);
          setFormats(res);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<InfoPopup type={'negative'} info={'format_list'} />);
        });
  };

  useEffect(() => {
    if (!searchParams.get('order')) {
      getSizes(true);
    }
  }, []);

  useEffect(() => {
    if (renderOnce) {
      let initsizes = false;
      if (reformatData.basic_sizes_list == null) {
        initsizes = true;
      }
      getSizes(initsizes);
      setRenderOnce(false);
    }
  }, [reformatData]);

  const handleInputs = (e) => {
    let value = e.target.value;
    let groupID = e.target.dataset.groupId;

    if (reformatData.basic_sizes_list) {
      if (checkIfChosen(value, groupID)) {
        handleChosenSizes('remove', value, groupID);
      } else {
        handleChosenSizes('append', value, groupID);
      }
    }
  };

  const handleGroupCheck = (sizeGroup, checked) => {
    const checkboxes = document.querySelectorAll(`input[data-group-id="${sizeGroup}"]`);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
      handleChosenSizes(checked ? 'append' : 'remove', checkbox.value, sizeGroup);
    });
    setGroupStates((prevState) => ({
      ...prevState,
      [sizeGroup]: checked,
    }));
  };

  const handleChosenSizes = (action, checkingSize, checkingSizeGroup) => {
    let copySizesList = [...reformatData.basic_sizes_list];
    for (let i = 0; i < copySizesList.length; i++) {
      if (copySizesList[i].group == parseInt(checkingSizeGroup)) {
        if (action == 'append') {
          if (!copySizesList[i].chosen_sizes.includes(checkingSize)) {
            copySizesList[i].chosen_sizes.push(checkingSize);
          }
        } else {
          let startIndex = copySizesList[i].chosen_sizes.indexOf(checkingSize);
          if (startIndex !== -1) {
            copySizesList[i].chosen_sizes.splice(startIndex, 1);
          }
        }
      }
    }
    setReformatData({ ...reformatData, basic_sizes_list: copySizesList });
  };

  const checkIfChosen = (element, groupId) => {
    if (reformatData.basic_sizes_list) {
      let valueList = [...reformatData.basic_sizes_list];
      for (let i = 0; i < valueList.length; i++) {
        if (valueList[i].group == parseInt(groupId)) {
          let chosenSizes = valueList[i].chosen_sizes;
          if (chosenSizes) {
            for (let j = 0; j < chosenSizes.length; j++) {
              if (chosenSizes[j] == element) return true;
            }
          }
        }
      }
    }
    return false;
  };

  const isSizeGroupChanged = (incomeGroup) => {
    if (incomeGroup !== sizeGroupHelper) {
      sizeGroupHelper = incomeGroup;
      return true;
    } else {
      return false;
    }
  };

  const [groupStates, setGroupStates] = useState({});

  const isGroupChecked = (sizeGroup) => {
    const checkboxes = document.querySelectorAll(`input[data-group-id="${sizeGroup}"]`);
    return Array.from(checkboxes).every((checkbox) => checkbox.checked);
  };

  useEffect(() => {
    const newGroupStates = {};
    formats.forEach((el) => {
      if (el?.size_group) {
        newGroupStates[el.size_group] = isGroupChecked(el.size_group);
      }
    });
    setGroupStates(newGroupStates);
  }, [formats]);

  useEffect(() => {
    const checkboxes = document.querySelectorAll(`input[type="checkbox"]`);
    checkboxes.forEach((checkbox) => {
      const sizeGroup = checkbox.getAttribute('data-group-id');
      if (sizeGroup) {
        checkbox.checked = groupStates[sizeGroup];
      }
    });
  }, [groupStates]);
  return (
    <>
      <ul className="format-list">
        {formats.length > 0 ? (
            formats.map((el, index) => {
              return (
                  <>
                    {el?.size_group_name && isSizeGroupChanged(el.size_group_name) && (
                        <li key={`group-${index}`}>
                          <label className="real-checkbox">
                            <input
                                type="checkbox"
                                onChange={(e) => handleGroupCheck(el.size_group, e.target.checked)}
                                checked={isGroupChecked(el.size_group)}
                            />
                            <span className="checkmark"></span>
                            <label className={'group-name'}>{el.size_group_name}</label>
                          </label>
                        </li>
                    )}
                    {el?.size ? (
                        <li key={`li-${index}`}>
                          <label className="real-checkbox">
                            <input
                                type="checkbox"
                                value={el.size}
                                onChange={handleInputs}
                                checked={checkIfChosen(el.size, el.size_group)}
                                data-group-id={el.size_group}
                            />
                            <span className="checkmark"></span>
                            {el.size}
                          </label>
                        </li>
                    ) : null}
                  </>
              );
            })
        ) : (
          <>
            {loading
            ? <span>loading</span>
            : <li><span className="error">brak dostępnych formatów</span></li>
            }
          </>
        )}
      </ul>
    </>
  );
};

export default FormatList;
