import TableProductList from "../components/elements/e-commerce/Table/collapseElements/TableProductList";
import TableLogotypesList from "../components/elements/e-commerce/Table/collapseElements/TableLogotypesList";
import getZipPackage from "./getZipPackage";

/**
 * A method that helps extract information from calculeted content field
 *
 * example usage: generateDisplayData.js, prepareDataKeyVisual.js
 *
 * @param {Array} content - calculated content
 * @returns Returns the name derived from the calculated content, a text value hidden under a specific ID
 */

const generateCalculetedContent = (content, customSizeList) => {
  let result = "";
  if(content && typeof content !== 'string'){
    content.forEach((el, index) => {
      if (el.value) {
        result += el.value;
      } else if (el.default_name) {
        result += el.default_name;
      }

      if (index <= content.length - 1) result += ", ";
    });
  }else if(content){
    result+=content;
  }


  if(customSizeList?.length > 0) result += customSizeList.join(', ')
  return result;
};

/**
 * A method for generating an array consisting of TableProductList components for
 * generating a view of displaying product codes along with their mechanics
 *
 * example usage: generateDisplayData.js, prepareDataKeyVisual.js
 *
 * @param {Array} content - calculated content field
 * @param {Integer} variant_id - id of order variant
 * @returns Returns a list of TableProductList components along with a button to download the zip package
 */

const generateProductContent = (content, variant_id) => {
  let result = [];

  if(content?.length > 0){
    content.forEach((el, index) => {
      if (el.ean) {
        result.push(<TableProductList value={el} external_id={el.external_id} key={`ean-code-${index}`} hasPhoto={el.photos[0]?.url ? true : false}/>);
      }
    });
  
    result.push(<button className="download-products" onClick={()=> getZipPackage(variant_id, 'product')}></button>);
  }
  
  return result;
};

/**
 * A method for generating an array consisting of TableLogotypeList components for
 * generating a view of displaying lgotype codes along with their mechanics
 *
 * example usage: generateDisplayData.js, prepareDataKeyVisual.js
 *
 * @param {Array} content - calculated content field
 * @returns Returns a list of TableLogotypeList components
 */

const generateLogotypeContent = (content, variant_id, customInputValue) => {
  let result = [];
  content.forEach((el, index) => {
    if (el.name && el.url) {
      result.push(<TableLogotypesList value={el.name} external_id={el.id} key={`ean-code-${index}`} url={el.url}/>);
      // if(index < content.length-1) result.push(<span className="delimiter">,</span>);
    }
  });

  if(customInputValue) result.push(<span className="delimiter" dangerouslySetInnerHTML={{ __html: customInputValue }}></span>);
  result.push(<button className="download-products" onClick={()=> getZipPackage(variant_id, 'logotype')}></button>);

  return result;
};

export default { generateCalculetedContent, generateProductContent, generateLogotypeContent };
