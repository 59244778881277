 import React, { useState, useContext, useEffect } from "react";
import orderServices from "../../../../services/orderServices";
import variantsServices from "../../../../services/variantsServices";
import checkPermissions from "../../../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../../wrapper/AuthWrap";
import Loader from "../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../globalElements/Loader/LOADER_TYPE";
import AGENTION_NOTES_ENUMS from "./ENUMS/AGENTION_NOTES_ENUMS";
import Popup from "../../Popup/Popup";
import POPUP_TYPES from "../../Popup/ENUMS/POPUP_TYPES";
import InfoPopup from "../../Popup/InfoPopup";
import usePopup from "../../../../helper/usePopup";
import TextEditor from "../../OrderBoxFields/TextEditor";
import * as Sentry from "@sentry/react";
import SomethingWentWrong from "../../Popup/AddOrder/SomethingWentWrong";

const AgentionNotes = ({ isReformat, orderID, showVariant, variantsData, setUpdateVariantsData, updateVariantData, reformatData, noteType, viewType, saveButton }) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  const [notValidated, setNotValidated] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [sendingDat, setSendingData] = useState(false);
  const [agentionNotesData, setAgendtionNotesData] = useState([]);
  const [hasPermissions, setHasPermissions] = useState(false);
  const { initPopup } = usePopup();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (agentionNotesData[showVariant]) {
      let obj = agentionNotesData[showVariant];

      setTextAreaValue(agentionNotesData[showVariant][noteType]);
    }
  }, [showVariant]);

  useEffect(() => {
    if (noteType == AGENTION_NOTES_ENUMS.NOTE_TYPE.MANAGE_BY_NOTE) {
      setHasPermissions(checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]));
    } else {
      setHasPermissions(
        checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION, PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP])
      );
    }
    if (isReformat) {
      setTextAreaValue(reformatData || "");
    } else {
      let variantsDataCopy = variantsData.map((el, index) => {
        let obj = { variant_id: el.id, acc_note: el.acc_note, assigned_to_note: el.assigned_to_note };

        if (index == showVariant) setTextAreaValue(el[noteType]);

        return obj;
      });

      setAgendtionNotesData(variantsDataCopy);
    }
  }, [variantsData, reformatData]);

  const handleSaveValue = (e) => {
    if (textAreaValue) {
      let body = {};
      body[noteType] = textAreaValue;


      Sentry.captureMessage(`Agention note save data: ${JSON.stringify(body)}, Order id: ${orderID}`, "info");

      setSendingData(true);
      if (isReformat) {
        orderServices
          .changeOrderDetail(orderID, body)
          .then((res) => {
            setSendingData(false);
            setSaveSuccess(true);
            if(setUpdateVariantsData){
              setUpdateVariantsData(!updateVariantData)
            }
          })
          .catch((error) => {
            console.log("UPDATE NOTE FOR REFORMAT ERROR");
            console.error(error);
            initPopup(<SomethingWentWrong /> )
            setSendingData(false);
          });
      } else {
        variantsServices
          .updateVariants(agentionNotesData[showVariant].variant_id, body)
          .then((res) => {
            setSendingData(false);
            setSaveSuccess(true);
            if(setUpdateVariantsData){
              setUpdateVariantsData(!updateVariantData)
            }
          })
          .catch((error) => {
            console.log("UPDATE NOTE FOR VARIANT ERROR");
            console.error(error);
            initPopup(<SomethingWentWrong /> )
            setSendingData(false);
          });
      }
    } else {
      setNotValidated(true);
    }
  };

  const handleTextArea = (e) => {
    let value = e.target.value;
    if (!isReformat) {
      let agentionNotesDataCopy = [...agentionNotesData];
      agentionNotesDataCopy[showVariant][noteType] = value;
      setAgendtionNotesData(agentionNotesDataCopy);
    }
    setTextAreaValue(value);
  };
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      {viewType === AGENTION_NOTES_ENUMS.VIEW_TYPE.TABLE &&
          ( noteType === AGENTION_NOTES_ENUMS.NOTE_TYPE.ASSIGNED_TO_NOTE ||
              noteType === AGENTION_NOTES_ENUMS.NOTE_TYPE.MANAGE_BY_NOTE) &&
      checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE]) ? null : (
        <>
          <div className={`agention-notes ${viewType}`}>
            <div className="info-box">
              <div className="text-content">
                <span>{AGENTION_NOTES_ENUMS.NOTE_TEXT[noteType].TITLE}{' '}{AGENTION_NOTES_ENUMS.NOTE_TEXT[noteType].SUB_TITLE}</span>
              </div>
              <div className="text-area-content">
                <TextEditor
                    readOnly={!hasPermissions}
                    saveData={setTextAreaValue}
                    initialValue={textAreaValue}
                    placeholder={hasPermissions ? `Napisz wiadomość` : `Tylko do odczytu`}
                    onFocus={()=> setIsFocused(true)}
                    onBlur={()=> setIsFocused(false)}
                    style = {{
                      border:isFocused ? '1px solid #1ab394': '1px solid #E4E5E6',
                      width: '100%',
                      height: '150px',
                      background: '#FFF',
                      borderRadius: '8px',
                      padding : '10px',
                      fontFamily: `"Montserrat", sans-serif`,
                      fontSize: `12px`
                    }}
                />
                {hasPermissions && saveButton && (
                  <button className={`save`} onClick={handleSaveValue}>{sendingDat ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15}/> : "Zapisz"}</button>
                )}
                {notValidated && <span className="error">*Aby zapisać uzupełnij pole tekstowe</span>}
                {saveSuccess  && <span className="success">Pomyślnie zapisano</span>}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AgentionNotes;
