import React, {useEffect, useState} from 'react';
import usePopup from "../../../helper/usePopup";
import Slider from "../Table/Slider";
import checkPrefix from "../../../../functions/checkPrefix";
import { ReactComponent as Close } from "../../../../assets/closse-icon.svg";

const ImageCarouselPopup = ({type, src, images, close}) => {
    const { closePopup } = usePopup();
    const [name, setName] = useState(extractFileName(src));
    function extractFileName(url) {
        if(url){
            const parts = url.split('/');
            const fileNameWithExtension = parts.pop() || '';
            return fileNameWithExtension.split('.').slice(0, -1).join('.');
        }
    }
    
 return (
     <div className={`carousel-popup ${type === 'multiple' && 'carousel'}`} onClick={e => e.stopPropagation()}>
         <div className='close-container'>
             <Close style={{cursor: 'pointer'}} onClick={(e) => {
                 e.stopPropagation();
                 closePopup();
                 close(false);
             }}/>
         </div>
         {type === 'multiple' && images.length ? (
             <Slider spaceBetween={5} slidesPerView={1} slides={images} hasCustomNavigation={true} keyBoardControl={true} src={src} setName={setName} extractFileName={extractFileName}/>
         ) : (
             <>
                 <figure>
                     <img src={checkPrefix(src)} alt={extractFileName(src)}></img>
                 </figure>
             </>
         )}
         <div className='title-container'>
             <span>{name}</span>
         </div>
     </div>
 );
};

export default ImageCarouselPopup;
