const removeHiddenBoxSettingsVariant = (hiddenBoxes,variantToRemove) =>{
  let result = [];
  hiddenBoxes.forEach(el=>{
    if(el.variants.includes(parseInt(variantToRemove))){
      let newVariantsArr = el.variants.filter(variant=>{
        if(variant != variantToRemove) return variant
      })

      result.push({...el, variants: newVariantsArr});
    }else{
      result.push(el);
    }
  })
  
  return hiddenBoxes;
}

export default removeHiddenBoxSettingsVariant;