import React, { useState, useContext } from "react";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions, {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import CounterPopup from "../../../Popup/CounterPopup";
import usePopup from "../../../../../helper/usePopup";
import { ReloadTableContext } from "../../../../../Router";
import Restore from "../../../Popup/AddOrder/Restore";

const AddCounterBtn = ({ orderID, isCounter, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const { setReloadComponent } = useContext(ReloadTableContext);
  return (
    <>
      {!isCounter && shouldDisplayButton('add_counter', rowData?.order_status,user,) !== 'Brak' &&  (
        <>
          <button className={`add-counter ${shouldDisplayButton('add_counter', rowData?.order_status,user,) === 'Szary' ? 'casual' : null}`} onClick={()=> initPopup(<CounterPopup orderID={orderID} setReloadComponent={setReloadComponent}/>)}>
            Dodaj licznik
          </button>
        </>
      )}
    </>
  );
};

export default AddCounterBtn;
