/**
 * checkIfSourceIsPDF()- checks if file source is a source of pdf file
 * 
 * @param {String} source 
 * @returns boolean (true or false)
 */

const checkIfSourceIsPDF = (source) => {
  if(source?.endsWith(".pdf")) return true

  return false;
}

export default checkIfSourceIsPDF