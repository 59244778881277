/**
 * 
 * checkIfImageIsBas64() - check if image src is in Bas64 format
 * 
 * @param {String} imageSource 
 * @returns true / false
 */

export const checkIfImageIsBas64 = (imageSource) =>{
  const base64ImagePattern = /^data:image\/[a-zA-Z0-9]+;base64,[A-Za-z0-9+/]+={0,2}$/;

  return base64ImagePattern.test(imageSource)
}