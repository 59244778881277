import React, { useEffect, useState, useContext } from "react";
import {ReactComponent as TimerClock} from "../../../../assets/popups/date.svg";
import { useNavigate } from "react-router-dom";
import orderServices from "../../../services/orderServices";
import usePopup from "../../../helper/usePopup";
import { MainAppContext } from "../../../MainAppComponent";
import timer from "../../../../functions/timer";

const TimerPopup = ({ orderLocks, setOrderLocks, initCorrectionTimer }) => {
  const navigate = useNavigate();

  const { closePopup, clearTimeoutManually } = usePopup();

  const [timerValue, setTimerValue] = useState(0);

  const handleFirstButton = async (repeat) => {
    if (repeat > 0) {
      let res = await orderServices.extendOrderLock(orderLocks.id, 300);

      if ([400, 423, 401].includes(res.status)) {
        handleFirstButton(repeat - 1);
      } else {
        let data = await res.json();
        setOrderLocks(data);
        if (initCorrectionTimer) initCorrectionTimer(data.end_time);
        clearTimeoutManually();
        closePopup();
      }
    }
  };

  const handleSecondButton = async () => {
    try {
      await orderServices.removeOrderLock(orderLocks.id);
      clearTimeoutManually();
      closePopup();
      navigate("/");
      window.location.reload();
    } catch {
      handleSecondButton();
    }
  };

  const initTimerValue = () => {
    let countDownDate = new Date(orderLocks.end_time).getTime();
    let currentDate = new Date().getTime();
    let timeDistance = countDownDate - currentDate;
    // let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);

    let minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);

    setTimerValue(`${minutes}:${seconds}`);
  };

  useEffect(() => {
    initTimerValue();
    timer.secondCountDownTimer(orderLocks.end_time, setTimerValue);
  }, []);

  return (
    <div className={`timer-popup`} onClick={(e) => e.stopPropagation()}>
      <TimerClock />
      <h3>
        Pozostały czas do <br /> wprowadzenia zmian: {timerValue} sek.
      </h3>
      <h4>Przedłuż o 5 min</h4>
      <div className="buttons">
        <button className="button cancel" onClick={handleSecondButton}>
          Anuluj
        </button>
        <button className="button accept" onClick={() => handleFirstButton(3)}>
          Przedłuż
        </button>
      </div>
    </div>
  );
};

export default TimerPopup;
