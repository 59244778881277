export const updateGLobalBoxFieldsData = (variantsObj, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant, isUnique) => {
  let result = [];
  result = boxFieldsData.map((value) => {
    if (value.box_field == boxFieldID) {
      let variantsArr = [];

      if (isUnique) {
        if (value.order.length > 0) {
          variantsArr = value.order.map((el) => {
            if (el.variant_id == actualVariant) {
              return variantsObj;
            } else {
              return el;
            }
          });

          value.order = variantsArr;
        } else {
          value.order = [...value.order, variantsObj];
        }
      } else {
        if (value.variants?.length > 0) {
          variantsArr = value.variants.map((el) => {
            if (el.variant_id == actualVariant) {
              return variantsObj;
            } else {
              return el;
            }
          });

          value.variants = variantsArr;
        } else {
          value.variants = [...value.variants, variantsObj];
        }
      }

      return value;
    } else {
      return value;
    }
  });

  setBoxFieldsData(result);
};

export const updateUniqueFieldsData = (variantsObj, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant) => {
  let result = [];
  result = boxFieldsData.map((value) => {
    if (value.box_field == boxFieldID) {
      let variantsArr = [];

      if (value.order.length > 0) {
        variantsArr = value.order.map((el) => {
          if (el.order_id == actualVariant) {
            return variantsObj;
          } else {
            return el;
          }
        });

        value.order = variantsArr;
      } else {
        value.order = [...value.order, variantsObj];
      }

      return value;
    } else {
      return value;
    }
  });
  setBoxFieldsData(result);
};
