import React, {useState} from "react";
import loop from "../../../assets/loop.svg"

const SearchBar = ({handleSerchBar, searchValue, setSearchValue}) =>{
  // const [searchValue, setSearchValue] = useState('')

  return (
    <div className="serchbar">
      <label>
        <div className={`loop`}>
          <figure><img src={loop}></img></figure>
        </div>
        <input value={searchValue} placeholder="szukaj" onChange={(e)=> {
          setSearchValue(e.target.value) 
          if(handleSerchBar) handleSerchBar(e.target.value)
        }}></input>
      </label>
    </div>
  );
}

export default SearchBar