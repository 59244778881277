import React, {useContext, useEffect, useState} from 'react';
import usePopup from "../../../helper/usePopup";
import AddCounterCalenderDeadline from "./additionalElements/AddCounterCalenderDeadline";
import orderServices from "../../../services/orderServices";
import InfoPopup from "./InfoPopup";
import {useNavigate} from "react-router-dom";
import { ReactComponent as Counter } from "../../../../assets/popups/counter.svg";
import SomethingWentWrong from "./AddOrder/SomethingWentWrong";
import Success from "./AddOrder/Success";
const CounterPopup = ({orderID, setReloadComponent}) => {
    const {closePopup, initPopup} = usePopup();
    const [actionName, setActionName] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [calenderDeadline, setCalenderDeadline] = useState("");
    const navigate = useNavigate();

    const handleActionName = (e) => {
        if (!error) setError("");
        setActionName(e.target.value);
    };
    const handleSaveButton = () => {
        if (actionName !== "" && actionName !== null && actionName !== undefined && calenderDeadline !== "" && calenderDeadline) {
            setLoading(true);
            orderServices
                .addCounter(orderID, actionName, calenderDeadline)
                .then((res) => {
                    closePopup();
                    initPopup(<Success />)
                    setReloadComponent(true);
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                    closePopup();
                    initPopup(<SomethingWentWrong />)
                });
        } else {
            setError("*Musisz wskazać nowy deadline");
        }
    };

    const handleNavigateButton = () => {
        navigate("/sendedprojects");
        window.location.reload();
    };

    const handleCancelButton = () => {
        setActionName("");
        closePopup()
    };

    return (
        <div className="counter-popup">
                <div className="upper-part-popup">
                    <Counter />
                    <h3>Dodaj licznik <br/> do zlecenia.</h3>
                </div>
                <div className="middle-part-popup">
                    <input className="order-name" value={actionName} onChange={handleActionName} placeholder={'Wpisz nazwę akcji'}></input>
                    <div className="input-group">
                        <AddCounterCalenderDeadline setCalenderDeadline={setCalenderDeadline} deadline/>
                        {error && <span className="error">{error}</span>}
                    </div>
                </div>
            <div className="buttons">
                <button className="button cancel" onClick={handleCancelButton}>Anuluj</button>
                <button className="button accept" onClick={handleSaveButton}>{loading ? <span className='loader-spinner'></span> : 'Dodaj'}</button>
            </div>
        </div>
    );
};

export default CounterPopup;
