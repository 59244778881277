import React, { useState, useEffect } from "react";
import { ReactComponent as CorrectionIcon } from "../../../../../assets/korekta.svg";
import { ReactComponent as ClockIcon } from "../../../../../assets/clock.svg";
import timer from "../../../../../functions/timer";

const OrderEditInfo = ({ editInfo,  rowPosition}) => {
  const [timerValue, setTimerValue] = useState("00:00");

  useEffect(() => {
    if(editInfo) timer.displayCountDownTimer(editInfo.end_time, setTimerValue);
  }, []);

  return (
    <div className={`order-edit-info`} style={{alignItems : rowPosition}}>
      <div className={`order-edit-info__data-row--person`}>
        <CorrectionIcon width="10px" height="10px" />
        <span className="order-edit-info__text-content">
          korektę wprowadza: <strong>{editInfo.user_name}</strong>
        </span>
      </div>
      <div className={`order-edit-info__data-row--time`}>
        <ClockIcon width="10px" height="10px" />
        <span className="order-edit-info__text-content">
          estymowany czas wprowadzenia zmian: <strong>{timerValue}</strong>
        </span>
      </div>
    </div>
  );
};

export default OrderEditInfo;
