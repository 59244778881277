import React, { useRef, useState, useContext, useEffect } from "react";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateGLobalBoxFieldsData, updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getActualBoxFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { getBoxFieldContent, getHeadersFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import { getUniqueFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";

const Language = ({ value, description, actualVariant, boxFieldID, validated }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const languageRef = useRef([]);
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);

  const handleLanguageChose = (e) => {
    let chosenLanguage = e.target.dataset.language;
    let initialValue = { ...getUniqueFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: e.target.value }, custom_inputs_value: chosenLanguage };
    setBoxSettings({ ...boxSettings, language: chosenLanguage });
    updateUniqueFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  useEffect(() => {
    value.box_field_dictionary.forEach((el) => {
      if (el.default_name == boxSettings.language) {
        let initialValue = { ...getUniqueFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: el.id }, custom_inputs_value: el.default_name };
        setBoxSettings({ ...boxSettings, language: el.default_name });
        updateUniqueFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
      }
    });
  }, [boxSettings.language]);

  return (
    <div className="language">
      <h3>Język</h3>
      <div className="input-group">
        {value.box_field_dictionary
          ? value.box_field_dictionary.map((el, index) => {
              return (
                <label className="checkbox" key={`language-${index}`}>
                  <input 
                    checked={getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant, true) == el.id ? true : false} 
                    type="checkbox" data-language={el.default_name} data-name={`language`} value={el.id ?? ""} 
                    onChange={handleLanguageChose} 
                    ref={(el) => languageRef.current.push(el)}></input>
                  <span className="checkmark"></span>
                  {el.default_name}
                </label>
              );
            })
          : null}
      </div>
      {!validated && <span className="error">*Wybierz język zlecenia</span>}
    </div>
  );
};

export default Language;
