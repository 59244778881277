import checkPermissions from "../../../../../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../../../../../globalEnums/PERMISSIONS_ENUMS";

/**
 * Method that handle HTML DOM class, for recognize if user is ACC or other
 * 
 * @param {Object} user - Object of user data
 * @returns acc-perm - if user is ACC, casual - if user is DTP, CLIENT or CREATION
 */

const handlePermissionClass = (user) => {
  let isAccc = checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]);

  return isAccc ? "acc-perm" : "casual";
};

export default handlePermissionClass