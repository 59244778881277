const getApiKey = () => {
  let cookies = document.cookie.split("; ");
  let apiKey = null;
  for (let i = 0; i < cookies.length; i++) {
    if (cookies[i].includes("api_key")) {
      apiKey = cookies[i].replace("api_key=", "");
      break;
    }
  }

  return apiKey;
};

export default getApiKey;
