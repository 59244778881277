import FILTER_BAR_TYPES from "../ENUMS/FILTER_BAR_TYPE";

/**
 * 
 * @param {Array} perUserData 
 * @param {Array} statusData 
 * @param {String} chosenDateFrom 
 * @param {String} chosenDateTo 
 * @param {String} filterType 
 * @param {String} user_id 
 * @returns endpoint to get order list with implemented filters
 */

const generateFilterEndpointForOrders = (perUserData, statusData, chosenDateFrom, chosenDateTo, filterType) => {
  // let endpointURL = ENPOINTS_LIST[filterType];
  let endpointURL = '&';

  if (perUserData.length > 0) endpointURL += `per_user=${perUserData.join(",")}&`;
  if (statusData.length > 0) {
    if (
        [FILTER_BAR_TYPES.MY_PROJECT_BOX, FILTER_BAR_TYPES.NEW_ORDER_BOX, FILTER_BAR_TYPES.TEAM, FILTER_BAR_TYPES.TEAM_BOX_ACC].includes(filterType)
    ) {
      endpointURL += `designer_status=${statusData.join(",")}&`;
    } else {
      if(FILTER_BAR_TYPES.NOTIFICATION === filterType){
        endpointURL += `is_read=${statusData.join(",")}&`
      }else{
        if(statusData.length === 1){
          endpointURL += `${statusData[0]}=1&`;
        }else{
          statusData.map(el => endpointURL += `${el}=1&`)
        }
      }
    }
  }
  if (chosenDateFrom) endpointURL += `deadline_from=${chosenDateFrom}&`;
  if (chosenDateTo) endpointURL += `deadline_to=${chosenDateTo}&`;

  return endpointURL;
};

export default generateFilterEndpointForOrders;