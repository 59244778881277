import React, { useRef, useState, useEffect } from "react";
import usePopup from "../../../helper/usePopup";
import logotypeServices from "../../../services/logotypeServices";
import Dropdown from "../../globalElements/Dropdown/Dropdown";
import InfoPopup from "./InfoPopup";
import img from "../../../../assets/product-modal.svg";
import noPhotoPlaceholder from "../../../../assets/no-photos.svg";

const LogoTypeChooserPopup = ({ saveData, removeData, initialData}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [logotypeList, setLogotypeList] = useState([]);
  const [allowRemove, setAllowRemove] = useState(false);

  const [logotypeData, setLogotypeData] = useState({})

  const { closePopup, initPopup } = usePopup();
  const inputRef = useRef();

  useEffect(()=>{
    if(initialData){
      setLogotypeData({
        ...logotypeData,
        id: initialData.id,
        name: initialData.name,
        url: initialData.url,
        type: initialData.type
      })

      if(initialData.id && initialData.name && initialData.url) setAllowRemove(true);
    }
  }, [initialData])

  const handleInput = (e) => {
    if (e.target.value !== "") {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }

    setLogotypeData({...logotypeData, name: e.target.dataset.nameContent})
    getLogotypList(e.target.value);
  };

  const getLogotypList = (name) => {
    setTimeout(() => {
      if (name) {
        logotypeServices
          .getLogotypList(name)
          .then((res) => {
            let data = res.data;

            let logotypeArr = data.map((el) => {
              return { value: el.url, displayName: el.name, groupId: el.id };
            });

            setLogotypeList(logotypeArr);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<InfoPopup type={"negative"} info={"product_list"} />);
          });
      }
    }, 200);
  };

  const handleListElement = (e) => {
    setShowDropdown(false);
    logotypeServices.getLogotypeImage(e.target.dataset.groupId).then(res=>{
      setLogotypeData({
        ...logotypeData,
        id: e.target.dataset.groupId,
        name: e.target.dataset.nameContent,
        url: `${process.env.REACT_APP_MEDIA_URL}${res.width && res.height ? res.image : '/static/products/no-photos.jpg'}`// if widht and height are equal null there is no photo
      })
    })
  };

  const handleSaveButton = () =>{
    saveData(logotypeData)
    closePopup();
  }

  const handleRemoveButton = () =>{
    removeData(logotypeData.type)
    closePopup();
  }

  return (
    <div className="outer-add-product-popup chooser">
      <div className="add-product-popup">
        <div className="title-section">
          <figure>
            <img src={img}></img>
          </figure>
          <h3>
            DODAJ<span>logotyp</span>
          </h3>
          <div className={`green-bar`}>
            <span>dodaj / wyszukaj logotyp</span>
          </div>
        </div>
        <div className="fields">
          <div className={"index"}>
            <h2>Logotyp</h2>
            <input placeholder="wpisz/wybierz po nr. logotypu" onChange={handleInput} ref={inputRef} value={logotypeData.name}></input>
            {showDropdown && (
              <Dropdown
                data={logotypeList}
                handleListElement={handleListElement}
                displayCheckbox={false}
                positionToElementRef={inputRef}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
              />
            )}
          </div>
          {/* {error ? <span>podaj kod ean</span> : null} */}
          <div className="view">
            <h2 style={{ color: "rgba(0, 0, 0, 0.3)" }}>podgląd</h2>
            <div className={`show-field`}>
              {logotypeData.url && (
                <figure>
                  <img src={logotypeData.url}></img>
                </figure>
              )}
            </div>
          </div>
          <div className="bottom always-show">
            {logotypeData.name != '' && <button className="save" onClick={handleSaveButton}>Dodaj</button>}
            <button className="cancel" onClick={closePopup}>Anuluj</button>
            {allowRemove && <button className="remove" onClick={handleRemoveButton}>Usuń</button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoTypeChooserPopup;
