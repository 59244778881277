import React, { useEffect, useState, useContext } from "react";
import clockIcon from "../../../../assets/wprowadzania_korerkty.png"
import { useNavigate } from "react-router-dom";
import orderServices from "../../../services/orderServices";
import usePopup from "../../../helper/usePopup";
import { MainAppContext } from "../../../MainAppComponent";
import timer from "../../../../functions/timer";

const TimerPopup = ({ orderLocks, setOrderLocks, initCorrectionTimer }) => {
  const navigate = useNavigate();

  const { closePopup, clearTimeoutManually } = usePopup();

  const [timerValue, setTimerValue] = useState(0);

  const handleFirstButton = async (repeat) => {
    if (repeat > 0) {
      let res = await orderServices.extendOrderLock(orderLocks.id, 900);

      if ([400, 423, 401].includes(res.status)) {
        handleFirstButton(repeat - 1);
      } else {
        let data = await res.json();
        setOrderLocks(data);
        if(initCorrectionTimer) initCorrectionTimer();
        clearTimeoutManually();
        closePopup();
      }
    }
  };

  const handleSecondButton = async () => {
    try {
      await orderServices.removeOrderLock(orderLocks.id);
      clearTimeoutManually();
      closePopup();
      navigate("/");
      window.location.reload();
    } catch {
      handleSecondButton();
    }
  };

  const initTimerValue = () =>{
    let countDownDate = new Date(orderLocks.end_time).getTime();
    let currentDate = new Date().getTime();
    let timeDistance = countDownDate - currentDate;
    // let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);

    let minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);

    setTimerValue(`${minutes}:${seconds}`);
  }

  useEffect(() => {
    initTimerValue();
    timer.secondCountDownTimer(orderLocks.end_time, setTimerValue);
  }, []);

  return (
    <div className={`timer-popup neutral`} onClick={(e) => e.stopPropagation()}>
      <div className={`popup-contents neutral`}>
        <div className="popup-upper-part">
          <img className="icon-section" src={clockIcon}></img>
          <span className={`text-small neutral`}>czas na wprowadzenie zmian minął</span>
          <span className={`text-big neutral`}>Potrzebujesz więcej czasu <br/> na wprowadzenie <br/> zmian</span>
        </div>
        <div className="timer-section">
          <span className={`text-timer neutral`}>Pozostały czas do zamknięcia korekty*</span>
          <span className={`text-timer neutral timer`}><strong>{timerValue} sek</strong></span>
        </div>
        <div className="popup-lower-part">
          <div className="popup-buttons">
            <button className={`popup-button first positive`} onClick={() => handleFirstButton(3)}>
              Tak, poproszę o kolejne 5 minut
            </button>
            <button className={`popup-button second negative`} onClick={handleSecondButton}>
              Nie, anuluj koretkę*
            </button>
          </div>
          <div className='text-last-container'>
            <span className={`text-last neutral`}>
              *wprowadzone przez Ciebie zmiany w korekcie nie zostaną naniesione do zlecenia i zostaną utracone.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimerPopup;
