import React from "react";
import noPhotoPlaceholder from "../../../../../../assets/no-photos.svg";

const TeamProductViewBox = ({data}) => {
  return (
    <div className="block" key={data.id} data-view={data.id}>
      <div className={data.img_src ? "photo" : "photo no-photo"}>
          <img
            src={data.img_src || noPhotoPlaceholder}
            data-view={data.id}
            style={{
              width: data.display_width,
              height: data.display_height,
              transform: `rotate(${data.rotate ?? 0}deg)`,
          }}
          ></img>
      </div>
    </div>
  );
};


export default TeamProductViewBox