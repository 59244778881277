import TABLE_TYPES from "../globalEnums/TABLE_TYPES_ENUMS";
import IMAGE_STATUS from "../globalEnums/IMAGE_STATUS";

/**
 * imageFilterByStatus() - filter for images by allowed status list
 * 
 * @param {Array} images array of images
 * @returns images to display with allowed status
 */

const imageFilterByStatus = (images, type) => {
  let allowedStatus;

  if(type && type == TABLE_TYPES.FINISHED){
    allowedStatus = [IMAGE_STATUS.ACCEPTED, IMAGE_STATUS.AUTOACCEPTED];
  }else allowedStatus = [IMAGE_STATUS.ACCEPTED, IMAGE_STATUS.CREATED, IMAGE_STATUS.AUTOACCEPTED];

  let filteredImages = images.filter((image) => {
    if (allowedStatus.includes(image.status)) return image;
  });

  return filteredImages;
};

export default imageFilterByStatus;
