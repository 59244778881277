/**
 * formatDate() - method that get date passed to incomeDate and format it to display in HH:MM DD-MM-YYYY format
 * 
 * example usage: NotificationsCollapseStructure.js, TableBody.js
 * 
 * @param {String} incomeDate - string date that we want to display 
 * @returns string (formated date)
 */

const formatDate = (incomeDate) => {
  let date = new Date(incomeDate);
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  let minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  let fullData = `${hour}:${minute} ${day}-${month}-${year}`;

  return fullData;
};

export default formatDate;