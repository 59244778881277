import React, { useRef } from "react";
import { Link } from "react-router-dom";
import avatar from "../../assets/avatar.png";
const Menu = () => {
  const menuRef = useRef();

  return (
    <nav>
      <div className="avatar-section">
        <div className="avatar">
          <figure>
            <img src={avatar}></img>
          </figure>
        </div>
        <div className="name">
          <span>Admin</span>
          <span>Mateusz</span>
          <span>ABC4brands sp. z o.o.</span>
        </div>
      </div>
      <ul className="menu-elements">
        <li>
          <Link to="#">Kokpit</Link>
        </li>
        <li>
          <Link to="#">AdProducts</Link>
        </li>
        <li>
          <Link to="#">Dostawcy</Link>
        </li>
        <li>
          <Link to="#">Obrazy</Link>
        </li>
        <li>
          <Link to="#">AdCatalogues</Link>
        </li>
        <li>
          <Link to="#">AdProduction</Link>
        </li>
        <li>
          <Link to="#">AdLogistic</Link>
        </li>
        <li>
          <Link to="#">Alerty</Link>
        </li>
        <li className="sidebar-active">
          <Link to="/">E-COMMERCE</Link>
        </li>
      </ul>
      <div className="helpdesk"></div>
    </nav>
  );
};

export default Menu;
