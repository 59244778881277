import React, { useCallback, useState, useContext, useEffect, createContext } from "react";
import RaportsRadioButtons from "./RaportsRadioButtons";
import RaportsHeader from "./RaportsHeaders";
import Loader from "../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../globalElements/Loader/LOADER_TYPE";
import { MainAppContext } from "../../../MainAppComponent";
import handleDataListElements from "../../../../functions/handleDataListElements";
import reportServices from "../../../services/reportServices";
import handleDownload from "../../../../functions/handleDownload";
import { getFileBaseName } from "../../../../functions/getFileBaseName";
import RAPORTS_ENUMS from "./RAPORTS_ENUMS";
import Popup from "../Popup/Popup";
import POPUP_TYPES from "../Popup/ENUMS/POPUP_TYPES";
import InfoPopup from "../Popup/InfoPopup";
import usePopup from "../../../helper/usePopup";

let headersSettings = [
  { title: "Nazwa raportu", values: [], layoutDirection: "column", type: "raport_name", props: {} },
  { title: "Za okres", values: [], layoutDirection: "row", type: "calender", props: {} },
  {
    title: "Rodzaj zliczania raportów",
    values: [
      { value: RAPORTS_ENUMS.METHOD_TYPE.FILE_CREATION, displayName: "wg. dat plików" },
      { value: RAPORTS_ENUMS.METHOD_TYPE.ORDER_FINISH, displayName: "wg. dat zamknięcia zlecenia" },
    ],
    layoutDirection: "row",
    type: "methodType",
    props: {},
  },
];

const RaportsContainer = () => {
  const { generalSettings, setGeneralSettings } = useContext(MainAppContext);

  //settings
  const [radioListSettings, setRadioListSettings] = useState([
    { title: "Zakres arkuszy raportu", values: [], layoutDirection: "column", type: "range", isValidated: true },
    { title: "Język zleceń", values: [], layoutDirection: "row", type: "language", isValidated: true },
    {
      title: "Rodzaj zleceń",
      values: [
        { id: RAPORTS_ENUMS.TYPE.REJECTED, default_name: "odrzucone" },
        { id: RAPORTS_ENUMS.TYPE.ACCEPTED, default_name: "zaakceptowane" },
        { id: RAPORTS_ENUMS.TYPE.ALL, default_name: "wszystkie" },
      ],
      layoutDirection: "row",
      type: "type",
      isValidated: true,
    },
  ]);

  //states for generator API
  const [rangeData, setRangeData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [typeData, setTyoeData] = useState([]);
  const [chosenDateFrom, setChosenDateFrom] = useState("");
  const [chosenDateTo, setChosenDateTo] = useState("");
  const [getRaport, setGetRaport] = useState(false);
  const [raportName, setRaportName] = useState("");
  const [chosenMethod, setChosenMethod] = useState("");
  const [chosenMethodName, setChosenMethodName] = useState("");
  const [chosenType, setChosenType] = useState([]);
  const [showValidationInfo, setShowValidationInfo] = useState(false);
  const [error, setError] = useState({ type:''})
  const { initPopup } = usePopup();
  const initData = () => {
    let raportsSettings = [];
    let radioListSettingsCopy = [...radioListSettings];
    for (let i = 0; i < generalSettings.length; i++) {
      let boxFields = generalSettings[i].box.box_fields;

      for (let j = 0; j < boxFields.length; j++) {
        if (["size"].includes(boxFields[j].field_type)) {
          radioListSettingsCopy[0].values = [...boxFields[j].box_field_dictionary, { id: "reformats", default_name: "przeformatowania" }];
        } else if (["client_language"].includes(boxFields[j].field_type)) {
          radioListSettingsCopy[1].values = boxFields[j].box_field_dictionary;
        }

        if (raportsSettings.length == 2) break;
      }

      if (raportsSettings.length == 2) break;
    }

    setRadioListSettings(radioListSettingsCopy);
  };

  useEffect(() => {
    initData();
  }, [generalSettings]);

  const handleRadioListElement = useCallback(
    (e) => {
      let element = e.currentTarget;
      setError({ type: ''});
      if (element.dataset.inputType == "range") {
        let updateState = handleDataListElements(element.value, rangeData);
        setRangeData(updateState);
      } else if (element.dataset.inputType == "language") {
        setLanguageData(element.value);
      } else if (element.dataset.inputType == "type") {
        setChosenType([element.value]);
      } else {
        setChosenMethod(element.value);
        setChosenMethodName(element.dataset.nameContent);
      }
    },
    [rangeData, languageData, typeData, chosenMethod]
  );

  const downloadRaport = () => {
    if (validateLanguageOption()) {
      if(!chosenDateFrom && !chosenDateTo){
        initPopup(<InfoPopup type={'negative'} info={'date'}/>)
        setError({ type: 'calender' })
      }else if(!chosenMethodName){
        initPopup(<InfoPopup type={'negative'} info={'method'}/>)
        setError({ type: 'methodType' })
      }else {
        setGetRaport(true);
        reportServices
            .getReport(chosenMethod, chosenType[0], chosenDateTo, chosenDateFrom, null, raportName, languageData, rangeData)
            .then((res) => {
              setGetRaport(false);

              let basename = getFileBaseName(res.file);
              handleDownload(basename, res.file);
            })
            .catch((error) => {
              console.log("GET RAPORT ERROR");
              console.error(error);
              setGetRaport(false);
            });
      }
    }
  };

  const validateLanguageOption = () => {
    let validationResult = true, updateStatement = false;
    let radioListSettingsCopy = [...radioListSettings];

    if (languageData.length == 0) {
      validationResult = false;
      initPopup(<InfoPopup type={'negative'} info={'language'}/>)
    }
    for(let i=0; i<radioListSettingsCopy.length; i++){
      if(radioListSettingsCopy[i].type == 'language'){
        if(radioListSettingsCopy[i].isValidated !== validationResult){
          radioListSettingsCopy[i].isValidated = validationResult;
          updateStatement = true;
        }

        break;
      }
    }

    if(updateStatement) setRadioListSettings(radioListSettingsCopy);

    return validationResult;
  };

  const headersChosenDate = (type) => {
    let result = "";

    if (type == "calender") {
      if (chosenDateFrom) result += `od ${chosenDateFrom} `;
      if (chosenDateTo) result += `do ${chosenDateTo} `;
    } else if (type == "methodType") {
      result = chosenMethodName;
    } else {
      result = raportName;
    }

    return result;
  };

  const getChosenData = (type) => {
    if (type == "range") return rangeData;
    if (type == "language") return languageData;
    if (type == "type") return chosenType;
  };

  return (
    <div className="raports-container">
      <div className="raport-headers">
        {headersSettings &&
          headersSettings.map((el, index) => {
            return (
              <RaportsHeader
                key={`raports-header-${index}`}
                title={el.title}
                type={el.type}
                values={el.values}
                chosenDateFrom={el.type == "calender" ? chosenDateFrom : null}
                setChosenDateFrom={el.type == "calender" ? setChosenDateFrom : null}
                chosenDateTo={el.type == "calender" ? chosenDateTo : null}
                setChosenDateTo={el.type == "calender" ? setChosenDateTo : null}
                value={headersChosenDate(el.type)}
                handleListElement={handleRadioListElement}
                setRaportName={el.type == "raport_name" ? setRaportName : null}
                error={error}
                setError={setError}
              />
            );
          })}
      </div>
      <div className="raports-settings">
        <div className="raports-wrapper">
          {radioListSettings.map((el, index) => {
            if (el.values.length > 0) {
              return (
                <RaportsRadioButtons
                  key={`raports-radio-list-${index}`}
                  title={el.title}
                  values={el.values}
                  layoutDirection={el.layoutDirection}
                  type={el.type}
                  handleRadioListElement={handleRadioListElement}
                  chosenData={getChosenData(el.type)}
                  isValidated={el.isValidated}
                />
              );
            }
          })}
        </div>
        <button className="generate-raport" onClick={downloadRaport}>
          {getRaport ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} /> : "Generuj raport"}
        </button>
      </div>
    </div>
  );
};

export default RaportsContainer;
