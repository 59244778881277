import React, { useEffect, useState, useContext } from "react";
import AddCampaignAction from "./AddCmpaignAction";
import BackgroundShadow from "../globalElements/BackgroundShadow";
import Dropdown from "../globalElements/Dropdown/Dropdown";
import { MainAppContext } from "../../MainAppComponent";
import { ReactComponent as DropdownIcon } from "../../../assets/dropdownicon.svg"
/**
 * CampaignDropdownContainer - visual component
 *
 * that component required declaration of all fields
 */

const CampaignDropdownContainer = ({
  blockEdit,
  dropDownArea,
  validate,
  campaignValue,
  handleInput,
  inputRef,
  dropdownRef,
  handleAddButton,
  noSourceError,
  displayDropdownData,
  handleListElement,
  showDopdown,
  setShowDropdown
    ,reloadComponent
}) => {
  const [data, setData] = useState([]);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);

  useEffect(() => {
    let mapedData = displayDropdownData.map((el) => {
      return { value: el.id, displayName: el.name };
    });

    setData(mapedData);
  }, [displayDropdownData]);
  useEffect(()=>{
    setData([]);
  },[reloadComponent])
  const handleDropdown = () => {
    setShadowActive(true);
    setShowDropdown(true);
  };

  return (
    <div>
      <div className={blockEdit ? "dropdown-area blocked" : "dropdown-area"} ref={dropDownArea}>
        <input
          className={validate ? `campaing-input ${showDopdown ? `showbg` : ""}` : "campaing-input not-validated"}
          placeholder="wpisz..."
          value={campaignValue}
          onChange={handleInput}
          onClick={handleDropdown}
          ref={inputRef}
        ></input>
        <DropdownIcon className='arrow-down' onClick={handleDropdown} />
        {showDopdown ? (
          <div className="dropdown-container" ref={dropdownRef}>
            {validate ? null : (
              <span className="error">
                *Wybierz z listy lub naciśnij <strong>dodaj kampanie</strong>
              </span>
            )}
            <Dropdown data={data} handleListElement={handleListElement} displayCheckbox={false}>
              <AddCampaignAction handleAddButton={handleAddButton} noSourceError={noSourceError} />
            </Dropdown>
            <BackgroundShadow setShow={setShowDropdown} handleShadow={true}/>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CampaignDropdownContainer;
