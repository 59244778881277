import React from "react";

const TableProductList = ({ value, hasPhoto }) => {
  return (
    <>
      <a className={hasPhoto ? `product-reference` : `product-reference no-photo`} href={`/products/${value.external_id}`} target={`_blank`}>
        <span className="value" dangerouslySetInnerHTML={{ __html: value.ean }}></span>
        <span className="size">{`${value.width}x${value.height}`}</span>
        <span className="rotation">{`${value.rotate}`}</span>
      </a>
    </>
  );
};

export default TableProductList;
