import React from "react";
import FILTER_BAR_DROPDOWN_TYPES from "../ENUMS/FILTER_BAR_DROPDOWN_TYPES";

const FilterTabs = ({ showPerAccount, showStatus, showDeadline, handleTab, tabsRef, isPerAccountChosen, isStatusChosen,  isDedlineChosen}) => {

  const handleFilterLabelStatus = () =>{
    return isPerAccountChosen || isStatusChosen || isDedlineChosen
  }

  return (
    <>
      <div className={handleFilterLabelStatus() ? `label active` : `label`}>
        <div className={`icon`}></div>
        <span>FILTR</span>
      </div>
      {showPerAccount && (
        <div
          className={`tab per-account`}
          data-tab-type={FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT}
          onClick={handleTab}
          ref={(el) => tabsRef.current.push(el)}
        >
          <span {... (isPerAccountChosen && {style: {color : `#87C16C`,  fontWeight: 700}})}>per user</span>
        </div>
      )}
      {showStatus && (
        <div className={`tab status`} data-tab-type={FILTER_BAR_DROPDOWN_TYPES.STATUS} onClick={handleTab} ref={(el) => tabsRef.current.push(el)}>
          <span {... (isStatusChosen && {style: {color : `#87C16C`, fontWeight: 700}})}>status</span>
        </div>
      )}
      {showDeadline && (
        <div className={`tab deadline`} data-tab-type={FILTER_BAR_DROPDOWN_TYPES.DEADLINE} onClick={handleTab} ref={(el) => tabsRef.current.push(el)}>
          <span {... (isDedlineChosen && {style: {color : `#87C16C`, fontWeight: 700}})}>deadline</span>
        </div>
      )}
    </>
  );
};

export default FilterTabs;
