import React, { useRef, useEffect } from "react";
import Loader from "../Loader/Loader";
import LOADER_TYPE from "../Loader/LOADER_TYPE";
import { ReactComponent as DropdownIcon } from "../../../../assets/dropdownicon.svg"
//TODO: make that componen universal for all dropdowns in app

const Dropdown = ({
  data,
  handleListElement,
  handleCheckBox,
  displayCheckbox,
  positionToElementRef,
  listDescription,
  children,
  setShowDropdown,
  showDropdown,
                    radioInput
}) => {
  const dropdownListRef = useRef(null);

  useEffect(() => {
    if (positionToElementRef) {
      positionDropdown();
      window.addEventListener("scroll", positionDropdown);
      document.addEventListener("click", handleWindowCLick);
    }
  }, [data, showDropdown]);

  const handleWindowCLick = (e) => {
    if (
      dropdownListRef.current &&
      e.target !== dropdownListRef.current &&
      e.target !== positionToElementRef?.current &&
      !dropdownListRef?.current.contains(e.target) &&
      !positionToElementRef?.current.contains(e.target)

    ) {
      if (setShowDropdown) setShowDropdown(false);
      document.removeEventListener("click", handleWindowCLick);
    }
  };

  const positionDropdown = () => {
    let relativeElement = positionToElementRef.current;
    let dropdown = dropdownListRef.current ? dropdownListRef.current : "";

    if (relativeElement && dropdown) {
      let rect = relativeElement.getBoundingClientRect();

      dropdown.style.position = "fixed";
      dropdown.style.top = rect.bottom + "px";
      dropdown.style.left = rect.left + "px";
      dropdown.style.zIndex = "99999999";
      dropdown.style.maxWidth = relativeElement.getBoundingClientRect().width + "px";
    }
  };
  return (
    <div className="dropdown dropdown-list" ref={dropdownListRef} style={{height: data ? "auto" : `150px`}}>
      {listDescription && (
        <div className="list-search">
          <span>{listDescription}</span>
        </div>
      )}
      {children && children}
      {data ? (
        <ul className="dropdown-list__container">
          {data.map((el, index) => {
            return (
              <li className="dropdown-list__element" key={`dropdown-list-element-${index}`}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleListElement}
                    value={el.value}
                    {...(displayCheckbox ? { checked: handleCheckBox(el.value) } : {})}
                    data-name-content={el.displayName}
                    data-group-id={el?.groupId ? el.groupId : el.value}
                  ></input>
                  {displayCheckbox && <span className={radioInput ? "dropdown-list__radio" : "dropdown-list__checkmark"}></span>}
                  {el.displayName}
                </label>
              </li>
            );
          })}
        </ul>
      ) : (
        <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />
      )}
    </div>
  );
};

export default Dropdown;
