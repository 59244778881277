import React, { useRef, useState } from "react";
import TableContextContainer from "../../elements/e-commerce/Table/TableContextContainer";
import pageLogo from "../../../assets/draft-logo.svg";
import TABLE_TYPES from "../../../globalEnums/TABLE_TYPES_ENUMS";

const DraftProjects = () => {
  return <TableContextContainer tableType={TABLE_TYPES.DRAFT} pageTitle={"Szkice"} logo={pageLogo} />;
};

export default DraftProjects;
