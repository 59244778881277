import React, {useEffect} from "react";
import TextEditor from "../../OrderBoxFields/TextEditor";


const TextBox = ({logo, title, subtitle, type, value, handleSaveData, handleResetData}) => {

  const saveData = (newData) =>{
    handleSaveData(newData, type);
  }

  const handleCheckbox = () =>{
    handleResetData(type)
  }
  
  return (
    <div className={`text-box ${value == null ? 'hidden' : ''}`}>
      <div className="text-box__header">
        <div className="text-box__logo">
          <figure>
            <img src={logo || ''}></img>
          </figure>
        </div>
        <h3 className="text-box__title">{title || ''}</h3>
        <span className="text-box__subtitle">— {subtitle || ''}</span>
        <div class="text-box__hide-box">
          <label class="checkbox">
            <input type="checkbox" onChange={handleCheckbox} checked={value == null}/>
            <span class="checkmark"></span>brak
          </label>
        </div>
      </div>
      <div className="text-box__content">
        <div className="text-box__text-area">
          <TextEditor 
            editorHeight={110}
            initialValue = {value}
            saveData={saveData}
            style = {{
              width: '100%',
              height: '110px',
              background: '#f4f4f4',
              borderRadius: '5px',
              padding : '5px 12px',
              fontFamily: `"Montserrat", sans-serif`,
              fontSize: `12px`
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TextBox;
