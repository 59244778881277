import React, { useContext, useRef, useState } from "react";
import { TableDataContext } from "../../../../Router";

import orderServices from "../../../../services/orderServices";
import { ReloadTableContext } from "../../../../Router";

import SearchBar from "../../../globalElements/SearchBar";
import DownloadDataSheet from "../../../globalElements/DownloadDataSheet";
import FilterBar from "../../../globalElements/Filters/FilterBar";
import FILTER_BAR_TYPES from "../../../globalElements/Filters/ENUMS/FILTER_BAR_TYPE";

const TableActions = ({ setLoadingTableData, paginationPage, setExternalEndpoint, externalEndoint, searchByValue,  setSearchByValue}) => {
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [searchBarValue, setSearchBarValue] = useState("")
  const serchBarInputRef = useRef();
  let postPerPageTimer;

  const handlePostPerPage = (e) => {
    setTableContext((prevState) => ({
      ...prevState,
      postPerPage: e.target.value,
    }));

    clearTimeout(postPerPageTimer);
    postPerPageTimer = setTimeout(() => {
      setReloadComponent(true);
    }, 300);
  };

  const handleSerchBar = (value) => {
    setSearchByValue(value);
  };

  const handleFilterBar = (data) => {
    setTableContext({ ...tableContext, tableData: data.results, staticTableData: data.results, total_page: data.total_page });
    setLoadingTableData(false);
  };


  return (
    <div className="actions">
      <div className="show-number">
        <span>Pokaż</span>
        <input placeholder="all" value={tableContext.postPerPage} onChange={handlePostPerPage} ref={serchBarInputRef}></input>
        <span>wpisów</span>
      </div>
      <FilterBar
        showPerAccount={true}
        showStatus={true}
        showDeadline={true}
        filterType={tableContext.tableType}
        handleFilteredData={handleFilterBar}
        setDataLoader={setLoadingTableData}
        setExternalEndpoint={setExternalEndpoint}
        currentPage={paginationPage}
        postPerPage={tableContext.postPerPage}
        search={searchBarValue}
        onValueChange={[searchBarValue]}
      />
      <SearchBar handleSerchBar={handleSerchBar} searchValue={searchBarValue} setSearchValue={setSearchBarValue}/>
      <DownloadDataSheet sheetType={tableContext.tableType} />
    </div>
  );
};

export default TableActions;
