export const getHiddenBoxesFieldsID = (hiddenBoxes, boxData) => {
  let idArr = [];

  hiddenBoxes.forEach(el=>{
    if(el.variants?.length) idArr.push(el.box_id);
  })

  let fieldsID = [];
  boxData.forEach((boxObj) => {
    if (idArr.includes(boxObj.box.id)) {
      let boxFieldsID = boxObj.box.box_fields.map((el) => el.id);
      let obj = {box_id: boxObj.box.id, box_fields: boxFieldsID}
      fieldsID = [...fieldsID, obj];
    }
  });

  return fieldsID;
};
