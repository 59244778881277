import React, { useState, useContext, useEffect } from "react";
import { ReformatContext } from "../../../pages/e-commerce/Reformatting";

const ReformatingActionName = ({ data, setData, boxID, validate, sourceType, reloadComponent }) => {
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [noSourceError, setNoSourcerError] = useState(false);

  const handleInput = (e) => {
    if (sourceType) {
      setNoSourcerError(false);

      setReformatData((prevState) => {
        return { ...prevState, action_name: e.target.value, order_name: e.target.value };
      });

      for (let i = 0; i < data.length; i++) {
        if (data[i].box_field == boxID) {
          data[i].order[0].value.content = e.target.value;
          break;
        }
      }

      setData(data);
    } else {
      setNoSourcerError(true);
    }
  };

  useEffect(() => {
    setNoSourcerError(false);
  }, [sourceType]);

  return (
    <>
      <div className="reformatting-action-name">
        <input
          className={validate ? "active" : "active not-validated"}
          placeholder="wpisz..."
          value={reformatData.order_name || ""}
          onChange={handleInput}
        ></input>
        {noSourceError && (
          <span className="error campaign">
            *Aby wpisać nazwę akcji <strong>musisz wybrać plik źródłowy</strong>
          </span>
        )}
      </div>
    </>
  );
};

export default ReformatingActionName;
