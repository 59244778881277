import React, { useState, useContext } from "react";
import faceicon from "../../../../../assets/junior1.svg";
import { ReactComponent as RocketGrey } from "../../../../../assets/rocket-lunch-grey.svg";
import { ReactComponent as SettingsIcon } from "../../../../../assets/settings.svg";
import Row from "./Row";
import settingsIMG from "../../../../../assets/settings-sliders.svg";
import orderServices from "../../../../services/orderServices";
import { UserContext } from "../../../../wrapper/AuthWrap";
import checkPermissions from "../../../../../functions/checkPermissions";
import trimString from "../../../../../functions/trimString";
import PERMISSION_ENUM from "../ENUMS/TEAN_PERMISSIONS";
import Popup from "../../Popup/Popup";
import POPUP_TYPES from "../../Popup/ENUMS/POPUP_TYPES";
import AssigneePopup from "../../Popup/AssigneePopup";
import InfoPopup from "../../Popup/InfoPopup";
import usePopup from "../../../../helper/usePopup";
import Assignee from "../../Popup/AddOrder/Assignee";
import ENUMS from "../ENUMS";

import { ReactComponent as DateIcon } from "../../../../../assets/team/date.svg";

const AssignedProjectBox = ({
  new_order,
  id,
  rocket,
  data,
  initializeNewWorkLog,
  currentWorkProject,
  setAssignePopup,
  setOrderId,
  orderId,
  startProjectRef,
  showRocketStartProject,
  permission,
  projectAdditionalActionsRef,
  setLoading,
  setRealoadComponent,
  showAction,
  hasMarginRight,
  displayName,
  showAccAction
}) => {
  const [popupStatus, setPopupStatus] = useState("correct");
  const [showDetails, setShowDetails] = useState(false);
  const [boxToRemove, setBoxToRemove] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const handleDetailButtons = (e) => {
    let type = e.currentTarget.dataset.buttonType;

    if (type == "capture") {
      initPopup(<Assignee setRealoadComponent={setRealoadComponent} setAssignePopup={setAssignePopup} orderID={id} changeStatus={true}/>)
    } else {
      setLoading(true);
      orderServices.changeOrderStatus(id, "finished").then((res) => {
        // setLoading(false);
        setBoxToRemove(e.target);
        setRealoadComponent(true);
      });
    }
  };

  const handleOrder = (localOrderID) => {
    if(orderId !== localOrderID){
      setOrderId(localOrderID);

      let currentElement;

      if(projectAdditionalActionsRef){
        projectAdditionalActionsRef.current.forEach(el=>{
          if(el) el.classList.remove('details-height')
        })
      }

      if (startProjectRef) {
        startProjectRef.current.forEach((el) => {
          if (el) {
            el.classList.remove("show");
            if (el.dataset.order == localOrderID) {
              currentElement = el.classList.add("show");
            }
          }
        });
      }
    }

  };

  const handleOrderDetails = (e) =>{

    if(projectAdditionalActionsRef){
      let elements = projectAdditionalActionsRef.current
      for(let i = 0; i<elements.length; i++){
        if(elements[i] && elements[i].dataset.id == id){
          elements[i].classList.toggle('details-height');
          break;
        }
      }
    }

    if (startProjectRef) {
      startProjectRef.current.forEach((el) => {
        if (el) {
          if (el.dataset.order == id && !el.classList.contains("show")) {
            el.classList.add("show");
          }else{
            el.classList.remove("show");
          }
        }
      });
    }
  }

  const displayUserName = () => {
    switch (displayName) {
      case ENUMS.ROLE.ACCM:
        return data.managed_by;
      case ENUMS.ROLE.CLIENT:
        return data.created_by;
      case ENUMS.ROLE.GRAPHIC:
        return data.assigned_to;
    }
  };

  return (
    <>
      <div
        className="assigned-project-box-container-around"
        data-order={id}
        style={{ marginRight: hasMarginRight ? "10px" : "0" }}
        ref={(el) => {
          startProjectRef.current.push(el);
        }}
        {...(permission == PERMISSION_ENUM.ACCM
          ? {
              onClick: () => {
                handleOrder(id);
              },
            }
          : {})}
      >
        <div className="assigned-project-box-container" onClick={(e) => handleOrder(data.id, e)}>
          <div className="top-section">
            <div className="status-wrapper">
              <div className="status-color" style={{ background: data?.status_label_and_color[1] || `#ffb800` }}></div>
              <p className="status" style={{ color: data?.status_label_and_color[1] || `#ffb800` }}>
                {data?.status_label_and_color[0] || ""}
              </p>
            </div>
            <div className="date-wrapper">
              <DateIcon />
              <p className="status">{data.deadline?.content || `---`}</p>
            </div>
          </div>
          <div className="bottom-section">
            <div className="order-data">
              <p className="user-name">{displayUserName() || `---`}</p>
              <p className="action-name">{trimString(data.action_name?.content, 17) || `id: ${data.id}`}</p>
              <p>Formaty: {data.formats_count}</p>
              <div className="date">
                <p>Propozycja do:</p>
                {data.proposal_deadline && data.proposal_deadline.content ? (
                  <p dangerouslySetInnerHTML={{ __html: data.proposal_deadline.content }} />
                ) : (
                  <p>---</p>
                )}
              </div>
            </div>
            {showAction && (
              <div className="action">
                <div className="start-project-worklog" onClick={() => initializeNewWorkLog(data.id)} data-order={data.id}>
                  Rozpocznij
                </div>
              </div>
            )}
            {/* {checkPermissions(user, ["ecommerce_admin", "ecommerce_orders_create"]) && showAccAction && (
              <div className="icon settings-icon" onClick={handleOrderDetails} style={showDetails ? { background: "#FFD600" } : {}} data-id={id}>
                <SettingsIcon/>
              </div>
            )} */}
          </div>
        </div>
        {/* <div
          className={`details`}
          ref={(el) => {
            projectAdditionalActionsRef.current.push(el);
          }}
          data-id={id}
        >
          <div className="details-content">
            <Row paddingTop={"4px"} paddingRight={"8px"}>
              <div className="button-wrapper" data-button-type={"capture"} onClick={handleDetailButtons}>
                <div className="icon capture"></div>
                <div className="extended-text">
                  <span>przekaż do</span>
                </div>
              </div>
              {checkPermissions(user, ["ecommerce_admin", "ecommerce_orders_create"]) ? (
                <div className="button-wrapper" data-button-type={"archive"} onClick={handleDetailButtons}>
                  <div className="icon archive"></div>
                  <div className="extended-text">
                    <span>zamknij i zarchiwizuj</span>
                  </div>
                </div>
              ) : null}
              <div className={`icon close-btn`} onClick={handleOrderDetails}></div>
            </Row>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AssignedProjectBox;
