import React, { useContext, useState } from "react";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions, {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import ChangeDeadlinePopup from "../../../Popup/PopupComponents/ChangeDeadlinePopup";
import usePopup from "../../../../../helper/usePopup";
import orderServices from "../../../../../services/orderServices";
import duplicateOrder from "./functions/duplicateOrder";
import { useNavigate } from "react-router-dom";
import Duplicate from "../../../Popup/AddOrder/Duplicate";

const DuplicateButton = ({ status, orderID, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { initOrderPopup, closePopup, initPopup } = usePopup();
  const navigate = useNavigate();

  const handleAction = (orderName, calenderDeadline) => {
    orderServices
    .cloneOrder(orderID, calenderDeadline, orderName)
    .then((res) => {
      duplicateOrder(status, res.id, navigate);
      closePopup();
    })
    .catch((error) => {
      console.error(error);
      closePopup();
    });
  }
  return (
    <>
      {shouldDisplayButton('duplicate', rowData?.order_status,user,) !== 'Brak' && (
        <>
          <button className={`draft-duplicate ${shouldDisplayButton('duplicate', rowData?.order_status,user,) === 'Szary' ? 'casual' : null}`} onClick={()=> initPopup(<Duplicate status={status} orderID={orderID} contentType={'clone'} handleAction={handleAction}/>)}>
            Powiel
          </button>
        </>
      )}
    </>
  );
};

export default DuplicateButton;
