import orderServices from "../../../../services/orderServices";

export const ENUMS = {
  OBJECT_PROPERTY: {
    ASSIGNED_TO: "assigned_to",
    STATUS: "status",
    MANAGED_BY: "managed_by",
  },
  STATUS: {
    DELETED: "deleted",
    NEW: "new",
    DRAFT: "draft",
  },
  COLUMNS: {
    assignedToPerson: "assignedToPerson",
    newOrderBox: "newOrderBox",
    MyProjectsBox: "MyProjectsBox",
  },
};

export const segregateOrdersColumns = async (columns, setColumns, selectedPerm, setActions) => {
  // nowe zlecenia od klienta
  let newOrders = [];
  await orderServices.getOrderList(ENUMS.STATUS.NEW).then((r) => {
    const orders = r;
    newOrders = orders.filter((order) => {
      if (!order[ENUMS.OBJECT_PROPERTY.MANAGED_BY]) {
        return order;
      }
    });
  });

  //moje projekty
  let myProjects = [];
  await orderServices.getOrderAssignedToUser(selectedPerm).then((r) => {
    const orders = r;
    myProjects = orders;
  });

  setColumns({
    ...columns,
    [ENUMS.COLUMNS.newOrderBox]: {
      ...[ENUMS.COLUMNS.newOrderBox],
      items: newOrders,
    },
    [ENUMS.COLUMNS.MyProjectsBox]: {
      ...[ENUMS.COLUMNS.MyProjectsBox],
      items: myProjects,
    },
  });

  setActions.forEach(setAction => setAction(false))
};
