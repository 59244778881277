import React, {useContext, useEffect, useRef, useState} from "react";
import banner from "../../../../../assets/banner3.png";
import orderServices from "../../../../services/orderServices";
import {ReformatContext} from "../../../../pages/e-commerce/Reformatting";
import SOURCE_FILE_TYPE from "../enums/SOURCE_FILE_TYPES";
import {useSearchParams} from "react-router-dom";
import Dropdown from "../../../globalElements/Dropdown/Dropdown";
import variantsServices from "../../../../services/variantsServices";

import { ReactComponent as DropdownIcon } from "../../../../../assets/dropdownicon.svg";
const RadioInput = ({setChosenOrder, setChosenSource, setSourceType, reloadComponent}) => {
    const fourthConnectorRef = useRef();
    const thirdConnectorRef = useRef();
    const secondConnectorRef = useRef();
    const firstConnectorRef = useRef();
    const [showErrorPopup, setErrorPopup] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchOrderName, setSearchOrderName] = useState('')

    const dropdownInputRef = useRef();
    const [orderList, setOrderList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [file, setFile] = useState(null);
    const {reformatData, setReformatData} = useContext(ReformatContext);
    const [renderOnce, setRenderOnce] = useState(true);
    const [radioActive, setRadioActive] = useState(false);

    const removeCheckStatusFromInputs = () => {
        const refArray = [firstConnectorRef];
        setRadioActive(false);
        setSearchOrderName('');
        refArray.forEach((el) => {
            el.current.parentNode.parentNode.classList.remove("active");
            el.current.checked = false;
        });
    };

    const handleSourceFileInputs = (e) => {
        if (e.target.dataset.action !== 'additional-info') removeCheckStatusFromInputs();

        e.target.parentNode.parentNode.classList.toggle("active");
        setRadioActive(true);
        if (e.target.value == "upload" && e.target.checked) {
            setChosenSource({pk: null, image: null});
        }

        if (e.target.value == "dropdownhandle") {
            firstConnectorRef.current.classList.toggle("active");
        }

        if (e.target.value != SOURCE_FILE_TYPE.UNSTANDARDIZED) setSourceType(e.target.value);
    };

    useEffect(() => {
        removeCheckStatusFromInputs();

        setFile(null);
    }, [reloadComponent]);
    useEffect(() => {
        initOrderDropdownData("finished", '', 10);
    }, []);
    const handleDropdownElement = (e) => {
        orderServices
            .getOrderImages(e.target.value)
            .then((res) => {
                if (res[0]) setChosenSource({pk: res[0].pk, image: res[0].image});
            })
            .catch((error) => {
                console.error(error);
                setErrorPopup(true);
            });
        setChosenSource(banner);
        setShowDropdown(false)

        if(searchParams.get('variant')){
            setReformatData({...reformatData, order_id: e.target.value, order_name: e.target.dataset.nameContent});
        }else{
            variantsServices.getVariants(e.target.value).then(res=>{
                setReformatData({...reformatData, order_id: e.target.value, order_name: e.target.dataset.nameContent, variant_id: res[0].id}); //TODO: change this solution E-312
            })
        }
        
        setChosenOrder({order_id: e.target.value, order_name: e.target.dataset.nameContent});
        setSearchOrderName(e.target.dataset.nameContent);
    };

    const initCheckOnOrderEdit = () => {
        if (searchParams.get("edit_order") || searchParams.get("create_format")) {
            if (reformatData.own_file) {
                thirdConnectorRef.current.parentNode.parentNode.classList.add("active");
                thirdConnectorRef.current.checked = true;
                setFile(reformatData.own_file);
                setRenderOnce(false);
            } else if (reformatData.external_url) {
                secondConnectorRef.current.parentNode.parentNode.classList.add("active");
                secondConnectorRef.current.checked = true;
                setRenderOnce(false);
            } else if (reformatData.order_id) {
                firstConnectorRef.current.parentNode.parentNode.classList.add("active");
                setRadioActive(true);
                setRenderOnce(false);
            }
        }
    };

    const initOrderDropdownData = (status, search = '', count = 10) => {
        orderServices.getOrdersNames(status, search, count).then((res) => {
            let oderList = res;

            let mapedOrders = oderList.map((el) => {
                return {value: el.id, displayName: el.action_name ? el.action_name : "Brak nazwy"};
            });

            setOrderList(mapedOrders);
        });
    }

    const handleDropdown = (search) => {
        setShowDropdown(true);
        initOrderDropdownData("finished", search);
    };

    useEffect(() => {
        if (renderOnce) {
            initCheckOnOrderEdit();
        }
    }, [reformatData]);

    const handleAdditionalInfo = (e) => {
        setReformatData((prevState) => {
            return {...prevState, additional_info: e.target.value};
        });
    };

    const handleOrderInput = (e) => {
        setSearchOrderName(e.target.value)
        handleDropdown(e.target.value);
    }

    return (
        <div className="radio-input">
            <div className={"input-group text-input dropdown"}>
                <label className="checkbox ">
                    <input type="checkbox" value={SOURCE_FILE_TYPE.ORDER_ID ?? ""} checked={radioActive}
                           onClick={handleSourceFileInputs}
                           ref={firstConnectorRef}></input>
                    <span className="checkmark"></span>
                    wybierz z listy projektów już zaakceptowanych
                </label>
                <input className="text-input" value={searchOrderName || ''} placeholder="promocje dot wybranych prod."
                       onChange={handleOrderInput} ref={dropdownInputRef}></input>
                <div className={"conector"}></div>
                {showDropdown &&
                    <Dropdown
                        data={orderList}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        positionToElementRef={dropdownInputRef}
                        handleListElement={handleDropdownElement}
                    />
                }
                <DropdownIcon className='arrow-down' onClick={(e) => {
                    if (radioActive) {
                        e.stopPropagation();
                        setShowDropdown(prevState => !prevState);
                    }
                }}/>
            </div>
            <span className="info">*możesz też wpisać nazwę interesującego Cię zlecenia i wybrać je z rozwijanej listy powyżej.</span>
        </div>
    );
};

export default RadioInput;
