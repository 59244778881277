import React, {useContext, useState} from 'react';
import {ReactComponent as Assignee} from "../../../../../assets/popups/assign.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AddCounterCalenderDeadline from "../additionalElements/AddCounterCalenderDeadline";
import SelectBox from "../../Team/globalelements/SelectBox";
import ENUMS from "../../Team/ENUMS";
import orderServices from "../../../../services/orderServices";
import {MainAppContext} from "../../../../MainAppComponent";
const ChangeAssignee = ({setAssignePopup, orderID, setRealoadComponent, assigneToDtp, changeStatus}) => {
    const navigate = useNavigate()
    const {closeOrderPopup} = usePopup();
    const [selectBoxData, setSelectBoxData] = useState({ role: "", person: "" });
    const [activeButtonLoader, setActiveButtonLoader] = useState(false);
    const [error, setError] = useState(false);
    const { usersList, setUsersList } = useContext(MainAppContext);
    const handleSecondButton = () => {
        setAssignePopup({ showPopup: false, order_id: null });
        closeOrderPopup();
    }
    const handleFirstButton = (e) => {
        if(!selectBoxData.role || !selectBoxData.person){
            setError(true);
            return;
        }
        if (setRealoadComponent) setRealoadComponent(true);
        setActiveButtonLoader(true);

        if (assigneToDtp || selectBoxData.role == ENUMS.ROLE.KREACJA || selectBoxData.role == ENUMS.ROLE.DTP) {
            orderServices
                .assignProjectToDtpUser(orderID, selectBoxData.id, changeStatus)
                .then((res) => {
                    setActiveButtonLoader(false);
                })
                .catch((error) => {
                    console.log("ASSIGNE PROJECT TO DTP ERROR - assignePopup");
                    console.error(error);
                    setActiveButtonLoader(false);
                });
        } else if (selectBoxData.role == ENUMS.ROLE.CLIENT) {
            orderServices
                .changeOrderDetail(orderID, { client_managed_by: selectBoxData.id })
                .then((res) => {
                    setActiveButtonLoader(false);
                })
                .catch((error) => {
                    console.log("ASSIGNE PROJECT TO CURRENT CLIENT USER ERROR - assignePopup");
                    console.error(error);
                    setActiveButtonLoader(false);
                });
        } else {
            orderServices
                .assignProjectToCurrentAccUser(orderID, selectBoxData.id, changeStatus)
                .then((res) => {
                    setActiveButtonLoader(false);
                })
                .catch((error) => {
                    console.log("ASSIGNE PROJECT TO CURRENT ACC USER ERROR - assignePopup");
                    console.error(error);
                    setActiveButtonLoader(false);
                });
        }
        setAssignePopup({ showPopup: false, order_id: null });
        closeOrderPopup();
    };
    return (
        <div className='assignee-popup'>
            <div className="upper-part-popup">
                <Assignee/>
                <h3>Zmiana opiekuna <br/> zlecenia.</h3>
            </div>
            <div className="middle-part-popup">
                <SelectBox selectBoxData={selectBoxData} setSelectBoxData={setSelectBoxData} persons={usersList} error={error} setError={setError}/>
            </div>
            <div className='buttons'>
                <button className="button cancel" onClick={handleSecondButton}>Anuluj</button>
                <button className="button accept" onClick={handleFirstButton}>Zmień</button>
            </div>
        </div>
    );
}

export default ChangeAssignee;
