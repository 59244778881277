/**
 * getFileBaseName()- method to get basname from file direction urdl
 * 
 * example usage: DownloadButton.js, TableActions.js
 * 
 * @param {String} file_dir 
 * @returns string (basename)
 */

export const getFileBaseName = (file_dir) => {
  let urlArr = file_dir.split("/");
  return urlArr[urlArr.length - 1];
};
