import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release:process.env.REACT_APP_COMMIT_ID,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    tracesSampleRate: 0.1,
    //enableTracing
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
