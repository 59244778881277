import React, { useState, useContext, useEffect, useRef } from "react";
import icon from "../../../../../assets/plus.png";
import AddCounterCalenderDeadline from "../additionalElements/AddCounterCalenderDeadline";
import usePopup from "../../../../helper/usePopup";
import { ReactComponent as Deadline } from "../../../../../assets/deadline_icon.svg";
import { ReactComponent as Action } from "../../../../../assets/action_name.svg";
import { ReactComponent as ActionRequierd } from "../../../../../assets/signature.svg";
import Loader from "../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../globalElements/Loader/LOADER_TYPE";

const contentMap = {
  format: {
    text_small: "FORMATOWANIE",
    text_big: "Aby kontynuować <br/> uzupełnij deadline <br/> zlecenia",
    button_first_text: "Formatuj",
    button_second_text: "Anuluj",
    input_placeholder: "nazwA akcji_reformat",
    deadline_placeholder: "wybierz deadline na reformat zlecenia",
    text_last: "*celem rozróżnienia zleceń do nazwy automatycznie dodano frazę “_reformat”."
  },
  clone: {
    text_small: "na pewno chcesz",
    text_big: "powielić <br/> aktualne <br/> zlecenie",
    button_first_text: "Powiel",
    button_second_text: "Anuluj",
    input_placeholder: "wprowadź nazwę nowego zlecenia",
    deadline_placeholder: "wybierz deadline",
    text_last: ""
  },
  restore: {
    text_small: "PRZYWRÓĆ PROJEKT",
    text_big: "Aby kontynuować <br/> uzupełnij deadline <br/> zlecenia",
    button_first_text: "Przywróć",
    button_second_text: "Anuluj",
    input_placeholder: "",
    deadline_placeholder: "wybierz NOWY deadline",
    text_last: ""
  },
};

const suffix = "_reformat"

const ChangeDeadlinePopup = ({ contentType, handleAction}) => {
  const { closePopup } = usePopup();

  const [calenderDeadline, setCalenderDeadline] = useState("");
  const [orderName, setOrderName] = useState("");
  const [error, setError] = useState(false);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);

  const isValidated = () =>{
    if(contentType == 'format') return calenderDeadline && orderName;

    return calenderDeadline;
  }
  const handleSaveButton = () => {
    if (isValidated()) {
      if (error) setError(false);

      if(['restore'].includes(contentType)){
        setActiveButtonLoader(true)
        handleAction(orderName, calenderDeadline, setActiveButtonLoader);
      }else if(['format'].includes(contentType)){
        handleAction(orderName + suffix, calenderDeadline);
      }else{
        handleAction(orderName, calenderDeadline);
      }

    } else {
      setError(true);
    }
  };

  const handleCancelButton = () => {
    closePopup();
  };

  const handleOrderName = (e) => {
    setOrderName(e.target.value);
  };

  return (
    <>
      <div className={`new-order-popup neutral`} onClick={(e) => e.stopPropagation()}>
        <div className={`popup-contents positive`}>
          <div className="popup-upper-part">
            <img className="icon-section" src={icon}></img>
            <span className={`text-small neutral`}>{contentMap[contentType].text_small}</span>
            <span className={`text-big neutral`} dangerouslySetInnerHTML={{ __html: contentMap[contentType].text_big }}></span>
          </div>
          <div className="popup-center-part">
            {contentMap[contentType].input_placeholder && (
              <div
                className="input-container"
                style={error ? { boxShadow: "0px 0px 10px #ce5a69" } : { boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.13)" }}
              >
                <div className="icon-container">
                  {contentType == 'format' ? <ActionRequierd /> : <Action />}
                </div>
                <div className={contentType == 'format' ? "input-group with-suffix" : "input-group"} style={{ display: contentType == 'format' ? "flex" : "block", width: "100%", maxHeight: 25 }}>
                  <input
                    className={"input"}
                    value={orderName}
                    onChange={handleOrderName}
                    placeholder={contentMap[contentType].input_placeholder}
                  ></input>
                </div>
              </div>
            )}

            <div
              className="input-container"
              style={error ? { boxShadow: "0px 0px 10px #ce5a69" } : { boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.13)" }}
            >
              <div className="icon-container">
                <Deadline />
              </div>
              <div className="input-group" style={{ display: "block", width: "100%", maxHeight: 25 }}>
                <AddCounterCalenderDeadline
                  setCalenderDeadline={setCalenderDeadline}
                  deadline
                  placeholder={contentMap[contentType].deadline_placeholder}
                />
                {error && <span className="error">*Musisz wskazać nowy deadline</span>}
              </div>
            </div>
          </div>
          <div className="popup-lower-part">
            <div className="popup-buttons">
              <button className={`popup-button first positive`} onClick={handleSaveButton}>
                {activeButtonLoader ? (
                  <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} />
                ) : (
                  contentMap[contentType].button_first_text
                )}
              </button>
              <button className={`popup-button second neutral`} onClick={handleCancelButton}>
                {contentMap[contentType].button_second_text}
              </button>
            </div>
            <div className="text-last-container">
              <span className={`text-last positive`} dangerouslySetInnerHTML={{ __html: contentMap[contentType].text_last }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeDeadlinePopup;
