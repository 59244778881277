
import {MainAppContext} from "../MainAppComponent";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";

const usePopup = () => {
    const { popup, setPopup, intervalId, setIntervalId} = useContext(MainAppContext);
    const navigate = useNavigate();
    const initPopup = (component) => {
        if (popup.component !== component) {
            setPopup({ ...popup, component: component, display: true });
        }
    };
    const initTimeoutPopup = (component, time) => {
        if (popup.component !== component) {
            setPopup({ ...popup, component: component, display: true });
            const timeoutId = setTimeout(() =>{
                closePopup();
                navigate('/')
                window.location.reload();
            },time)
            setIntervalId(timeoutId);
        }
    }
    const initCustomBackgroundPopup = (component) => {
        if (popup.component !== component) {
            setPopup({ state:'custom', component: component, display: true });
        }
    }
    const popUpState = popup.state;
    const clearTimeoutManually = () => {
        if (intervalId) {
            clearTimeout(intervalId);
            setIntervalId(null);
        }
    };
    const setPopUpState = (state) => {
        setPopup({ ...popup, state: state });
    };

    const openPopup = () => {
        setPopup({ ...popup, display: true, component:undefined });
    };

    const closePopup = () => {
        setPopup({ ...popup,component: undefined, display: false, state:null });
    };

    return { initPopup, openPopup, closePopup, popup, popUpState, setPopUpState, clearTimeoutManually, setIntervalId, initTimeoutPopup, initCustomBackgroundPopup };
};

export default usePopup;
