import addIcon from "../../../../assets/nowe_dodaj_nowe_zlecenie.svg";
import sendedIcon from "../../../../assets/nowe_przeslane_do_agencji.svg";
import inProgressIcon from "../../../../assets/nowe_w_realizacji.svg";
import draftIcon from "../../../../assets/nowe_szkice.svg";
import reformatIcon from "../../../../assets/nowe_przeformatowania.svg";
import archiveIcon from "../../../../assets/nowe_archiwum.svg";
import teamIcon from "../../../../assets/nowe_team.svg";
import notificationIcon from "../../../../assets/powiadomienia.svg";
import raportIcon from "../../../../assets/calendar (1).svg"
import specificationIcon from "../../../../assets/specification.svg"


const dashboardBoxes = [
  {
    title: "Dodaj nowe zlecenie",
    subtitle: "w łatwy i szybki sposób dodaj nowe zlecenie",
    link: "/addneworder",
    icon: addIcon,
    permission_name: "ADD_NEW_ORDER_PAGE",
  },
  {
    title: "Przesłane do agencji",
    subtitle: "lista zleceń, które zostały przygotowane przez klienta i przesłane do agencji, oczekują na przyjęcie przez client service",
    link: "/sendedprojects",
    icon: sendedIcon,
    permission_name: null,
  },
  {
    title: "W realizacji",
    subtitle: "lista tematów, które są przygotowywane przez agencję - przed pierwszą korektą",
    link: "/inprogressprojects",
    icon: inProgressIcon,
    permission_name: null,
  },
  {
    title: "szkice",
    subtitle: "sekcja zleceń przeniesionych do oczekujących z zakładki dodaj nowe zlecenie",
    link: "/draft",
    icon: draftIcon,
    permission_name: null,
  },
  {
    title: "Przeformatowania",
    subtitle: "dodaj w szybki sposób zlecenie na przeformatowanie",
    link: "/reformatting",
    icon: reformatIcon,
    permission_name: "REFORMAT_PAGE",
  },
  {
    title: "ARCHIWUM",
    subtitle: "sekcja zakończonych projektów",
    link: "/archive",
    icon: archiveIcon,
    permission_name: null,
  },
  {
    title: "TEAM",
    subtitle: "zarządzaj projektami",
    link: "/team",
    icon: teamIcon,
    permission_name: null,
  },
  {
    title: "powiadomienia",
    subtitle: "zarządzaj projektami",
    link: "/notifications",
    icon: notificationIcon,
    permission_name: null,
  },
  {
    title: "Raportowanie",
    subtitle: "podsumowanie czasowe zleceń",
    link: "/raports",
    icon: raportIcon,
    permission_name: "GET_REPORTS_PAGE",
  },
  {
    title: "specyfikacja e-commerce",
    subtitle: "historia zmian i inne informacje techniczne na temat modułu",
    link: "/specification",
    icon: specificationIcon,
    permission_name: null,
  },
];

export default { dashboardBoxes };
