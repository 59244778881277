import React, { useState, useRef, useContext, useEffect } from "react";
import { MainAppContext } from "../../../MainAppComponent";
import createCampaign from "../../../services/createCampaign";
import { ReformatContext } from "../../../pages/e-commerce/Reformatting";
import showCampaignDropdown from "../../../../functions/campaignDropdownFunctions/showCampaignDropdown";
import CampaignDropdownContainer from "../../campaignDropdownElements/CampaignDropdownContainer";
import InfoPopup from "../../e-commerce/Popup/InfoPopup";
import usePopup from "../../../helper/usePopup";
import ErrorLoadingData from "../../e-commerce/Popup/AddOrder/ErrorLoadingData";

const ReformatingCampaignDropdown = ({ data, setData, boxID, value, validate, sourceType,reloadComponent }) => {
  let exampleData = ["test1", "test2", "test3", "test3", "test3", "test3", "test3", "test3", "test3", "test3"];

  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [campaignName, setCampaignName] = useState(reformatData.campaign_name || "");
  const [noSourceError, setNoSourcerError] = useState(false);
  const [displayDropdownData, setDisplayDropdownData] = useState(exampleData);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const [isValidated, setIsValidated] = useState(true)
  const [showDopdown, setShowDropdown] = useState(false);
  const { initPopup } = usePopup();
  const addNewElRef = useRef();
  const dropdownRef = useRef();
  const newElementRef = useRef();
  const inputRef = useRef();
  const dropDownArea = useRef();

  useEffect(() => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].box_field == boxID) {
        setCampaignName(data[i].order[0].custom_inputs_value);

        setReformatData((prevState) => {
          return { ...prevState, campaign_name: data[i].order[0].value.content };
        });

        break;
      }
    }
  }, [data]);
  useEffect(()=>{
    setCampaignName('');
    inputRef.current.value = '';
  },[reloadComponent])
  useEffect(() => {
    let newDisplayValueArr = [];
    value.forEach((el) => {
      let obj = { name: el.default_name, id: el.id };
      newDisplayValueArr.push(obj);
    });

    setDisplayDropdownData(newDisplayValueArr);
  }, []);

  const handleInput = (e) => {
    setCampaignName(e.target.value);

    setReformatData((prevState) => {
      return { ...prevState, campaign_name: e.target.value };
    });
  };

  const handleDropdown = (e) => {
    let element = e.target;
    showCampaignDropdown(element, dropdownRef, setShadowActive);
  };

  const handleAddButton = (e) => {
    let campaignName = inputRef.current.value;

    if (campaignName) {
      setCampaignName(campaignName);
      if (sourceType) {
        setNoSourcerError(false);
        createCampaign
          .createNewCampaign(boxID, campaignName)
          .then((res) => {
            setDisplayDropdownData([{ name: res.default_name, id: res.id }, ...displayDropdownData]);

            setReformatData((prevState) => {
              return { ...prevState, campaign_name: res.id };
            });
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'campaign_dropdown'}/>)
          });
        dropDownArea.current.classList.remove("not-validated");
      } else {
        setNoSourcerError(true);
        setCampaignName("");
      }
    } else {
      dropDownArea.current.classList.add("not-validated");
      if (sourceType) {
        setNoSourcerError(false);
      } else {
        setNoSourcerError(true);
      }
    }
  };

  const handleListElement = (e) => {
    if (sourceType) {
      let campaign_id = e.target.value
      setNoSourcerError(false);
      setShowDropdown(false);
      setShadowActive(false);
      setShadowActive(false);
      setCampaignName(e.target.dataset.nameContent);
      setReformatData((prevState) => {
        return { ...prevState, campaign_name: campaign_id};
      });

      inputRef.current.parentNode.classList.remove("visible");
      e.target.parentNode.classList.remove("visible");
      dropdownRef.current.classList.remove("visible");
      dropDownArea.current.classList.remove("not-validated");

      for(let i=0; i<data.length; i++){
        if(data[i].box_field == boxID){
          data[i].order[0].value.content = campaign_id;
          data[i].order[0].custom_inputs_value = e.target.dataset.nameContent
          break;
        }
      }

      setData(data);
    } else {
      setNoSourcerError(true);
    }
  };

  useEffect(() => {
    setNoSourcerError(false);
  }, [sourceType]);

  return (
    <CampaignDropdownContainer
      dropDownArea={dropDownArea}
      validate={validate}
      reloadComponent={reloadComponent}
      campaignValue={campaignName || "" || reformatData.campaign_name}
      handleInput={handleInput}
      handleDropdown={handleDropdown}
      inputRef={inputRef}
      dropdownRef={dropdownRef}
      handleAddButton={handleAddButton}
      noSourceError={noSourceError}
      displayDropdownData={displayDropdownData}
      handleListElement={handleListElement}
      isShadowActive={isShadowActive}
      showDopdown={showDopdown}
      setShowDropdown={setShowDropdown}
    />
  );
};

export default ReformatingCampaignDropdown;
