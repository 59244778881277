const checkIfLockIsOlder = (time) =>{
  if(time){
    let currentDate = new Date().getTime();
    let lockTime = new Date(time.end_time).getTime();

    return currentDate > lockTime
  }

  return false
}

export default checkIfLockIsOlder