import { handleResponse } from "../helper/handle-response.js";
import authHeader from "../helper/auth-header.js";
import axios from "axios";

const createImageSlot = async (order_id, variant_id, size, currentRetry=0) => {
  const auth = authHeader();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body: JSON.stringify({variant: variant_id, order: order_id }),
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images`,requestOptions);

  if ([500, 502].includes(response.status) && currentRetry < 3) {
    return createImageSlot(order_id, variant_id, size, currentRetry + 1);
  } else {
    return await handleResponse(response, requestOptions).then((r) => r.json());
  }
};

const getImages = async (order_id, variant_id, isReformat) => {
  let endpoint;
  if (variant_id) {
    endpoint = `${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images?variant_id=${variant_id}`;
  } else {
    endpoint = `${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images`;
  }
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(endpoint, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const changeImageStatus = async (image_pk, image_status) => {
  const auth = authHeader();
  let body = JSON.stringify({ status: image_status });

  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body,
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${image_pk}/sizes/images`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const removeImage = async (order_id, image_id) => {
  const auth = authHeader();

  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images/destroy/${image_id ? image_id : "all"}`, requestOptions).then((r) => {

    return handleResponse(r, requestOptions).then((r) => {
      return r
    });
  });
};

const removeImageFromVariant = async (variant_id, order_id) => {
  const auth = authHeader();

  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: auth },
  };

  //images/destroy/<int:variant_id>/all
  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images/destroy/${variant_id}/all`, requestOptions).then((r) => {

    return handleResponse(r, requestOptions).then((r) => {
      return r
    });
  });
};

const changeAllImagesStatusToAccepted = async (order_id) => {
  const auth = authHeader();
  let body = JSON.stringify({ status: "accepted" });
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json", Authorization: auth },
    body,
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images/all`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const updateImageSlot = async (inputFile, slot_pk, currentRetry = 0) => {
  const auth = authHeader();
  const data = new FormData();
  data.append("file", inputFile);

  const requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: auth,
      "Content-Disposition": `attachment; filename="filename.jpg"`,
    },
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders/images/${slot_pk}/upload`, data, requestOptions);

  if ([500, 502].includes(response.status) && currentRetry < 3) {
    updateImageSlot(inputFile, slot_pk, currentRetry + 1);
  }else if(![500, 502].includes(response.status)){
    return response.data;
  }
};

export default {
  createImageSlot,
  getImages,
  changeImageStatus,
  removeImage,
  changeAllImagesStatusToAccepted,
  updateImageSlot,
  removeImageFromVariant
};
