import React, { useContext, useState } from "react";
import PERMISSIONS_ENUMS from "../../../../../../globalEnums/PERMISSIONS_ENUMS";
import checkPermissions, {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import downloadFileService from "../../../../../services/downloadFileService";
import { getFileBaseName } from "../../../../../../functions/getFileBaseName";
import handleDownload from "../../../../../../functions/handleDownload";
import BOX_INFO_ENUMS from "../../../Popup/ENUMS/BOX_INFO_ENUMS";
import Popup from "../../../Popup/Popup";
import POPUP_TYPES from "../../../Popup/ENUMS/POPUP_TYPES";
import usePopup from "../../../../../helper/usePopup";
import InfoPopup from "../../../Popup/InfoPopup";
import CounterPopup from "../../../Popup/CounterPopup";
import NewInfoPopup from "../../../Popup/AddOrder/NewInfoPopup";

const DownloadButton = ({ status, orderID, navigate, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { initPopup } = usePopup();
  const handleDownloadButton = () => {
    downloadFileService.downloadFile(orderID).then((res) => {
      if (res.file) {
        let basename = getFileBaseName(res.file);
        handleDownload(basename, res.file);
      } else {
        initPopup(<NewInfoPopup type={'negative'} info={'no_files_to_download'}/>)
      }
    });
  };

  return (
    <>
      {shouldDisplayButton('download', rowData?.order_status,user,) !== 'Brak' && (
        <>
          <button className={`download ${shouldDisplayButton('download', rowData?.order_status,user,) === 'Szary' ? 'casual' : null}`} onClick={handleDownloadButton}>
            Pobierz
          </button>
        </>
      )}
    </>
  );
};

export default DownloadButton;
