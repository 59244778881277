import authHeader from "../helper/auth-header";
import { handleResponse } from "../helper/handle-response";
import getApiKey from "../helper/getApiKey";
import { getHiddenBoxesFieldsID } from "../../functions/boxFieldsFunctions/getHiddenBoxesFieldsID";
import checkIfHanfleBoxFieldVariant from "../../functions/boxFieldsFunctions/checkIfHanfleBoxFieldVariant";

export const sendBoxFIeldData = (boxData, headerBoxesData, boxFieldsData, boxSettings, method_type) => {
  // let body = JSON.stringify([...headerBoxesData, ...boxFieldsData]);
  let body = JSON.stringify(clearBoxFieldsData([...headerBoxesData, ...boxFieldsData], boxSettings, boxData));
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: method_type,
    headers: { "Content-Type": "application/json", Authorization: auth, api_key: api_key },
    body: body,
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/variant/variant-box-field-value/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

//Temporary method for saving inactive boxes
const clearBoxFieldsData = (body, boxSettings, boxData) => {
  let result = [];
  let hiddenBoxes = [];

  boxSettings.hidden_boxes?.forEach(el=>{
    if(el.variants?.length) hiddenBoxes.push(el)
  })

  let hiddenBoxFieldsGroup = getHiddenBoxesFieldsID(hiddenBoxes, boxData)

  result = body.map((el) => {
    if(el.variants){
      let newClearArr = el.variants.map((obj_value) => {
        if(!checkIfHanfleBoxFieldVariant(obj_value.variant_id, el.box_field, hiddenBoxFieldsGroup, boxSettings)){
          return { ...obj_value, value: {}, is_hidden: true, image: null, custom_inputs_value: null };
        }else{
          return obj_value;
        }
        
      });
      return { ...el, variants: newClearArr };
    }else{
      return el;
    }
      
  });

  return result;
};
