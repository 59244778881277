import React from "react";
import plik_zrodlowy from "../../../../../assets/plik_zrodlowy.svg";
import format_logo from "../../../../../assets/formaty.svg";
import view_logo from "../../../../../assets/view_logo.svg";
import unstandard_logo from "../../../../../assets/formaty recznie.svg";

let headerText = {
  formats: { title: "Format", subtitle: "— wybierz interesujące Cię przeformatowanie z pliku źródłowego zadanego powyżej", logo: format_logo },
  source_file: { title: "Plik źródłowy", subtitle: "— wybierz layout z którego ma zostać przygotowane przeformatowanie, ew. podaj link do pliku", logo: plik_zrodlowy},
  view: { title: "Podgląd", subtitle: "—  wybranego pliku źródłowego", logo: view_logo },
  unstandard: { title: "Format niestandardowe", subtitle: "—  wpisz w polu poniżej", logo: unstandard_logo },
};

const SectionHeader = ({ headerType }) => {
  return (
    <div className="header">
      <div className="logo">
        <figure>
          <img src={headerText[headerType].logo}></img>
        </figure>
      </div>
      <div className="title">
        <h3>{headerText[headerType].title}</h3>
        <span>{headerText[headerType].subtitle}</span>
      </div>
    </div>
  );
};

export default SectionHeader;
