import getCurrentDate from "../getCurrentDate";
import checkIfDateIsLessThan from "./checkIfDateIsLessThan";

/**
 * setCorrectDate() - method that set chosen date by user
 * 
 * @param {*} dateElement - node li element from calender that represents chosen date
 * @param {*} setError - state that handle if there is some error
 * @param {*} setChosenDate - state that sets chosen date
 * @param {Boolean} allowChoseAnyDate - decide if compare chosen date with current date and dont allow chose older date then current
 * @param {Array} calenderElements - list of all calender date elements
 */

const setCorrectDate = (dateElement, setError, setChosenDate, calenderElements, allowChoseAnyDate, autoDateSet) => {
  let currentDate = getCurrentDate();
  let chosenDate = dateElement.dataset.value;

  calenderElements.forEach((el) => {
    el.classList.remove("chosen");
  });

  dateElement.classList.add("chosen");
  if (!allowChoseAnyDate && checkIfDateIsLessThan(chosenDate, currentDate)) {
    setError(true);
    if(autoDateSet){
      setChosenDate(currentDate);
      calenderElements.forEach((el) => {
        if (el.dataset.value == currentDate) {
          el.classList.add("chosen");
        } else {
          el.classList.remove("chosen");
        }
      });
    }
  } else {
    setChosenDate(chosenDate);
  }
};

export default setCorrectDate;
