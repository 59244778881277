/**
 * Method checkPrefix() - checks if image path is absolute or local and return correct path
 * 
 * @param {String} image - image path
 * @returns string (image path)
 */

import { checkIfImageIsBas64 } from "./checkIfImageIsBas64";

const checkPrefix = (image) => {
  let patternString = process.env.REACT_APP_API_URL

  if(patternString.endsWith("/api")){
    patternString = patternString.slice(0, -"/api".length);
  }

  let regex = new RegExp(patternString);

  if (regex.test(image) || checkIfImageIsBas64(image)) {
    return image;
  }else {
    return patternString + image;
  }
};

export default checkPrefix