/**
 * handleDownload() - method that handle download file
 * 
 * example usage: DownloadButton.js, TableActions.js
 * 
 * @param {String} basename - file basename
 * @param {String} fileToDownlaod - file download url
 */

const handleDownload = (basename, fileToDownlaod) => {
  const pdf_regex = /\.pdf$/i;
  let linkElement = document.createElement("a");
  if (pdf_regex.test(fileToDownlaod)) linkElement.target = "_blank";

  linkElement.download = basename;
  linkElement.href = fileToDownlaod;

  linkElement.click();
  linkElement.remove();
};

export default handleDownload;
