import React, { useState, useRef, useEffect, useContext } from "react";
import { MainAppContext } from "../../../MainAppComponent";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { getUniqueFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getHeadersFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import getCurrentDate from "../../../../functions/getCurrentDate";
import getCurrentHour from "../../../../functions/getCurrentHour";
import CalenderContainer from "../../calenderElements/CalenderContainer";

const AddNewOrderCalenderDeadline = ({ boxFieldID, actualVariant, blockEdit }) => {
  const { headerBoxesData, setHeaderBoxesData } = useContext(AddNewOrderContext);
  const [chosenDate, setChosenDate] = useState("");
  const [localVariant, setLocalVariant] = useState(null);
  const [renderOnce, setRenderOnce] = useState(true);
  const [hour, setHoure] = useState("");
  const [minute, setMinute] = useState("");
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const [error, setError] = useState(false);
  const [displayCalender, setDisplayCalender] = useState(false);

  const hourRef = useRef();
  const minuteRef = useRef();

  useEffect(() => {
    if (renderOnce && actualVariant) {
      setLocalVariant(actualVariant);
    }
  }, [actualVariant]);

  const setUpDeadline = () => {
    if (chosenDate != "") {
      let currentDate = getCurrentDate();
      let currentTime = getCurrentHour();
      setError(false);
      let copyInputData;

      if (currentDate == chosenDate && hour < currentTime) {
        copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, actualVariant), value: { content: `${chosenDate} ${currentTime}:${59}` } };
      } else {
        copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, actualVariant), value: { content: `${chosenDate} ${hour !== "" ? hour : "17"}:${minute !== "" ? minute : "00"}` } };
      }

      updateUniqueFieldsData(copyInputData, headerBoxesData, setHeaderBoxesData, boxFieldID, actualVariant);

      setShadowActive(false);
      setDisplayCalender(false);
    } else {
      setError(true);
    }
  };

  return (
    <CalenderContainer
      blockEdit={blockEdit}
      calenderValue={getHeadersFieldContent(headerBoxesData, boxFieldID, actualVariant) ?? ""}
      setUpDeadline={setUpDeadline}
      hour={hour}
      minute={minute}
      hourRef={hourRef}
      minuteRef={minuteRef}
      setChosenDate={setChosenDate}
      setHoure={setHoure}
      setMinute={setMinute}
      chosenDate={chosenDate}
      error={error}
      inputValidated={true}
      handleShadow={true}
      showTimeInput={true}
      displayCalender={displayCalender}
      setDisplayCalender={setDisplayCalender}
      autoDateSet={true}
    />
  );
};

export default AddNewOrderCalenderDeadline;
