import React, { useRef, useContext } from "react";
import compLogo from "../../../../../assets/comp.png";
import { ReformatContext } from "../../../../pages/e-commerce/Reformatting";
import CustomSizeInputs from "../../../globalElements/radioListComponents/CustomSizeInputs";

const CustomSize = ({ activeCustomSizes, setActiveCustomSizes }) => {
  const { reformatData, setReformatData } = useContext(ReformatContext);

  const handleButton = (event, inputValue) => {
    let btnType = event.target.classList[0];
    let value = inputValue;

    if (value && value !== "" && btnType == "add_btn") {
      if (reformatData.custom_sizes_list) {
        setReformatData({ ...reformatData, custom_sizes_list: [...reformatData.custom_sizes_list, value] });
      } else {
        setReformatData({ ...reformatData, custom_sizes_list: [value] });
      }
    } else {
      let copySizesList = [...reformatData.custom_sizes_list];
      let removeValue = event.target.dataset.removeValue;
      let startIndex = copySizesList.indexOf(removeValue);
      copySizesList.splice(startIndex, 1);
      setReformatData({ ...reformatData, custom_sizes_list: copySizesList });
    }
  };

  return (
    <div className="custom-input-sizes">
      {activeCustomSizes ? (
        <CustomSizeInputs customSizeList={reformatData.custom_sizes_list} handleData={handleButton} reformatData={reformatData} setReformatData={setReformatData} />
      ) : (
        <div className="logo" onClick={() => setActiveCustomSizes(true)}>
          <figure>
            <img src={compLogo}></img>
          </figure>
          <span>
            <strong>kliknij,</strong> aby dodać własne formaty
          </span>
        </div>
      )}
    </div>
  );
};

export default CustomSize;
