import React, { useEffect, useState, useContext } from "react";
import formatDate from "../../../../../functions/formatDate";
import checkPermissions from "../../../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../../wrapper/AuthWrap";

const NotoficationCollapseStructure = ({ data, celData }) => {
  const [displayData, setDisplayData] = useState([]);
  const {user, setUser} = useContext(UserContext);

  useEffect(() => {
    let mapedData = [];
    data.forEach((el) => {
      let actionName, correctdate, createdBy, department, managedBy, status, stage;

      actionName = el.action_name ? el.action_name.content : "";
      managedBy = el.managed_by ? el.managed_by : "";
      correctdate = formatDate(el.created_at);
      status = el.is_read ? "przeczytane" : "nieodczytane";
      stage = el.action;
      createdBy = el.created_by ? el.created_by : "";
      department = el.department && el.assigned_to ?`${el.department || ''} / ${el.assigned_to || ''}` : "";

      let obj = { actionName, correctdate, stage, createdBy, department, status };
      mapedData.push(obj);
    });

    setDisplayData(mapedData);
  }, []);

  return (
    <>
      {displayData
        ? displayData.map((el, index) => {
            return (
              <div className="row notification-row" key={`key-${index}-${el}`}>
                <div className={`td`} style={{width: `${celData[0].width}%`}}>{el.actionName}</div>
                <div className={`td`} style={{width: `${celData[1].width}%`}}>{el.correctdate}</div>
                <div className={`td`} style={{width: `${celData[2].width}%`}}>{el.stage}</div>
                <div className={`td`} style={{width: `${celData[3].width}%`}}>{el.createdBy}</div>
                {!checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE]) && <div className={`td`} style={{width: `${celData[4].width}%`}}>{el.department}</div>}
                <div className={`td`} style={{width: !checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE]) ? `${celData[5].width}%` : `${celData[4].width}%`}}>
                  <span>{el.status}</span>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};

export default NotoficationCollapseStructure;
