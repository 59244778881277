import React, { useEffect, useState, useContext } from "react";
import { ReformatContext } from "../../../../pages/e-commerce/Reformatting";
import reformatServices from "../../../../services/reformatServices";
import { useSearchParams } from "react-router-dom";
import Popup from "../../Popup/Popup";
import POPUP_TYPES from "../../Popup/ENUMS/POPUP_TYPES";
import BOX_INFO_ENUMS from "../../Popup/ENUMS/BOX_INFO_ENUMS";
import usePopup from "../../../../helper/usePopup";
import InfoPopup from "../../Popup/InfoPopup";
import SizeGroup from "./SizeGroup";
import NewInfoPopup from "../../Popup/AddOrder/NewInfoPopup";

const FormatList = () => {
  const [formats, setFormats] = useState([]);
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [renderOnce, setRenderOnce] = useState(true);
  const { initPopup } = usePopup();
  let sizeGroupHelper = '';
  const checkIfStrcturesIncludes = (basicSizeList, element) => {
    for (let i = 0; i < basicSizeList.length; i++) {
      if (basicSizeList[i].group == element.group) return true;
    }
    return false;
  };

  const generateBasicSizeListDataStructure = (data, initsizes) => {
    let basicSizeList = [];
    let groupHelper = '';

    data.sort((a, b) => a.size_group - b.size_group);

    data.forEach((el) => {
      if (el.size_group_name !== groupHelper) {
        groupHelper = el.size_group_name;
        let obj = { group: el.size_group, group_name: el.size_group_name, chosen_sizes: [] };
        basicSizeList.push(obj);
      }
    });

    if (initsizes) {
      setReformatData({ ...reformatData, basic_sizes_list: basicSizeList });
    } else {
      let basicSizeListCopy = [...reformatData.basic_sizes_list];
      let isSizeListChanged = false;

      basicSizeList.forEach((el) => {
        if (!checkIfStrcturesIncludes(basicSizeListCopy, el)) {
          basicSizeListCopy.push(el);
          if (!isSizeListChanged) isSizeListChanged = true;
        }
      });

      if (isSizeListChanged) setReformatData({ ...reformatData, basic_sizes_list: basicSizeListCopy });
    }
  };

  const getSizes = (initsizes) => {
    reformatServices
        .getClientSizes()
        .then((res) => {
          generateBasicSizeListDataStructure(res, initsizes);
          const groupedFormats = Object.values(
              res.reduce((groups, item) => {
                const groupName = item.size_group_name;
                if (!groups[groupName]) {
                  groups[groupName] = { size_group_name: groupName, items: [] };
                }
                groups[groupName].items.push(item);
                return groups;
              }, {})
          );
          setFormats(groupedFormats);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<NewInfoPopup type={'negative'} info={'format_list'} />);
        });
  };

  useEffect(() => {
    if (!searchParams.get('order')) {
      getSizes(true);
    }
  }, []);

  useEffect(() => {
    if (renderOnce) {
      let initsizes = false;
      if (reformatData.basic_sizes_list == null) {
        initsizes = true;
      }
      getSizes(initsizes);
      setRenderOnce(false);
    }
  }, [reformatData]);
  // const handleChosenSizes = (action, checkingSize, checkingSizeGroup) => {
  //   let copySizesList = [...reformatData.basic_sizes_list];
  //   for (let i = 0; i < copySizesList.length; i++) {
  //     if (copySizesList[i].group == parseInt(checkingSizeGroup)) {
  //       if (action == 'append') {
  //         if (!copySizesList[i].chosen_sizes.includes(checkingSize)) {
  //           copySizesList[i].chosen_sizes.push(checkingSize);
  //         }
  //       } else {
  //         let startIndex = copySizesList[i].chosen_sizes.indexOf(checkingSize);
  //         if (startIndex !== -1) {
  //           copySizesList[i].chosen_sizes.splice(startIndex, 1);
  //         }
  //       }
  //     }
  //   }
  //   setReformatData({ ...reformatData, basic_sizes_list: copySizesList });
  // };
  //
  return (
    <>
      <ul className="format-list">
        {formats.length > 0 ? (
            formats.map((el, index) => {
              return (
                  <>
                    <SizeGroup group={el} key={index} setReformatData={setReformatData} reformatData={reformatData}/>
                  </>
              );
            })
        ) : (
          <>
            {loading
            ? <span>loading</span>
            : <li><span className="error">brak dostępnych formatów</span></li>
            }
          </>
        )}
      </ul>
    </>
  );
};

export default FormatList;
