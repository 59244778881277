import React, { useState, useEffect, useContext } from "react";
import SectionHeader from "./sectionsElements/SectionHeader";
import CustomSize from "./sectionsElements/CustomSizes";
import FormatList from "./sectionsElements/FormatList";
import { ReformatContext } from "../../../pages/e-commerce/Reformatting";

const Formats = ({ validate }) => {
  const [activeCustomSizes, setActiveCustomSizes] = useState(false);
  const { reformatData, setReformatData } = useContext(ReformatContext);

  useEffect(() => {
    setReformatData({ ...reformatData, send_custom_sizes_list: activeCustomSizes });
  }, [activeCustomSizes]);

  return (
    <div className={validate ? "formats" : "formats not-validated"}>
      <div className="section-header-wrapper">
        
        
      </div>
      <div className="row">
        <div className="col-4 col-12">
          <SectionHeader headerType={"formats"} />
          <FormatList activeCustomSizes={activeCustomSizes} setActiveCustomSizes={setActiveCustomSizes} />
        </div>
        <div className="col-8 col-12">
          <SectionHeader headerType={"unstandard"} />
          <CustomSize activeCustomSizes={activeCustomSizes} setActiveCustomSizes={setActiveCustomSizes} />
        </div>
      </div>
    </div>
  );
};

export default Formats;
