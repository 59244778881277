import React from "react";

const ViewBanner = ({ chosenSource }) => {
  return (
    <>
      <div className="view-info">
        <h4>Podgląd</h4>
        <span>wybranego źródła</span>
      </div>
      {chosenSource ? (
        <figure>
          <img src={chosenSource}></img>
        </figure>
      ) : null}
    </>
  );
};

export default ViewBanner;
