import React from "react";
import negativeIcon from "../../../../assets/icon-negative.png";
import orderServices from "../../../services/orderServices";
import usePopup from "../../../helper/usePopup";
const RemoveOrderPopup = ({ setRemoveOrderID, removeOrderID, orderID, status }) => {
  const { closePopup } = usePopup();
  let content = {
    deleted: {
      icon: negativeIcon,
      text_small: "na pewno chcesz",
      text_big: "usunąć <br/> permanentnie <br/> zlecenie",
      text_center: "",
      button_first_text: "Tak, usuń",
      button_second_text: "Nie, zostaw",
      text_last: "Twojego wyboru nie będzie można cofnąć.",
    },
    cancelled: {
      icon: negativeIcon,
      text_small: "na pewno chcesz",
      text_big: "anulować <br/> procedowanie <br/> zlecenie",
      text_center: "",
      button_first_text: "Tak, anuluj",
      button_second_text: "Nie, zostaw",
      text_last: "Twojego wyboru nie będzie można cofnąć.",
    },
  };
  const handleFirstButton = () => {
    if (setRemoveOrderID && removeOrderID) {
      let newData = { ...removeOrderID, noDisplayOrder: [...removeOrderID.noDisplayOrder, orderID] };
      setRemoveOrderID(newData);
      orderServices.changeOrderStatus(orderID, status);
    }
    closePopup();
  };
  const handleSecondButton = () => {
    closePopup();
  };
  return (
    <div className={`new-order-popup delete`} onClick={(e) => e.stopPropagation()}>
      <div className={`popup-contents delete`}>
        <div className="popup-upper-part">
          <img className="icon-section" src={negativeIcon}></img>
          <span className={`text-small delete`}>{content[status]?.text_small}</span>
          <span className={`text-big delete`} dangerouslySetInnerHTML={{ __html: content[status]?.text_big }} />
        </div>
        <div className="popup-lower-part">
          <div className="popup-buttons">
            <button className={`popup-button first delete`} onClick={handleFirstButton}>
              {content[status]?.button_first_text}
            </button>
            <button className={`popup-button second delete`} onClick={handleSecondButton}>
              {content[status]?.button_second_text}
            </button>
          </div>
          <div className="text-last-container">
            <span className={`text-last delete`} dangerouslySetInnerHTML={{ __html: content[status]?.text_last }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveOrderPopup;
