import linkifyHtml from "linkify-html";

/**
 * handleLinkInText() - method that search for link regexp in string and cover that link 
 * in HTML <a> link tag to make it clickable in HTML DOM
 * 
 * example usage: CollapseRow.js, Table.js
 * 
 * @param {String} str 
 * @returns String covered by HTML <a> link tag
 */

const handleLinkInText = (value) => {
  if(typeof value == 'string'){
    const options = {
      target: "_blank",
      tagName: "a",
    };
    
    return linkifyHtml(value, options);
  }

  return value;
};

export default handleLinkInText