import productsServices from "../components/services/productsServices";
import logotypeServices from "../components/services/logotypeServices";
import handleDownload from "./handleDownload";
import { getFileBaseName } from "./getFileBaseName";

const getZipPackage = async (variantID, type) => {
  let response, basename, fileToDownlaod;

  switch(type){
    case 'product':
      response = await productsServices.downloadProductsZipFileByVariants(variantID)
      break;
    case 'logotype':
      response = await logotypeServices.getLogotypeZip(variantID)
      break;
  }

  basename = getFileBaseName(response.file);
  fileToDownlaod = response.file;
  handleDownload(basename, fileToDownlaod);
};

export default getZipPackage;
