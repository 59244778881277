import React, {useEffect, useState} from 'react';
import usePopup from "../../../helper/usePopup";
import positiveIcon from "../../../../assets/v.png";
import neutralIcon from "../../../../assets/plus.png";
import negativeIcon from "../../../../assets/x.png";
import deleteIcon from "../../../../assets/icon-negative.png";
import startObserveIcon from "../../../../assets/obserwacja.png";
import stopObserveIcon from "../../../../assets/stop_obs.png";
import {contentMap} from "../ENUMS/INFO_POPUP_ENUM";

const icons = {
    positive: positiveIcon,
    neutral: neutralIcon,
    negative: negativeIcon,
    delete: deleteIcon,
    startObserve : startObserveIcon,
    stopObserve : stopObserveIcon,
};

const InfoPopup = ({ type, setShowBoxInfo, setBoxInfoData, info, setReloadComponent, setErrorPopup, reload, customIcon }) => {
    const [content, setContent] = useState({});
    const { closePopup, clearTimeoutManually } = usePopup();
    const IconComponent = customIcon ? customIcon : icons[type];

    useEffect(() => {
        const typeContent = contentMap[type][info] || contentMap[type].default;
        setContent(typeContent);
    }, [type, info]);

    const handleFirstButton = () => {
        if (type !== 'negative') {
            if(setReloadComponent){
                setReloadComponent(true);
            }
            if(reload){
                window.location.reload();
            }
            closePopup();
        } else {
            if(setErrorPopup){
                setErrorPopup(false);
            }
            if(setShowBoxInfo){
                setShowBoxInfo(false);
            }
            if(setBoxInfoData){
                setBoxInfoData({});
            }
            if(setReloadComponent){
                setReloadComponent(true);
            }
            closePopup();
        }
    };

    return (
        <div className={`info-popup ${info == "stop_observing" ? 'neutral' : type}`} onClick={e => e.stopPropagation()}>
            <div className={`popup-contents ${type}`}>
                <div className="popup-upper-part">
                    {IconComponent && <img className="icon-section" src={IconComponent}></img>}
                    <span className={`text-small ${info == "stop_observing" ? 'neutral' :type}`}>{content?.text_small}</span>
                    <span className={`text-big ${info == "stop_observing" ? 'neutral' :type}`} dangerouslySetInnerHTML={{ __html: content?.text_big }} />
                </div>
                {content?.text_center &&
                    <span className={`text-center ${info == "stop_observing" ? 'neutral' : type}`} dangerouslySetInnerHTML={{ __html: content?.text_center }} />}
                <div className="popup-lower-part">
                    <div className="popup-buttons">
                        <button className={`popup-button only ${type}`} onClick={handleFirstButton}>{content.button_first_text}</button>
                    </div>
                    <div className='text-last-container'>
                        <span className={`text-last ${type}`} dangerouslySetInnerHTML={{ __html: content?.text_last }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoPopup;
