import React, { useState, createContext, useContext, useRef, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import AddNewOrderCampaignDropdown from "../../elements/headers/AddNewOrder/AddNewOrderCampaignDropdown";
import ActionName from "../../elements/headers/AddNewOrder/ActionName";
import AddNewOrderCalenderDeadline from "../../elements/headers/AddNewOrder/AddNewOrderCalenderDeadline";
import OrderBoxContent from "../../elements/e-commerce/OrderBox/OrderBoxContent";
import { handleBoxStatus } from "../../../functions/boxFunctions/handleBoxStatus";
import { getHiddenBoxesFieldsID } from "../../../functions/boxFieldsFunctions/getHiddenBoxesFieldsID";
import { sendBoxFIeldData } from "../../services/sendBoxFieldsData";
import { addNewBoxFieldVariant } from "../../../functions/boxFieldsFunctions/addNewBoxFieldVariant";
import orderServices from "../../services/orderServices";
import createNewVariant from "../../services/createNewVariant";
import deleteOrderVariant from "../../services/deleteOrderVariant";
import getBoxFields from "../../services/getBoxFields";
import getOrderVariant from "../../services/getOrderVariant";
import getApiKey from "../../helper/getApiKey";
import { MainAppContext } from "../../MainAppComponent";
import checkPermissions from "../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../wrapper/AuthWrap";
import ActionDataButtons from "./ActionData";
import BOX_INFO_ENUMS from "../../elements/e-commerce/Popup/ENUMS/BOX_INFO_ENUMS";
import checkIfHanfleBoxFieldVariant from "../../../functions/boxFieldsFunctions/checkIfHanfleBoxFieldVariant";
import removeHiddenBoxSettingsVariant from "../../../functions/boxFieldsFunctions/removeHiddenBoxSettingsVariant";
import Loader from "../../elements/globalElements/Loader/Loader";
import LOADER_TYPE from "../../elements/globalElements/Loader/LOADER_TYPE";
import POPUP_TYPES from "../../elements/e-commerce/Popup/ENUMS/POPUP_TYPES";
import settingsService from "../../services/settingsService";
import usePopup from "../../helper/usePopup";
import NewOrderPopup from "../../elements/e-commerce/Popup/NewOrderPopup";
import InfoPopup from "../../elements/e-commerce/Popup/InfoPopup";
import timer from "../../../functions/timer";
import TimerPopup from "../../elements/e-commerce/Popup/TimerPopup";
import removeOrderLockOnLocationChange from "../../../functions/removeOrderLockOnLocationChange";
import * as Sentry from "@sentry/react";


export const AddNewOrderContext = createContext(null);

const AddNewOrder = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [validate, setValidate] = useState(false);
  const [doNotValidate, setDoNotValidate] = useState(true);
  const [showErrorPopup, setErrorPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [actionButtonPopup, setAtionButtonPopup] = useState(null);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const { totalRemovePopup, setTotalRemovePopup } = useContext(MainAppContext);
  const [boxInfoData, setBoxInfoData] = useState({ infoType: "", popupType: POPUP_TYPES.POSITIVE, icon: "" });
  const [showBoxInfo, setShowBoxInfo] = useState(false);
  const [boxData, setBoxData] = useState([]); //array of all boxes
  const [boxFieldsData, setBoxFieldsData] = useState([]); //data saved from boxDara
  const [headerBoxes, setHeaderBoxes] = useState([]); //array of uniqe box from header section
  const [headerBoxesData, setHeaderBoxesData] = useState([]); //data saved from headerBoxes
  const [uniqueBoxData, setUniqueBoxData] = useState(null);
  const addNewOrderRef = useRef();
  const [tabsArr, setTabsArr] = useState([]);
  const [chosenTab, setChosenTab] = useState("tab-1");
  const [chosenTabIndex, setChosenTabIndex] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [initBoxSettings, setInitBoxSettings] = useState(true);
  const [productViewBoxID, setProductViewBoxID] = useState(null);
  const [logotypeBoxID, setLogotypeBoxID] = useState(null)
  const { generalSettings, setGeneralSettings } = useContext(MainAppContext);
  const { initTimeoutPopup, initPopup, initCustomBackgroundPopup, closePopup } = usePopup();
  const [boxSettings, setBoxSettings] = useState({
    language: "",
    hidden_boxes: [],
    notValidatedBoxes: [],
    addNewOrderReference: addNewOrderRef,
    variantsNumber: null,
  });
  const [orderDeadlineFieldID, setOrderDeadlineFieldID] = useState(null)
  const [orderID, setOrderID] = useState(null);
  const [actualVariant, setActualVariant] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false);

  const [orderCustomSizeList, setOrderCustomSizeList] = useState([]);

  const [displayTimerPopup, setDisplayTimerPopoup] = useState(false);
  const [orderLocks,setOrderLocks] = useState(null);  

  useEffect(() => {

    if (searchParams.get("edit_order") === "true" && searchParams.get("order")) {
      getVariantBoxFieldValue(searchParams.get("order"));
    } else if (searchParams.get("order_status") && searchParams.get("order")) {
      getVariantBoxFieldValue(searchParams.get("order"));
    } else {
      createNewOrder();
    }
    handleBoxStatus(boxData, boxFieldsData, setBoxFieldsData, boxSettings.hidden_boxes);
    getApiKey();
  }, []);

  useEffect(() => {
    let cleanupFunction;

    if (searchParams.get("edit_order") === "true") {
      cleanupFunction = timer.initCorrectionTimer();
    }

    return () => {
      if (cleanupFunction) cleanupFunction();
    };
  }, [searchParams]);
  
  useEffect(()=>{
    if(displayTimerPopup) {
      let timeDistance = new Date(orderLocks.end_time).getTime() - new Date().getTime();
      initTimeoutPopup(<TimerPopup orderLocks={orderLocks} setOrderLocks={setOrderLocks} initCorrectionTimer={timer.initCorrectionTimer} />, timeDistance)
      setDisplayTimerPopoup(false)
    } //TODO show timer popup
  }, [displayTimerPopup])

  useEffect(()=>{
    if(orderLocks){
      let countDownDate = new Date(orderLocks.end_time).getTime();
      timer.countDownTimer(countDownDate, setDisplayTimerPopoup);

      return () => {
        removeOrderLockOnLocationChange(orderLocks.id)
      };
    }
  }, [orderLocks])

  useEffect(() => {
    if (reloadComponent) {
      let queryParams = [...searchParams];

      if (queryParams.length > 0) {
        queryParams.forEach((param) => {
          if (param[0] == "edit_order") searchParams.delete("edit_order");
          if (param[0] == "order_status") searchParams.delete("order_status");
          searchParams.delete("order");
          setSearchParams(searchParams);
        });
      }

      setAtionButtonPopup(null);
      setShowActionPopup(false);
      setTotalRemovePopup(false);
      setBoxData([]);
      setHeaderBoxes([]);
      setHeaderBoxesData([]);
      setBoxSettings({
        language: "",
        hidden_boxes: [],
        notValidatedBoxes: [],
        addNewOrderReference: addNewOrderRef,
      });
      setOrderID(null);
      setActualVariant(null);
      setBoxFieldsData([]);
      setTabsArr([]);
      setChosenTab("tab-1");
      setErrorPopup(false);
      handleBoxStatus([], [], setBoxFieldsData, []);
      createNewOrder();
      setReloadComponent(false);
    }
  }, [reloadComponent]);

  const getVariantBoxFieldValue = (id) => {
    getBoxFields
      .getVariantBoxFieldValue(id)
      .then((res) => {
        let variantsArr = [];
        getOrderVariant(id).then((res) => {
          res.forEach((el,index)=>{
            if(el.is_saved){
              variantsArr.push({tab_name: `tab-${index + 1}`,tab_vairant: el.id,})
            }
          })
          setTabsArr(variantsArr);
          setShowContent(true);
        });

        getSetting(null, res, id, variantsArr);
        setOrderID(id);
      })
      .catch((error) => {
        console.error(error);
        setErrorPopup(true);
        initPopup(<InfoPopup type={'negative'} info={'load_data'}/>)
      });
  };

  const createNewOrder = () => {
    orderServices
      .createNewOrder()
      .then((res) => {
        setOrderID(res.id);
        createOrderVariant(res.id, 1);
      })
      .catch((error) => {
        console.error(error);
        setErrorPopup(true);
        initPopup(<InfoPopup type={'negative'} info={'create_new_order'}/>)
      });
  };

  const createOrderVariant = (order_id, mod) => {
    createNewVariant
      .createOrderVariant(order_id)
      .then((res) => {
        if (tabsArr.length > 0) {
          let lastTab = tabsArr[tabsArr.length - 1].tab_name,
            tabNameNumber = parseInt(lastTab.split("-")[1]) + 1;
          setTabsArr((prevState) => [...prevState, { tab_name: `tab-${tabNameNumber}`, tab_vairant: res.id }]);
          setChosenTab(`tab-${tabNameNumber}`);
        } else {
          setTabsArr((prevState) => [...prevState, { tab_name: "tab-1", tab_vairant: res.id }]);
        }

        if (mod !== "add_new_variant") {
          getSetting(res.id, null, order_id, tabsArr);
          setInitBoxSettings(false);
        } else {
          createNewVariantValues(res.id);
        }

        setActualVariant(res.id);
        setChosenTabIndex(tabsArr.length);
      })
      .catch((error) => {
        console.error(error);
        setErrorPopup(true);
        initPopup(<InfoPopup type={'negative'} info={'create_new_order_variant'}/>)
      });
  };
  const createNewVariantValues = (actualVariant) => {
    boxFieldsData.forEach((el) => {
      let boxID;
      if ("box_field" in el) {
        boxID = el.box_field;
      } else {
        boxID = el.box_field_id;
      }
      handleDuplicateHiddenBoxes(actualVariant);
      addNewBoxFieldVariant(null, boxFieldsData, setBoxFieldsData, boxID, actualVariant, uniqueBoxData);
    });
  };

  const handleDuplicateHiddenBoxes = (actualVariant) => {
    let lastVariantID = tabsArr[tabsArr.length - 1].tab_vairant;
    let copyHideBoxes = boxSettings.hidden_boxes;

    copyHideBoxes.forEach((box) => {
      let lastIndex = box.variants.length - 1;

      if (box.variants[lastIndex] == lastVariantID) {
        box.variants.push(actualVariant);
      }
    });

    setBoxSettings({
      ...boxSettings,
      hidden_boxes: copyHideBoxes,
    });
  };

  const getSetting = async (variantID, draftData, order_id) => {
    let headerBoxesArr = [],
      boxDataArr = [];

      if(generalSettings.length > 0){
        generalSettings.forEach((el) => {
          if (el.column < 0) {
            headerBoxesArr.push(el);
          } else {
            boxDataArr.push(el);
          }
        });
      }else{
        try{
          let settings = await settingsService.getSetting();
          settings.forEach((el) => {
            if (el.column < 0) {
              headerBoxesArr.push(el);
            } else {
              boxDataArr.push(el);
            }
          });
        }catch{
          setErrorPopup(true);
        }

      }


    setHeaderBoxes(headerBoxesArr);
    generateHeadersFieldsData(headerBoxesArr, draftData, variantID, order_id);
    setBoxData(boxDataArr);
    generateBoxFieldsData(boxDataArr, draftData, variantID, order_id);
  };

  const generateBoxFieldArr = (boxArr) => {
    let result = [];
    let mainBoxID = null;
    let mainBoxFields = [];
    boxArr.forEach((el) => {
      el?.box?.box_fields?.forEach((data) => {
        result.push(data);
        if (data.field_type == "size" || data.field_type == "client_language") {
          mainBoxID = el.box.id;
          mainBoxFields.push(data);
        }
      });
    });

    if (mainBoxID) setUniqueBoxData({ box_id: mainBoxID, box_fields: mainBoxFields });

    return result;
  };

  const generateHeadersFieldsData = (boxes, draftData, variantID, orderID) => {
    let result = [];
    let boxFields = generateBoxFieldArr(boxes);

    if (variantID == null) variantID = getVariants(draftData)[0];
    result = boxFields.map((el) => {

      if(el.field_type == 'datetime'){
        setOrderDeadlineFieldID(el.id)
      }

      let obj = {
        box_field: el.id,
        order: [{ order_id: orderID, value: { content: "" }, is_hidden: false, custom_inputs_value: "", picture: null }],
      };
      return obj;
    });

    if (searchParams.get("order_status") || searchParams.get("edit_order")) {
      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < draftData?.length; j++) {
          if (result[i].box_field == draftData[j].box_field) {
            result[i].order = [...draftData[j].order];
          }
        }
      }
    }

    setHeaderBoxesData(result);
  };

  const getFiledTypeInitialValue = (field, variantID, orderid) => {
    let initValue;
    switch (field.field_type) {
      case "input_text":
        initValue = {
          variant_id: variantID,
          value: { content: "" },
          is_hidden: false,
          custom_inputs_value: null,
          image: null,
          is_required: field.is_required,
        };
        break;
      case "client_language":
        initValue = {
          order_id: orderid,
          value: { content: null },
          is_hidden: false,
          custom_inputs_value: "PL",
          image: null,
        };
        break;
      case "radio_list":
        initValue = {
          variant_id: variantID,
          value: { content: "" },
          is_hidden: false,
          custom_inputs_value: "",
          image: null,
          is_required: field.is_required,
        };

        break;
      case "size":
        initValue = {
          order_id: orderid,
          value: { parent_id: null, children: [] },
          is_hidden: false,
          custom_inputs_value: "",
          image: null,
          is_required: field.is_required,
        };

        break;
      case "color":
        initValue = {
          variant_id: variantID,
          value: { content: "#000000" },
          is_hidden: false,
          custom_inputs_value: "",
          image: null,
          is_required: field.is_required,
        };
        break;
      case "product":
        initValue = {
          variant_id: variantID,
          is_hidden: false,
          value: [
            {
              external_id: null,
              ean: null,
              plu: null,
              name: null,
              quantity: 1,
              weight: null,
              photos: [
                {
                  external_id: null,
                  url: null,
                },
              ],
            },
          ],

          custom_inputs_value: null,
          image: null,
          is_required: field.is_required,
        };
        setProductViewBoxID(field.id);
        break;
      case 'logotype':
        initValue = {
          variant_id: variantID,
          value: { content: [] },
          is_hidden: false,
          custom_inputs_value: "",
          image: null,
          is_required: field.is_required,
        };
        setLogotypeBoxID(field.id)
        break;
      default:
        initValue = {
          variant_id: variantID,
          value: { content: [] },
          is_hidden: false,
          custom_inputs_value: "",
          image: null,
          is_required: field.is_required,
        };
        break;
    }

    return initValue;
  };

  const setUpLanguage = (boxFields, draftData) => {
    let languageBoxFieldId = null;
    let result = "";

    for (let i = 0; i < boxFields.length; i++) {
      if (boxFields[i].field_type == "client_language") {
        languageBoxFieldId = boxFields[i].id;
        break;
      }
    }

    for (let i = 0; i < draftData.length; i++) {
      if (draftData[i].box_field == languageBoxFieldId) {
        result = draftData[i].order[0].custom_inputs_value;
        break;
      }
    }

    return result;
  };

  const chechIfHiddenBoxSettingInluce = (searchElement, fetchedObjBoxArr) => {
    for (let j = 0; j < fetchedObjBoxArr.length; j++) {
      if (searchElement.box_id == fetchedObjBoxArr[j].box_id) return true;
    }

    return false;
  };

  const completeBoxSettings = (localObjBoxArr, fetchedObjBoxArr, variantList) => {
    let result = [...fetchedObjBoxArr];

    for (let i = 0; i < localObjBoxArr.length; i++) {
      if (!chechIfHiddenBoxSettingInluce(localObjBoxArr[i], fetchedObjBoxArr))
        result.push({ box_id: localObjBoxArr[i].box_id, variants: variantList ? [...variantList] : [] });
    }

    return result;
  };

  const setUpBoxSettings = (boxFields, draftData, boxes, order_id) => {
    let objBoxArr = [];
    boxes.forEach((box) => {
      let obj = { box_id: box.box.id, variants: [] };
      objBoxArr.push(obj);
    });
    let variantList = getVariants(draftData);
    if (searchParams.get("order_status") || searchParams.get("edit_order")) {
      let setLanguage = setUpLanguage(boxFields, draftData);
      orderServices.getOrderDetails(order_id).then((res) => {
        let hiddenBoxes = completeBoxSettings(objBoxArr, res.ui_settings?.hidden_boxes ? [...res.ui_settings.hidden_boxes] : objBoxArr, variantList);

        setBoxSettings({
          ...boxSettings,
          language: setLanguage,
          hidden_boxes: hiddenBoxes,
        });

        setOrderCustomSizeList(res.custom_size_list)

        if(searchParams.get("lock_id")){
          setOrderLocks(res.order_locks[0]);
        }
      });
    } else {
      setBoxSettings({
        ...boxSettings,
        hidden_boxes: objBoxArr,
      });
    }
  };

  const generateBoxFieldsData = (boxes, draftData, variantID, order_id) => {
    let result = [];
    let boxFields = generateBoxFieldArr(boxes);
    let variantsList;
    if (variantID == null) variantID = getVariants(draftData)[0];

    if (draftData) variantsList = getVariants(draftData);
    result = boxFields.map((el) => {
      let initValue = variantsList?.length
        ? variantsList.map((variant) => getFiledTypeInitialValue(el, variant, order_id))
        : [getFiledTypeInitialValue(el, variantID, order_id)];

      if (el.field_type == "size" || el.field_type == "client_language") {
        return {
          box_field: el.id,
          order: initValue,
        };
      } else {
        return {
          box_field: el.id,
          variants: initValue,
        };
      }
    });

    if (searchParams.get("order_status") || searchParams.get("edit_order")) {
      variantsList = getVariants(result);

      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < draftData?.length; j++) {
          if (result[i].box_field == draftData[j].box_field) {
            if (result[i].variants) {
              result[i].variants = draftData[j].variants;
            } else {
              result[i].order = draftData[j].order;
            }
          }
        }
      }
      for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < draftData.length; j++) {
          if (result[i].variants?.length == 1) {
            let firstVariant = result[i].variants[0];

            result[i].variants = variantsList.map((el) => {
              return { ...firstVariant, variant_id: el };
            });
          }
        }
      }
    }
    setUpBoxSettings(boxFields, draftData, boxes, order_id);
    setBoxFieldsData(result);
    setShowContent(true);
  };

  const getVariants = (boxList) => {
    let variantsArr = [];
    if (boxList) {
      for (let i = 0; i < boxList.length; i++) {
        if (boxList[i].variants?.length > 0) {
          variantsArr = boxList[i].variants.map((el) => el.variant_id);
          break;
        }
      }
    }

    return variantsArr;
  };

  const removeVariant = (variantID, tabsArrCopy) =>{
    deleteOrderVariant.removeOrderVariant(variantID);

    let result = [];
    result = boxFieldsData.map((value) => {
      if (value.variants?.length > 0) {
        let newVariantArr = [];
        newVariantArr = value.variants.filter((el) => {
          if (el.variant_id !== parseInt(variantID)) {
            return el;
          }
        });
        value.variants = newVariantArr;
      }

      return value;
    });

    setBoxFieldsData(result);
    setBoxSettings({
      ...boxSettings,
      hidden_boxes: removeHiddenBoxSettingsVariant(boxSettings.hidden_boxes, variantID),
    });


    setChosenTabIndex(tabsArrCopy.length - 1);
    setTabsArr(tabsArrCopy);
    setChosenTab(tabsArrCopy[tabsArrCopy.length - 1].tab_name);
  }

  const handleTabs = (e) => {
    if (e.target.classList.contains("remove")) {
      
      let variantID = e.target.parentNode.dataset.vairant;
      let tabsArrCopy = tabsArr,
        target = e.target.parentNode.dataset.target;
      tabsArrCopy = tabsArrCopy.filter((el) => {
        if (el.tab_name != target) {
          return el;
        }
      });

      initPopup(<NewOrderPopup type={"remove_variant"} removeVariant={() => removeVariant(variantID, tabsArrCopy)} />);
    } else {
      setChosenTab(e.target.dataset.target);
      setChosenTabIndex(e.target.dataset.tabIndex);
    }
  };

  const validateBoxFieldsVariants = (boxFieldVariants, hiddenBoxFieldsGroup, boxFieldID) => {
    let arr = [];

    boxFieldVariants.forEach((el) => {
      let id = el.variant_id || el.order_id;
      if (checkIfHanfleBoxFieldVariant(id, boxFieldID, hiddenBoxFieldsGroup, boxSettings)) {
        if (el && el.value) {
          //&& el.is_hidden == false
          if ("content" in el.value) {
            if (el.value.content == "" || el.value.content == null || el.value.content.length <= 0 || el.value.parent_id == "") {
              if (el.variant_id) {
                arr.push(el.variant_id);
              } else {
                arr.push(el.order_id);
              }
            }
          } else {
            if (el.value.parent_id == "" || el.value.parent_id == null || (el.value.children.length <= 0 && orderCustomSizeList.length == 0)) {
              if (el.variant_id) {
                arr.push(el.variant_id);
              } else {
                arr.push(el.order_id);
              }
            }
          }
        }
      }
    });

    return arr;
  };

  const validateBoxFields = (actionType) => {
    let fieldsNotValidated = [];
    let hiddenBoxFieldsGroup = getHiddenBoxesFieldsID(boxSettings.hidden_boxes, boxData);
    let allBoxFields = [];
    allBoxFields = [...headerBoxesData, ...boxFieldsData];
    for (let i = 0; i < allBoxFields.length; i++) {
      if (![productViewBoxID, logotypeBoxID].includes(allBoxFields[i].box_field) ) {
        let variantList = allBoxFields[i].variants || allBoxFields[i].order;
        let boxFieldID = allBoxFields[i].box_field;
        let notValidatedVariantList = validateBoxFieldsVariants(variantList, hiddenBoxFieldsGroup, boxFieldID);

        let obj = { box_field: allBoxFields[i].box_field, variants: notValidatedVariantList };
        fieldsNotValidated.push(obj);
      }
    }

    let isValidated = checkIfBoxesAreValidated(fieldsNotValidated);
    setDoNotValidate(isValidated);
    setBoxSettings({
      ...boxSettings,
      notValidatedBoxes: fieldsNotValidated,
    });

    if(fieldsNotValidated.length > 0){
      Sentry.captureMessage(`Nie wypelniono wszytkich pól: ${JSON.stringify(fieldsNotValidated)}`, "info");
    }

    return isValidated;
  };

  const checkIfBoxesAreValidated = (fieldsNotValidated) => {
    for (let i = 0; i < fieldsNotValidated.length; i++) {
      if (fieldsNotValidated[i].variants?.length) return false;
    }

    return true;
  };

  const handleAddNewForm = () => {
    createOrderVariant(orderID, "add_new_variant");
  };
  const handleSaveProject = () => {
    if (doNotValidate) {
      if (searchParams.get("edit_order") == "true" || searchParams.get("order_status")) {
        sendBoxFIeldData(boxData, headerBoxesData, boxFieldsData, boxSettings, "PUT");
      } else {
        sendBoxFIeldData(boxData, headerBoxesData, boxFieldsData, boxSettings, "POST");
      }

      let body = { status: "draft", ui_settings: { hidden_boxes: boxSettings.hidden_boxes } };
      orderServices.changeOrderDetail(orderID, body); //change order status to new and send uisettings
      return true;
    } else {
      setShowActionPopup(false);
      setBoxInfoData({ infoType: BOX_INFO_ENUMS.INFO.save_data_info, popupType: POPUP_TYPES.NEGATIVE, icon: "" });
      setShowBoxInfo(true);
      return false;
    }
  };

  useEffect(()=>{
    if(showBoxInfo){
      initPopup(<InfoPopup type='negative' setShowBoxInfo={setShowBoxInfo} setBoxInfoData={setBoxInfoData} edit={searchParams.get("edit_order") === "true"}/>);
    }
  },[showBoxInfo])
  const handleActionButton = async (e) => {
    let actionType = e.target.dataset.name;
    if (actionType !== "neutral") {
      if (actionType === "positive") {
        if (validateBoxFields(actionType)) {
          let method_type = "POST";
          if (searchParams.get("order_status") || searchParams.get("edit_order") == "true") {
            method_type = "PUT";
          }

          try{
            await sendBoxFIeldData(boxData, headerBoxesData, boxFieldsData, boxSettings, method_type);

            let orderDetailBody = {custom_size_list: orderCustomSizeList} //defult order detail body

            if (!searchParams.get("is_correction") || searchParams.get("order_status") == "finished"){
              orderDetailBody.status = "new";
              orderDetailBody.ui_settings = { hidden_boxes: boxSettings.hidden_boxes };
            } else if(searchParams.get("is_correction")){
              orderDetailBody.ui_settings = { hidden_boxes: boxSettings.hidden_boxes };
            }

            orderServices.changeOrderDetail(orderID, orderDetailBody);

            if(searchParams.get("lock_id")) orderServices.removeOrderLock(searchParams.get("lock_id"));

            initTimeoutPopup(<NewOrderPopup type={actionType} setReloadComponent={setReloadComponent} setSearchParams={setSearchParams} edit={searchParams.get("edit_order") === "true"}/>, 5000)

          }catch(error){
            console.log('BOX FIELD DATA ERROR');
            console.error(error);
            initPopup(<InfoPopup type='negative' info={`something_wrong`}/>);
          }


        } else {
          setBoxInfoData({ infoType: BOX_INFO_ENUMS.INFO.save_data_info, popupType: POPUP_TYPES.NEGATIVE, icon: "" });
          setShowBoxInfo(true);
        }
      } else {
        // orderServices.setUpUiSettings(orderID, boxSettings.hidden_boxes);
        initPopup(<NewOrderPopup type={actionType} edit={searchParams.get("edit_order") === "true"}/>)
      }
      // orderServices.setUpUiSettings(orderID, boxSettings.hidden_boxes);
    } else {
      initPopup(<NewOrderPopup type={actionType} handleSaveProject={handleSaveProject} edit={searchParams.get("edit_order") === "true"}/>)
    }
  };
  const handleHeaderBoxesClass = (box) => {
    let resultClass = "input-box";
    let boxPosition = box.position;
    let notValidatedBoxList = boxSettings.notValidatedBoxes;
    let fieldID = box.box.box_fields[0].id;

    if (boxPosition == 1) resultClass += " order-1";
    if (boxPosition == 2) resultClass += " order-2";
    if (boxPosition == 3) resultClass += " order-3";

    if (boxSettings.notValidatedBoxes.length > 0) {
      for (let i = 0; i < notValidatedBoxList.length; i++) {
        if (notValidatedBoxList[i].box_field === fieldID) {
          if (notValidatedBoxList[i].variants?.length) resultClass += " not-validated";
        }
      }
    }

    return resultClass;
  };

  useEffect(() => {
    handleBoxStatus(boxData, boxFieldsData, setBoxFieldsData, boxSettings.hidden_boxes);
  }, [boxSettings]);

  const handleTabsClass = (tabName, tabVariant) => {
    let result = "tab";

    if (tabName != chosenTab) result += " disactive";

    if (checkIfTabHansNotValidatedBox(tabVariant)) result += " not-validated";

    return result;
  };

  const checkIfTabHansNotValidatedBox = (tabVariant) => {
    let boxes = boxSettings.notValidatedBoxes;
    for (let i = 0; i < boxes.length; i++) {
      let variantList = boxes[i].variants;

      for (let j = 0; j < variantList.length; j++) {
        if (variantList[j] == tabVariant) return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if(totalRemovePopup){
      let alloweRemoveOrder = searchParams.get("edit_order") ? false : true;
      initCustomBackgroundPopup(<NewOrderPopup type='delete' alloweRemoveOrder={alloweRemoveOrder} pagetype={`add_order`} orderID={orderID}/>)
    }
  }, [totalRemovePopup])
  return (
    <AddNewOrderContext.Provider
      value={{
        boxData,
        setBoxData,
        boxSettings,
        setBoxSettings,
        boxFieldsData,
        setBoxFieldsData,
        headerBoxesData,
        setHeaderBoxesData,
        uniqueBoxData,
        setUniqueBoxData,
        tabsArr,
        setTabsArr,
        orderCustomSizeList,
        setOrderCustomSizeList
    }}
    >
      {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ADD_NEW_ORDER_PAGE) ? (
        <>
          {showContent && tabsArr?.length ? (
            <div className="add-new-order" ref={addNewOrderRef}>
              <div className="top-inputs">
                {headerBoxes
                  ? headerBoxes.map((el, index) => {
                      return (
                        <div className={handleHeaderBoxesClass(el)} key={`add-new-order-key-${index}`}>
                          <h3>{el.box.name}</h3>
                          {el.box.box_fields.length > 0 && el.box.box_fields.map(field=>{
                            if (field.field_type == "input_text") {
                              return <ActionName boxFieldID={field.id} actualVariant={orderID} value={el} />;
                            } else if (field.field_type == "autocomplete") {
                              return (
                                <AddNewOrderCampaignDropdown
                                  boxFieldID={field.id}
                                  actualVariant={orderID}
                                  value={field.box_field_dictionary}
                                  notValidatedBoxes={boxSettings.notValidatedBoxes}
                                />
                              );
                            }else{
                              return <AddNewOrderCalenderDeadline boxFieldID={field.id} actualVariant={orderID} />
                            }
                          })}
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="form-section">
                <div className="form-tabs">
                  <h3>WARIANT</h3>
                  {tabsArr.map((el, index) => {
                    return (
                      <div
                        className={handleTabsClass(el.tab_name, el.tab_vairant)}
                        key={el.tab_name}
                        onClick={handleTabs}
                        data-target={el.tab_name}
                        data-vairant={el.tab_vairant}
                        data-tab-index={index}
                      >
                        {index + 1}
                        {index > 0 ? <button className="remove"></button> : null}
                      </div>
                    );
                  })}
                  <button className="add-new-form" onClick={handleAddNewForm}>
                    + Dodaj kolejny
                  </button>
                </div>
                <div className="form-tab-content">
                  <OrderBoxContent
                    tabsArr={tabsArr}
                    variant={tabsArr[chosenTabIndex].tab_vairant}
                    orderID={orderID}
                    chosenTabIndex = {chosenTabIndex}
                    key={`tabs-content-${tabsArr[chosenTabIndex].tab_name}`}
                    target={tabsArr[chosenTabIndex].tab_name}
                    visible={tabsArr[chosenTabIndex].tab_name == chosenTab ? true : false}
                    orderDeadlineFieldID={orderDeadlineFieldID}
                  />
                </div>
              </div>
              <ActionDataButtons handleActionButton={handleActionButton} isCorrection={searchParams.get("is_correction")}/>
            </div>
          ) : (
            <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />
          )}
        </>
      ) : (
        <span>brak uprawnień do korzystania z tej strony</span>
      )}
    </AddNewOrderContext.Provider>
  );
};

export default AddNewOrder;
